import { useTheme } from '@root/context/ThemeContext';
import AdminBlack from './Admin black.svg';
import AdminWhite from './Admin white.svg';
import AlertsBlack from './Alerts Black.svg';
import AlertsWhite from './Alerts White.svg';
import AllEventsBlack from './All Events Black.svg';
import CDRBlack from './CDR BLACK.svg';
import CDRWhite from './CDR White.svg';
import CodeBlack from './CodeBlack.svg';
import ComplianceBlack from './Compliance Black.svg';
import DashboardBlack from './Dashboard Black.svg';
import DashboardWhite from './Dashboard White.svg';
import DataSecurityBlack from './Data Security Black.svg';
import DataSecurityWhite from './Data Security white.svg';
import EnvironmentsDark from './Environments Dark.svg';
import EnvironmentsWhite from './Environments White.svg';
import ExceptionsBlack from './Exceptions Black.svg';
import ExceptionsWhite from './Exceptions White.svg';
import GroupsBlack from './Groups Black.svg';
import IdentityBlack from './Identity Black.svg';
import IdentityWhite from './Identity White.svg';
import IdpBlack from './IdP Black.svg';
import InsightsBlack from './Insights black.svg';
import InsightsWhite from './Insights white.svg';
import InventoryBlack from './Inventory black.svg';
import InventoryWhite from './Inventory white.svg';
import KeyInsightsBlack from './Key Insights Black.svg';
import KeyInsightsWhite from './Key Insights White.svg';
import KubernetesBlack from './Kubernetes-icon-dark.svg';
import KubernetesWhite from './Kubernetes-icon-white.svg';
import OverviewBlack from './Overview Black.svg';
import OverviewWhite from './Overview White.svg';
import PoliciesBlack from './Policies Black.svg';
import RBACBlack from './rbac-b.svg';
import RBACWhite from './rbac-w.svg';
import RemediationHubBlack from './Remediation hub black.svg';
import RemediationHubWhite from './Remediation hub white.svg';
import ReportsBlack from './Reports Black.svg';
import ResourcesBlack from './Resources Black.svg';
import ResourcesWhite from './Resources White.svg';
import RiskBlack from './Risk Black.svg';
import RiskWhite from './Risk White.svg';
import RolesBlack from './Roles Black.svg';
import RolesWhite from './Roles White.svg';
import RulesBlack from './Rules Black.svg';
import RulesWhite from './Rules White.svg';
import ScanBlack from './Scan Black.svg';
import ScanWhite from './Scan White.svg';
import SecurityScoreBlack from './SecurityScore Black.svg';
import SuppressedEventsBlack from './Suppressed Events Black.svg';
import UsersBlack from './Users Black.svg';
import UsersWhite from './Users White.svg';
import VulnerabilitiesBlack from './Vulnerabilities Black.svg';
import WorkloadBlack from './WorkLoad Black.svg';
import WorkloadWhite from './Workload White.svg';

import AllEventsWhite from './All Events White.svg';
import ComplianceWhite from './Compliance White.svg';
import GroupsWhite from './Groups White.svg';
import IdpWhite from './IdP White.svg';
import PoliciesWhite from './Policies White.svg';
import ReportsWhite from './Reports White.svg';
import SecurityScoreWhite from './SecurityScore White.svg';
import SuppressedEventsWhite from './Suppressed Events White.svg';
import VulnerabilitiesWhite from './Vulnerabilities White.svg';
const blackIcons = {
  RBAC: RBACBlack,
  Scan: ScanBlack,
  'Scan Report': ScanBlack,
  Dashboard: DashboardBlack,
  'Data Security': DataSecurityBlack,
  Admin: AdminBlack,
  CDR: CDRBlack,
  'Cloud Detection & Response': CDRBlack,
  Environments: EnvironmentsDark,
  'Key Insights': KeyInsightsBlack,
  Identity: IdentityBlack,
  Insights: InsightsBlack,
  Inventory: InventoryBlack,
  Kubernetes: KubernetesBlack,
  'Remediation Hub': RemediationHubBlack,
  Workload: WorkloadBlack,
  Rules: RulesBlack,
  Overview: OverviewBlack,
  Resources: ResourcesBlack,
  Alert: AlertsBlack,
  Exceptions: ExceptionsBlack,
  Risk: RiskBlack,
  Users: UsersBlack,
  Roles: RolesBlack,
  Policies: PoliciesBlack,
  Groups: GroupsBlack,
  IdP: IdpBlack,
  'All Events': AllEventsBlack,
  Reports: ReportsBlack,
  Vulnerabilities: VulnerabilitiesBlack,
  'Action History': SuppressedEventsBlack,
  Compliance: ComplianceBlack,
  Notification: AlertsBlack,
  'Security Score': SecurityScoreBlack,
  Seccomp: UsersBlack,
  Code: CodeBlack,
  Workspace: CodeBlack,
};

const whiteIcons = {
  RBAC: RBACWhite,
  Scan: ScanWhite,
  Dashboard: DashboardWhite,
  'Data Security': DataSecurityWhite,
  Admin: AdminWhite,
  CDR: CDRWhite,
  'Cloud Detection & Response': CDRWhite,
  Environments: EnvironmentsWhite,
  Identity: IdentityWhite,
  Insights: InsightsWhite,
  Inventory: InventoryWhite,
  Kubernetes: KubernetesWhite,
  'Remediation Hub': RemediationHubWhite,
  Workload: WorkloadWhite,
  Rules: RulesWhite,
  Overview: OverviewWhite,
  Resources: ResourcesWhite,
  Alert: AlertsWhite,
  Exceptions: ExceptionsWhite,
  Risk: RiskWhite,
  'Key Insights': KeyInsightsWhite,
  Users: UsersWhite,
  Roles: RolesWhite,
  Policies: PoliciesWhite,
  Groups: GroupsWhite,
  IdP: IdpWhite,
  'All Events': AllEventsWhite,
  Reports: ReportsWhite,
  Vulnerabilities: VulnerabilitiesWhite,
  'Action History': SuppressedEventsWhite,
  Compliance: ComplianceWhite,
  'Security Score': SecurityScoreWhite,
  Notification: AlertsWhite,
  Seccomp: UsersWhite,
  Workspace: CodeBlack,
};

export const BreadcrumbIconMapping = ({ title, subtitle }) => {
  const { isDarkMode } = useTheme();
  const findMatchingKey = subtitle => {
    return Object.keys(blackIcons || {})?.find(key => subtitle?.toLowerCase()?.includes(key?.toLowerCase()));
  };
  let mappingKey = findMatchingKey(subtitle);
  if (!mappingKey) {
    mappingKey = findMatchingKey(title);
  }
  if (!isDarkMode) {
    return (
      <img
        src={blackIcons[`${mappingKey}`]}
        alt=""
        style={{
          marginRight: '5px',
          height: '12px',
          width: '12px',
        }}
      />
    );
  } else {
    return (
      <img src={whiteIcons[`${mappingKey}`]} alt="" style={{ marginRight: '5px', height: '12px', width: '12px' }} />
    );
  }
};
