import React, { useEffect, useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import bulb from '@root/assets/img/bulb.png';
import bulbGlow from '@root/assets/svg/mage_light-bulbaa.svg';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import agent from '@root/agent';
import { useSelectedEnvironments } from '@root/hooks/environments';
import useEnhancedQuery from '@root/query/useEnhancedQuery';
// import { useTheme } from '@root/theme/ThemeContext';
import { useTheme } from '@root/context/ThemeContext';

const themeStyles = {
  light: {
    background: '#ffffff',
    textPrimary: '#4b5563',
    textSecondary: '#374151',
    iconColor: '#9CA3AF',
    toggleOffBg: '#E5E7EB',
    toggleOnBg: '#2563EB',
    toggleButtonBg: '#ffffff',
  },
  dark: {
    background: '#1a202c',
    textPrimary: '#a0aec0',
    textSecondary: '#e2e8f0',
    iconColor: '#718096',
    toggleOffBg: '#4a5568',
    toggleOnBg: '#2563EB',
    toggleButtonBg: '#e2e8f0',
  },
};

const RiskSpotlightToggle = ({ setIsEnabled, isEnabled, onChange, type }) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? themeStyles.dark : themeStyles.light;

  const { selectedEnvironments } = useSelectedEnvironments();

  const _type = useMemo(() => {
    if (type === 0) return { CVEs: true };
    if (type === 1) return { workload: true };
    if (type === 2) return { images: true };
    if (type === 3) return { sbom: true };
  }, [type]);
  const { data: spotlight } = useEnhancedQuery(
    [
      'get_workload_table_data',
      _type,
      isEnabled,
      selectedEnvironments.map(item => item.id),
      // selectedEnvironmentTags,
    ],
    () =>
      agent.Kubernetes.get_risk_spotlight({
        // scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),

        clusters: [],

        namespace: null,
        kind: null,
        risk_spotlight: true,
        ..._type,
      }),
    {
      enabled: isEnabled,
    },
  );
  useEffect(() => {
    if (type !== 1) {
      setIsEnabled(false);
    }
  }, [setIsEnabled, type]);

  const handleToggle = () => {
    const newState = !isEnabled;
    setIsEnabled(newState);
    if (onChange) {
      onChange(newState);
    }
  };
  const renderRiskSpotlightText = text => {
    // Split the text by the angle brackets
    const parts = text.split(/(<[^>]*>)/g);

    return parts.map((part, index) => {
      if (part.startsWith('<') && part.endsWith('>')) {
        // Extract the content between the angle brackets
        const content = part.substring(1, part.length - 1);

        // Return the content with increased font weight
        return (
          <span key={index} style={{ fontWeight: '700' }}>
            {content}
          </span>
        );
      }

      // Return regular text
      return <span key={index}>{part}</span>;
    });
  };
  const tooltipMsg =
    'Risk spotlight prioritizes vulnerabilities that pose a real risk to your organization using workload configuration, runtime context, exploitability, severity, and fixability, cutting the noise of your CVE scanning results by >90% and helping you focus on the alerts that actually matter.';

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px 16px',
      // backgroundColor: theme.background,
      transition: 'background-color 0.2s ease',
    },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    lightbulbIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      fontSize: '14px',
      color: theme.textPrimary,
      fontWeight: 500,
      fontFamily: 'Manrope',
    },
    textActive: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Manrope',
      background: 'linear-gradient(90deg, #001AFF -8.47%, #00DBDE 68.96%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
    },
    boldText: {
      fontWeight: '500',
    },
    rightContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    toggleContainer: {
      position: 'relative',
      display: 'inline-flex',
      height: '24px',
      width: '44px',
      alignItems: 'center',
      borderRadius: '999px',
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',
      backgroundColor: isEnabled ? theme.toggleOnBg : theme.toggleOffBg,
    },
    toggleButton: {
      display: 'inline-block',
      height: '20px',
      width: '20px',
      borderRadius: '999px',
      backgroundColor: theme.toggleButtonBg,
      transition: 'transform 0.2s ease',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      transform: isEnabled ? 'translateX(20px)' : 'translateX(2px)',
    },
    riskText: {
      fontSize: '14px',
      fontWeight: '500',
      color: theme.textSecondary,
    },
    infoIcon: {
      color: theme.iconColor,
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftContent}>
        <div style={styles.lightbulbIcon}>
          {isEnabled ? <img src={bulbGlow} style={{ width: '24px' }} /> : <img src={bulb} style={{ width: '24px' }} />}
        </div>
        {isEnabled ? (
          <span style={styles.textActive}>{renderRiskSpotlightText(spotlight?.cut_vulnerabilities ?? '')} </span>
        ) : (
          <span style={styles.text}>
            Turn on <span style={styles.boldText}>Risk spotlight</span> to prioritize vulnerabilities that pose a real
            risk to your organization using runtime context.
          </span>
        )}
      </div>

      <div style={styles.rightContent}>
        <div style={styles.toggleContainer} onClick={handleToggle}>
          <span style={styles.toggleButton} />
        </div>
        <span style={styles.riskText}>Risk spotlight</span>
        <div style={styles.infoIcon}>
          <Tooltip title={tooltipMsg} isIcon={true}>
            <InfoIcon size={16} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default RiskSpotlightToggle;
