import commonTableColumns from '@root/components/table/commonTableColumns';
import { VulnerabilityCounts } from '../VulnerabilityCounts';
import CountTooltip from '@root/components/Tooltip/CountTooltip';

export const sbomColumns = [
  commonTableColumns.environment('account_id','cluster' ),
 
  {
    field: 'name',
    headerName: 'Component',
    width: 230,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
  },
  {
    field: 'fix_version',
    headerName: 'Fix Version',
    renderCell: params => {
      return params?.row?.version ?? '-';
    },
    width: 250,
  },
  {
    field: 'images',
    headerName: 'Images',
    renderCell: params => {
      return <CountTooltip labels={params?.row?.related_images} />;
    },
    width: 300,
  },
  {
    field: 'workloads',
    headerName: 'Workloads',
    renderCell: params => {
      return <CountTooltip labels={params?.row?.related_workloads} />;
    },
    width: 250,
  },
  {
    field: 'namespaces',
    headerName: 'Namespaces',
    renderCell: params => {
      return <CountTooltip labels={params?.row?.related_namespaces} />;
    },
    width: 200,
  },
  {
    field: 'cluster',
    headerName: 'Cluster',
    width: 230,
  },

  {
    field: 'vulnerabilities',
    headerName: 'Vulnerabilities',
    width: 250,
    renderCell: params => {
      return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts} />;
    },
  },
];
