import React, { forwardRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import './count-tooltip.scss';

// Keep the original styling
const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.85rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#fafafa',
  },
}))(Tooltip);

const MaterialUlTooltip = forwardRef(({ label, children }, ref) => {
  // Simplify the conditional rendering
  // const hasLabel = label && label.length > 0;

  // If no valid label, return children without wrapper
  // if (!hasLabel) {
  //   return <span ref={ref}>{children}</span>;
  // }
  if (!label) return children;
  return (
    <HtmlTooltip
      title={<div className="CountTooltip__Content">{label}</div>}
      arrow
      // PopperProps={{
      //   // Ensure consistent positioning
      //   modifiers: [
      //     {
      //       name: 'preventOverflow',
      //       enabled: true,
      //       options: {
      //         boundariesElement: 'viewport',
      //       },
      //     },
      //     {
      //       name: 'zIndex',
      //       enabled: true,
      //       phase: 'write',
      //       fn: ({ state }) => {
      //         state.styles.popper.zIndex = 10;
      //       },
      //     },
      //     {
      //       name: 'offset',
      //       enabled: true,
      //       options: {
      //         offset: [0, 8], // Slight offset from element
      //       },
      //     },
      //   ],
      // }}
      // Add these props for better reliability
      // disableInteractive={false}
      // enterDelay={100}
      // leaveDelay={100}
    >
      <span
        ref={ref}
        style={{
          display: 'inline-block',
          // cursor: 'pointer', // Ensure the element is interactive
        }}>
        {children}
      </span>
    </HtmlTooltip>
  );
});

// Add display name for better debugging
// MaterialUlTooltip.displayName = 'MaterialUlTooltip';

export default MaterialUlTooltip;
