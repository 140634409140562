// import commonTableColumns from '@root/components/table/commonTableColumns';
import { VulnerabilityCounts } from '../VulnerabilityCounts';

export const imageColumns = [
 
  {
    field: 'name',
    headerName: 'Image',
    width: 230,
  },
  {
    field: 'workload',
    headerName: 'Workload',
    renderCell : (params)=>  params?.row?.workload_name || '-' ,
    width: 230,
  },
  

  {
    field: 'namespace',
    headerName: 'Namespace',
    renderCell : (params)=>  params?.row?.workload_namespace  ||  params?.row?.namespace  || '-',
    width: 200,
  },
  {
    field: 'cluster',
    headerName: 'Cluster',
    width: 230,
  },
  {
    field: 'vulnerabilities',
    headerName: 'Vulnerabilities',
    width: 250,
    renderCell: params => {
      return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts} />;
    },
  },
];
