import { NoIcon, YesIcon } from '@root/assets/svg/SVGComponents';
import React from 'react';
import './style.scss';

const YesNo = ({ yes }: { yes: boolean; no: boolean }) => {
  if (yes) {
    return (
      <div className="YesNo__Container">
        <YesIcon />
        Yes
      </div>
    );
  }

  return (
    <div className="YesNo__Container">
      <NoIcon />
      No
    </div>
  );
};

export default YesNo;
