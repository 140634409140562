// import networkIcon from '@assets/svg/risk/Network.svg';
// import dataIcon from '@assets/svg/risk/Data.svg';
// import identityIcon from '@assets/svg/risk/Identity.svg';
// import lateralMovementIcon from '@assets/svg/risk/LateralMovement.svg';
// import encryptionAtRestIcon from '@assets/svg/risk/EncryptionAtRest.svg';
// import encryptionInEncryptionIcon from '@assets/svg/risk/EncryptionInEncryption.svg';
// import highAvailabilityIcon from '@assets/svg/risk/HighAvailability.svg';
// import loggingAndMonitoringIcon from '@assets/svg/risk/LoggingAndMonitoring.svg';

import anomalousLogo from '@root/assets/svg/Risk/RiskPriority/anomalous.svg';
import adminLogo from '@root/assets/svg/Risk/RiskPriority/admin.svg';
import effectiveAdminLogo from '@root/assets/svg/Risk/RiskPriority/effective-admin.png';
// import effectiveAdminLogo from '@root/assets/svg/Risk/RiskPriority/effective-admin.svg';
import crossAccountLogo from '@root/assets/svg/Risk/RiskPriority/cross-account.svg';
import inactiveLogo from '@root/assets/svg/Risk/RiskPriority/inactive.png';
// import inactiveLogo from '@root/assets/svg/Risk/RiskPriority/inactive.svg';
import learningLogo from '@root/assets/svg/Risk/RiskPriority/learning.svg';
import noMfaLogo from '@root/assets/svg/Risk/RiskPriority/no-mfa.svg';
// import overPrivilegedLogo from '@root/assets/svg/Risk/RiskPriority/over-privileged.png';
import overPrivilegedLogo from '@root/assets/svg/Risk/RiskPriority/over-privileged.svg';
import threatLogo from '@root/assets/svg/Risk/RiskPriority/threat.svg';
import accessKey1NotRotatedLogo from '@root/assets/svg/Risk/RiskPriority/access-key1-not-rotated.svg';
import accessKey2NotRotatedLogo from '@root/assets/svg/Risk/RiskPriority/access-key2-not-rotated.svg';
import rootUserLogo from '@root/assets/svg/Risk/RiskPriority/root-user.svg';
import lateralMovementLogo from '@root/assets/svg/Risk/RiskPriority/lateral-movement.svg';
import permissionManagementLogo from '@root/assets/svg/Risk/RiskPriority/permission-management.png';
// import permissionManagementLogo from '@root/assets/svg/Risk/RiskPriority/permission-management.svg';
import dataAccessLogo from '@root/assets/svg/Risk/RiskPriority/data-access.svg';
import infrastructureModificationLogo from '@root/assets/svg/Risk/RiskPriority/infrastructure-modification.svg';
import reconnaissanceLogo from '@root/assets/svg/Risk/RiskPriority/reconnaissance.svg';
import credentialExposureLogo from '@root/assets/svg/Risk/RiskPriority/credential-exposure.svg';
import environmentAccessLogo from '@root/assets/svg/Risk/RiskPriority/environment-access.svg';
import priviledgeLogo from '@root/assets/svg/Risk/RiskPriority/privledge.png';
// import priviledgeLogo from '@root/assets/svg/Risk/RiskPriority/privledge.svg';
import privilegeEscalationLogo from '@root/assets/svg/Risk/RiskPriority/privilege-escalation.svg';
import multipleAccessKeysActiveLogo from '@root/assets/svg/Risk/RiskPriority/multiple-access-keys-active.png';
// import multipleAccessKeysActiveLogo from '@root/assets/svg/Risk/RiskPriority/multiple-access-keys-active.svg';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const IMAGE_MAP = {
  ['Effective Admin']: effectiveAdminLogo,
  ['Admin']: adminLogo,
  ['Cross Account']: crossAccountLogo,
  ['Inactive']: inactiveLogo,
  ['Over Privileged']: overPrivilegedLogo,
  ['Anomalous']: anomalousLogo,
  ['Learning']: learningLogo,
  ['Threat']: threatLogo,
  ['No Mfa']: noMfaLogo,
  ['Access Key 1 Not Rotated']: accessKey1NotRotatedLogo,
  ['Access Key 2 Not Rotated']: accessKey2NotRotatedLogo,
  ['Multiple Access Keys Active']: multipleAccessKeysActiveLogo,
  ['Root User']: rootUserLogo,
  ['Privileged']: priviledgeLogo,
  ['Privilege Escalation']: privilegeEscalationLogo,
  ['Lateral Movement']: lateralMovementLogo,
  ['Permission Management']: permissionManagementLogo,
  ['Data Access']: dataAccessLogo,
  ['Infrastructure Modification']: infrastructureModificationLogo,
  ['Reconnaissance']: reconnaissanceLogo,
  ['Credential Exposure']: credentialExposureLogo,
  ['Environment Access']: environmentAccessLogo,
};
export const RiskPriorityIcons = ({ name, paddingRight = '5px', rowIndex, width = '25px', height = '25px' }) => {
  return (
    <span style={{ paddingRight: paddingRight }}>
      <Tooltip title={name === 'No Mfa' ? 'No MFA' : name} isIcon={true} arrow>
        <img
          id={`${name?.toLowerCase()}-${rowIndex}`}
          src={IMAGE_MAP[name]}
          style={{ objectFit: 'contain', width: width, height: height }}
          alt={name}
        />
      </Tooltip>
    </span>
  );
};
