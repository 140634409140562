import React from 'react';

import { azure_icon } from '@root/components/utilities/CloudIconsUtil';
import aws_icon from '@root/assets/img/cloud_provider_icons/aws_icon.png';
import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';
// import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import { GetCloudProvider, GetEnvTags } from '@root/pipes/getEnvironmentDetails';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const Environment = ({ environmentName, environmentId, cluster }) => {
  const cloud = GetCloudProvider(environmentId) ?? 'aws';
  const environmentTags = GetEnvTags(environmentId) ?? ['prod'];
  console.log(environmentTags?.join(', '), 'kdfnksdfnkj');
  return (
    <div className="d-flex align-items-center" style={{ overflow: 'hidden' }}>
      <div className="d-flex align-items-center justify-content-center" style={{ width: '36px' }}>
        {cloud === 'aws' && <img src={aws_icon} alt="AWS" style={{ height: '18px' }} />}
        {cloud === 'azure' && <img src={azure_icon} alt="AZURE" style={{ height: '18px' }} />}
        {cloud === 'gcp' && <img src={GCPIcon} alt="GCP" style={{ height: '18px' }} />}
      </div>
      <div className="d-flex flex-column flex align-items-start ml-2 flex-grow-1 overflow-hidden">
        {environmentName ? <div>{environmentName}</div> : null}
        <span> {cluster ? <div>{cluster}</div> : null}</span>

        {environmentId ? (
          <Tooltip title={`${environmentTags?.join(', ')}`} isIcon={true}>
            <div style={{ marginRight: '5px' }}>
              {environmentId.length > 30 ? environmentId.substring(0, 30) + '...' : environmentId}
            </div>{' '}
          </Tooltip>
        ) : null}
        {/* diding for k8  */}
        {/* {environmentTags && environmentTags.length ? (
          <CountTooltip
            labels={environmentTags}
            width="auto"
            containerClassName="RemediationPolicyTable__EnvironmentTag"
          />
        ) : null} */}
      </div>
    </div>
  );
};

export default Environment;
