import _ from 'lodash';
import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';
import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
import warning_icon from '@root/assets/svg/icons/warning_icon.svg';
import info_icon from '@root/assets/svg/icons/info_icon.svg';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import HighIcon from '@root/components/svg/high';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';
import lowIcon2 from '@assets/svg/New_Severity_Icons/Low.svg';
import warning from '@assets/svg/New_Severity_Icons/warning_severity.svg';
import info from '@assets/svg/workload/info.svg';
import mediumIcon2 from '@assets/svg/New_Severity_Icons/Medium.svg';
import highIcon2 from '@assets/svg/New_Severity_Icons/High.svg';
import criticalIcon2 from '@assets/svg/New_Severity_Icons/Critical.svg';
// import Text from '@root/components/v2/ui-elements/Text';
import { capitalizeFirstLetter } from '@root/utilities/string';
const severityColorMapper = {
  all: '#405877',
  critical: '#FF0000',
  high: '#EB5757',
  medium: '#F2994A',
  low: '#F2C94C',
};

export const SeverityWithText = ({ propsValue }) => {
  if (!_.isEmpty(propsValue)) {
    if (propsValue.toUpperCase() === 'HIGH') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="High" isIcon={false}>
            {/* <HighIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={highIcon2} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'MEDIUM') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Medium" isIcon={false}>
            {/* <MediumIcon height={height} width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={mediumIcon2} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'LOW') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Low" isIcon={false}>
            {/* <LowIcon textDisabled={true} width={'88px'} horizontalPosition="flex-start" /> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={lowIcon2} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'CRITICAL') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Critical" isIcon={false}>
            {/* <CriticalIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={criticalIcon2} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'WARNING') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Warning" isIcon={false}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={warning} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'INFO') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Info" isIcon={false}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={info} style={{ height: '22px', width: '22px' }} />
              <div style={{ fontSize: '13px', fontFamily: 'Manrope', lineHeight: '20px' }}>
                {' '}
                {capitalizeFirstLetter(propsValue)}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    } else if (propsValue === 'non-compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#F62121', color: 'white', width: '100px', height: '18px' }}>
            Non-Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#03BB34', color: 'white', width: '100px', height: '18px' }}>
            Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'not-applicable') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#7657CC', color: 'white', width: '100px', height: '18px' }}>
            Not-Applicable
          </span>
        </div>
      );
    } else {
      return propsValue;
    }
  } else return '-';
};

export const SeverityBadge = ({ propsValue, height }) => {
  if (!_.isEmpty(propsValue)) {
    if (propsValue.toUpperCase() === 'HIGH') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="High" isIcon={false}>
            {/* <HighIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={highIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'MEDIUM') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Medium" isIcon={false}>
            {/* <MediumIcon height={height} width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={mediumIcon} style={{ height: height, width: '88px' }} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'LOW') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Low" isIcon={false}>
            {/* <LowIcon textDisabled={true} width={'88px'} horizontalPosition="flex-start" /> */}
            <img src={lowIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'CRITICAL') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Critical" isIcon={false}>
            {/* <CriticalIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={criticalIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'WARNING') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Warning" isIcon={false}>
            <img src={warning_icon} style={{ width: '88px' }} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'INFO') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="Info" isIcon={false}>
            <img src={info_icon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue === 'non-compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#F62121', color: 'white', width: '100px', height: '18px' }}>
            Non-Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#03BB34', color: 'white', width: '100px', height: '18px' }}>
            Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'not-applicable') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#7657CC', color: 'white', width: '100px', height: '18px' }}>
            Not-Applicable
          </span>
        </div>
      );
    } else {
      return propsValue;
    }
  } else return null;
};
export const SeverityLablel = ({ propsValue }) => {
  if (!_.isEmpty(propsValue)) {
    return (
      <p
        className="m-0"
        style={{
          fontSize: '11px',
          color: '#fff',
          fontWeight: '400',
          lineHeight: 2,
          textAlign: 'center',
          borderRadius: '5px',
          background: severityColorMapper[propsValue.toLowerCase()],
        }}>
        {capitalizeFirstLetter(propsValue)}{' '}
      </p>
    );
  } else return null;
};
