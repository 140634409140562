// How dynamic column widths are handled: ============================
// Every cell in the table is wrapped by the DynamicWidthCell component.
// When each cell renders, their width is shared with table.js using the
// setColumnWidth callback. In setColumnWidth, we don't directly update the
// columnWidths object as it can lead to way too many renders of this
// component; one render for each cell. In a table with 10 columns and 10
// rows, that's probably going to be 100 renders. To avoid too many renders,
// we update the ref columnWidthsBufferRef. We use a recursive setTimeout
// to check when columnWidthsBufferRef was last updated. If it's found
// to be updated more than 500ms ago, then we assume that there are no
// more cells left for which width is to be measured. We then commit the
// columnWidthsBufferRef to columnWidths which causes a render and the column
// widths are updated in the UI correctly.

import * as React from 'react';
import {
  DataGridPro,
  // GridToolbarContainer,
  // GridToolbarColumnsButton
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import _ from 'lodash';
import { useTheme } from '@root/context/ThemeContext';

import NoData from '../common/NoData';
import DynamicWidthCell from './dynamic-width-cell';
import './table.scss';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_KEY);

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 15, 25, 50, 100];

const getDetailPanelHeight = () => 'auto';

export default function MuiTable({
  columns,
  rows,
  components = {},
  expandRow = undefined,
  onRowClick,
  pagination = true,
  onTableChange,
  hideFooter = false,
  total_size,
  rowHeight = 80,
  noBorder,
  headerHeight,
  disableRowsPerPage,
  rowsPerPageOptions,
  pinnedColumns, // array of fields, e.g. ['name', 'environment_id']
  LoaderState = false,
  paginationMode, // server
  page, // for paginationMode === 'server, start from 1
  onPageChange,
  pageSize,
  onPageSizeChange,
  setPageSizeExternal,
  dynamicHeight = {},
  pointerOnHover,
  getDetailPanelContent,
  noDataMessage,
  disableVerticalScroll,
  getRowId,
  ref,
  expandedRowIds,
  checkboxSelection = false,
  sortingMode = 'client',
  handleSortModelChange = () => {},
  selectedRow,
  customClassName,
  disableDynamicColumn = false,
  isDynamicHeight = false,
  tableHeight,
  isCardTable = false,
  // borderLeft,
}) {
  const { isDarkMode } = useTheme();
  const [_pageSize, setPageSize] = React.useState(pageSize || DEFAULT_PAGE_SIZE);
  const [bulkMode] = React.useState(false);
  const [columnWidths, setColumnWidths] = React.useState({}); // { [index]: number }
  const columnWidthsBufferRef = React.useRef(columnWidths);
  const columnWidthsBufferLastUpdatedAtRef = React.useRef(undefined);
  const [error, setError] = React.useState(false);

  // const _rows = React.useMemo(() => {
  //   // if (
  //   //   rows &&
  //   //   rows[0] &&
  //   //   (Object.keys(rows[0]).includes('account_id') || Object.keys(rows[0]).includes('environment_id'))
  //   // ) {
  //   //   rows.map(row => ({ ...row, environment_tags: GetEnvTags(row?.account_id || row?.environment_id) }));
  //   // }
  //   return rows;
  // }, [rows]);
  // Define which columns are restricted
  React.useEffect(() => {
    if (!rows || !_.isArray(rows)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [rows]);

  const restrictedColumnsArray = [columns[0].field, columns[1].field];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(() => {
    const visibility = {};
    columns?.forEach(column => {
      visibility[column.field] = true;
    });
    return visibility;
  });

  const handleColumnVisibilityModelChange = newModel => {
    const updatedModel = { ...newModel };
    restrictedColumnsArray?.forEach(column => {
      updatedModel[column] = true; // Ensure restricted columns are always visible
    });
    setColumnVisibilityModel(updatedModel);
  };

  const isRowSelectable = React.useCallback(() => bulkMode === 'enable' || bulkMode === 'disable', [bulkMode]);

  const [columnWidthsInitialized, setColumnWidthsInitialized] = React.useState(false);

  const initialState = React.useMemo(
    () => ({
      ...(pinnedColumns?.length && { pinnedColumns: { left: pinnedColumns } }),
      detailPanel: { expandedRowIds: expandedRowIds },
    }),
    [expandedRowIds, pinnedColumns],
  );

  const setColumnWidth = React.useCallback(({ columnIndex, width }) => {
    columnWidthsBufferRef.current = {
      ...columnWidthsBufferRef.current,
      [columnIndex]: Math.max(width + 20, columnWidthsBufferRef.current[columnIndex] || 0),
    }; // 20 is the left and right paddings combined
    columnWidthsBufferLastUpdatedAtRef.current = Date.now();
  }, []);

  React.useEffect(() => {
    if (page && _pageSize && columns && rows) {
      // Keep this empty if block to ensure the effect is triggered for these deps changing.
    }

    columnWidthsBufferLastUpdatedAtRef.current = undefined;
    columnWidthsBufferRef.current = {};
    setColumnWidthsInitialized(false);
    setColumnWidths({});

    let invalidated = false;

    const checkAndCommitColumnWidthsBuffer = () => {
      if (invalidated) return;

      if (columnWidthsBufferLastUpdatedAtRef.current === undefined) {
        setTimeout(checkAndCommitColumnWidthsBuffer, 50);
      } else {
        if (Date.now() - columnWidthsBufferLastUpdatedAtRef.current >= 500) {
          // Commit the column widths buffer
          setColumnWidths(columnWidthsBufferRef.current);
          setColumnWidthsInitialized(true);
        } else {
          setTimeout(checkAndCommitColumnWidthsBuffer, 50);
        }
      }
    };

    checkAndCommitColumnWidthsBuffer();

    return () => {
      invalidated = true;
    };
  }, [page, _pageSize, columns, rows]);

  const _columns = React.useMemo(() => {
    return columns.map((c, index) => {
      const { minWidth, width, ...column } = c;
      return {
        ...column,
        minWidth: width || minWidth || columnWidths[index], // width and minWidth specified in the columns definition take precedence over the dynamic column width
        renderHeader: params => {
          const content = params.colDef.headerName || '';

          return (
            <DynamicWidthCell
              columnIndex={index}
              previousWidth={columnWidthsInitialized ? columnWidths[index] : 0}
              setColumnWidth={setColumnWidth}>
              {column.renderHeader ? column.renderHeader(params) : content}
            </DynamicWidthCell>
          );
        },
        renderCell: params => {
          const content = params.formattedValue || '';
          return (
            <DynamicWidthCell
              columnIndex={index}
              previousWidth={columnWidthsInitialized ? columnWidths[index] : 0}
              setColumnWidth={setColumnWidth}
              useCharacterLimit={column.useCharacterLimit}
              copyData={column.copyData}
              field={column.headerName}
              params={params}
              // columnWidths={columnWidths[index]}
              disableAbsolutePositioning={Boolean(width || minWidth)}>
              {column.renderCell ? column.renderCell(params) : content}
            </DynamicWidthCell>
          );
        },
      };
    });
  }, [columnWidths, columnWidthsInitialized, columns, setColumnWidth]);

  const handlePageChange = React.useCallback(
    p => {
      // +1 because mui table pages start from 0
      onPageChange(p + 1);
      if (onTableChange) onTableChange(p + 1);
    },
    [onPageChange, onTableChange],
  );

  const handlePageSizeChange = React.useCallback(
    newPageSize => {
      setPageSize(newPageSize);
      if (onPageSizeChange) {
        onPageSizeChange(newPageSize);
      }
    },
    [onPageSizeChange],
  );

  const _getDetailPanelContent = React.useCallback(
    row => (row?.row && expandRow ? expandRow(row.row) : {}),
    [expandRow],
  );

  const getRowClassName = React.useCallback(
    params => {
      return `${
        (params.row.shield_risk_severity ? `bl-${params.row.shield_risk_severity.toLowerCase()}` : '') ||
        (params.row.risk && _.isString(params.row.risk) ? `bl-${params?.row?.risk?.toLowerCase()}` : '')
      } ${params.row.show_configuration ? 'show-critical' : ''} ${
        params.row.classification == 'Critical' || params.row.status
          ? 'show-critical'
          : '' || params.row.edited_by
          ? 'show-critical'
          : ''
      } bl-base ${pointerOnHover ? 'pointer-on-hover' : ''}  ${
        selectedRow && _.isEqual(selectedRow, params.row) ? 'selected-row' : ''
      }`;
    },
    [pointerOnHover, selectedRow],
  );

  const tableStyle = React.useMemo(() => {
    let _height = '63.7vh';
    if (rowHeight) {
      _height = rowHeight * 10 + 140;
    } else {
      _height = 49.9 * 10 + 123;
    }

    // console.log(rows.length, '_rowsghhsd');
    // if (rows?.length > 0) {
    //   if (rows?.length >= 10 && rows?.length <= 25) {
    //     _height = 49.9 * rows?.length + 120;
    //   } else if (rows?.length < 10) {
    //     _height = 49.9 * 10 + 120;
    //   } else {
    //     _height = 49.9 * 25 + 120;
    //   }
    // }

    return {
      // FOR DYNAMIC ROW HEIGHT
      ...(isDynamicHeight && { height: _height }),
      ...(tableHeight && { height: tableHeight }),
      ...dynamicHeight,

      '.MuiDataGrid-columnHeader:last-child, .MuiDataGrid-cell:last-child': {
        borderRight: 'none',
      },
      // '.MuiDataGrid-main': {
      //   // border-top: 1px solid var(--border-color-light, #cbd5e0);
      //   // border-left: 1px solid var(--border-color-light, #cbd5e0);
      //   // border-right: 1px solid var(--border-color-light, #cbd5e0);
      //   // border-radius: 10px 10px 0px 0px;
      //   // padding: 5px;
      //   background: isCardTable ? '#fff' : '#f4f4f8',
      // },
      '&.MuiDataGrid-root.MuiDataGrid-root--densityStandard': {
        border: 'none',
        background: 'var(--muitable-background)',
        '.MuiDataGrid-main': {
          // border-top: 1px solid var(--border-color-light, #cbd5e0);
          // border-left: 1px solid var(--border-color-light, #cbd5e0);
          // border-right: 1px solid var(--border-color-light, #cbd5e0);
          borderRadius: isCardTable ? 'none' : '10px 10px 0px 0px',
          // padding: 5px;
          background: isCardTable ? 'var(--muitable-background)' : 'var(--muitable-header-bg)',
        },
      },
      '&.muiTable__custom--noBorder .MuiDataGrid-main': { border: 'none !important' },
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
        cursor: onRowClick ? 'pointer' : 'default',
        backgroundColor: 'var(--muitable-row-bg) !important',
      },
      '&.muiTable__custom--disableVerticalScroll .MuiDataGrid-virtualScroller': {
        overflowY: 'hidden',
      },
      m: '0',
      '.MuiTablePagination-selectLabel': {
        margin: '0',
      },
      '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
        opacity: '0',
        transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      //this
      '.MuiDataGrid-columnHeader:hover': {
        background: 'var(--muitable-header-hover)',
        '& .MuiDataGrid-columnHeaderTitle': {
          background: 'var(--muitable-header-hover)',
        },
      },
      '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
        opacity: LoaderState ? '0' : '1',
        transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      '.MuiTablePagination-displayedRows': {
        margin: '0',
      },
      '.bl-base': {
        borderLeft: '1px solid transparent',
      },
      '.bl-critical:hover': {
        borderColor: '#FF0000',
      },
      '.bl-high:hover': {
        borderColor: '#EB5757',
      },
      '.bl-medium:hover': {
        borderColor: '#F2994A',
      },
      '.bl-low:hover': {
        borderColor: '#F2C94C',
      },
      '.pointer-on-hover': {
        cursor: 'pointer',
      },
      '.css-zil1cx-MuiDataGrid-detailPanel': {
        zIndex: '0',
      },
      '.expand .css-1w5m2wr-MuiDataGrid-virtualScroller': {
        overflow: 'hidden',
      },
      '.expand css-1grl8tv': {
        overflow: 'hidden',
      },
      ' & .MuiDataGrid-cell:nth-last-child(2).MuiDataGrid-cell--withRenderer:has(+ .MuiDataGrid-cell:not(.MuiDataGrid-cell--withRenderer))':
        {
          borderRight: 'none !important',
        },
      '.MuiDataGrid-footerContainer': {
        // backgroundColor: 'transparent !important',
        background: 'var(--muitable-footer-bg)',
        borderRadius: '0px 0px 10px 10px',
      },
      // ' & .MuiDataGrid-cell:nth-last-child(2).MuiDataGrid-cell--withRenderer:has(+ .MuiDataGrid-cell:not(.MuiDataGrid-cell--withRenderer))':
      //   {
      //     borderRight: 'none !important',
      //   },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: '13px',
        height: '13px',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
        background: isDarkMode ? '#2d3748' : '#f5f5f5',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        borderRadius: '0px',
        backgroundColor: isDarkMode ? '#4a5568' : '#ccc',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
        background: isDarkMode ? '#718096 !important' : '#ccc !important',
      },
      '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:active': {
        background: '#b1b1b1 !important',
      },
      '.show-critical:hover': {
        '.critical': {
          display: 'flex !important',
        },
      },
      '.MuiDataGrid-cell': {
        color: 'var(--muitable-text-color)',
        // borderRight: isCardTable ? '0px' : '1px solid var(--border-color-light)',
        borderBottom: isCardTable ? '0px' : '',
        padding: isCardTable ? '' : '0px 10px 0px 5px !important',
      },
      '.MuiDataGrid-columnHeader': {
        height: '49.9px !important',
        // borderRight: isCardTable ? '0px' : '1px solid var(--border-color-light)',
        background: isCardTable ? 'var(--muitable-background)' : 'var(--muitable-header-bg)',
        color: isCardTable ? 'var(--muitable-pagination-text)' : 'var(--muitable-text-color)',
        fontWeight: isCardTable ? 500 : 600,
      },
      '& div.MuiDataGrid-columnHeaders': {
        minHeight: headerHeight ? `${headerHeight} !important` : '49.9px !important',
        maxHeight: headerHeight ? `${headerHeight} !important` : '49.9px !important',
        background: isCardTable ? 'var(--muitable-background)' : 'var(--muitable-header-bg)',
        borderBottom: isCardTable ? '' : '0px',
      },
      '& .MuiDataGrid-virtualScroller': {
        marginTop: headerHeight ? `${headerHeight} !important` : '49.9px !important',
        borderLeft: isCardTable ? 'none' : `5px solid var(--muitable-header-bg)`,
        borderRight: isCardTable ? 'none' : `5px solid var(--muitable-header-bg)`,
        // borderRadius: isCardTable ? '0px' : '15px',
      },
      '& .selected-row': {
        backgroundColor: isDarkMode ? '#2d3748 !important' : '#efefef !important',
      },
      '.MuiDataGrid-columnHeaderTitle': {
        color: isCardTable ? 'var(--muitable-pagination-text) !important' : 'var(--muitable-text-color)',
        fontWeight: isCardTable ? 500 : 600,
      },
    };
  }, [
    rowHeight,
    isDynamicHeight,
    tableHeight,
    dynamicHeight,
    onRowClick,
    LoaderState,
    isCardTable,
    headerHeight,
    isDarkMode,
  ]);
  const ErrorTable = React.useCallback(
    () => (
      <DataGridPro
        className={`muiTable__custom ${customClassName} ${noBorder ? 'muiTable__custom--noBorder' : ''} ${
          disableVerticalScroll ? 'muiTable__custom--disableVerticalScroll' : ''
        }`}
        sx={tableStyle}
        columns={columns}
        rows={[]}
        components={{ NoRowsOverlay: () => <NoData message={noDataMessage} height="100%" /> }}
        hideFooter={hideFooter}
        columnHeaderHeight={49.9}
      />
    ),
    [columns, customClassName, disableVerticalScroll, hideFooter, noBorder, noDataMessage, tableStyle],
  );

  const _components = React.useMemo(
    () =>
      Object.keys(components).length > 0
        ? {
            NoRowsOverlay: () => <NoData message={noDataMessage} height="100%" />,
            ErrorOverlay: () => <ErrorTable />,
            ...components,
          }
        : {
            DetailPanelExpandIcon: () => <div></div>,
            NoRowsOverlay: () => <NoData message={noDataMessage} height="100%" />,
            ErrorOverlay: () => <ErrorTable />,
          },
    [ErrorTable, components, noDataMessage],
  );

  try {
    return (
      <DataGridPro
        className={`muiTable__custom ${customClassName} ${noBorder ? 'muiTable__custom--noBorder' : ''} ${
          disableVerticalScroll ? 'muiTable__custom--disableVerticalScroll' : ''
        }`}
        sx={tableStyle}
        columns={disableDynamicColumn ? columns : _columns}
        loading={LoaderState}
        checkboxSelection={checkboxSelection}
        rows={rows}
        ref={ref}
        getRowId={getRowId}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        isRowSelectable={isRowSelectable}
        components={_components}
        rowThreshold={0}
        // error={true}
        header
        {...(expandRow && { getDetailPanelContent: _getDetailPanelContent })}
        pagination={pagination}
        {...(paginationMode && { paginationMode })}
        {...(page !== undefined && { page: page - 1 })}
        {...(onPageChange && { onPageChange: handlePageChange })}
        hideFooter={hideFooter}
        onPageSizeChange={handlePageSizeChange}
        pageSize={setPageSizeExternal || pageSize || _pageSize}
        {...(disableRowsPerPage
          ? null
          : rowsPerPageOptions
          ? { rowsPerPageOptions }
          : { rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS })}
        onRowClick={onRowClick}
        getRowClassName={getRowClassName}
        rowHeight={rowHeight}
        rowCount={total_size}
        sortingMode={sortingMode}
        onSortModelChange={handleSortModelChange}
        initialState={initialState}
        disableVirtualization={true}
        columnBufferPx={400}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        columnHeaderHeight={49.9}
        error={error}
      />
    );
  } catch (error) {
    console.error('Error while loading the table', error);
    return <ErrorTable />;
  }
}

export { DEFAULT_PAGE_SIZE };
