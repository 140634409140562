// import { useEffect } from 'react';
// import auto_scaling from '@assets/img/aws_services/auto-scaling.svg';
import access_analyzer from '@assets/img/aws_services/access-analyzer.svg';
// import sagemaker from '@assets/img/aws_services/sagemaker.svg';
// import direct_connect from '@assets/img/aws_services/direct_connect.svg';
// import amazon_cloudfront from '@assets/img/aws_services/amazon-cloudfront.svg';
// import amazon_elasticcache from '@assets/img/aws_services/amazon-elasticcache.svg';
// import amazon_redshift from '@assets/img/aws_services/amazon-redshift.svg';
// import amazon_s3 from '@assets/img/aws_services/amazon-s3.svg';
// import amazon_ses from '@assets/img/aws_services/amazon-ses.svg';
// import amazon_sns from '@assets/img/aws_services/amazon-sns.svg';
// import amazon_sqs from '@assets/img/aws_services/amazon-sqs.svg';
// import amazon_vpc from '@assets/img/aws_services/amazon-vpc.svg';
// import api_gateway from '@assets/img/aws_services/api-gateway.svg';
// import app_sync from '@assets/img/aws_services/app-sync.svg';
// import doc_db from '@assets/img/aws_services/doc-db.svg';
// import aws_certificate_manager from '@assets/img/aws_services/aws-certificate-manager.svg';
// import aws_network from '@assets/img/aws_services/aws_network.svg';
// import aws_kinesis from '@assets/img/aws_services/aws-kinesis.svg';
// import aws_cloudformation from '@assets/img/aws_services/aws-cloudformation.svg';
// import aws_guardduty from '@assets/img/aws_services/aws-guardduty.svg';
// import aws_cloudtrail from '@assets/img/aws_services/aws-cloudtrail.svg';
// import aws_cloudwatch from '@assets/img/aws_services/aws-cloudwatch.svg';
// import aws_codebuild from '@assets/img/aws_services/aws-codebuild.svg';
import aws_config from '@assets/img/aws_services/aws-config.svg';
// import aws_dynamodb from '@assets/img/aws_services/aws-dynamodb.svg';
// import aws_elastic_beanstalk from '@assets/img/aws_services/aws-elastic-beanstalk.svg';
// import aws_emr from '@assets/img/aws_services/aws-emr.svg';
// import aws_opensearch from '@assets/img/aws_services/aws-opensearch.svg';
// import aws_kms from '@assets/img/aws_services/aws-kms.svg';
// import aws_dms from '@assets/img/aws_services/aws-dms.svg';
import aws_dax from '@assets/img/aws_services/aws-dax.svg';
// import aws_lambda from '@assets/img/aws_services/aws-lambda.svg';
// import aws_organizations from '@assets/img/aws_services/aws-organizations.svg';
// import aws_rds from '@assets/img/aws_services/aws-rds.svg';
// import aws_route53 from '@assets/img/aws_services/aws-route53.svg';
// import ec2_instances from '@assets/img/aws_services/ec2-instances.svg';
// import ECR from '@assets/img/aws_services/ECR.svg';
// import ECS from '@assets/img/aws_services/ECS.svg';
// import EFS from '@assets/img/aws_services/EFS.svg';
// import elastic_load_balancing from '@assets/img/aws_services/elastic-load-balancing.svg';
// import iam from '@assets/img/aws_services/iam.svg';
// import secrets_manager from '@assets/img/aws_services/secrets-manager.svg';
// import security_group from '@assets/img/aws_services/security-group.svg';
// import vpc_elastic_network_interface from '@assets/img/aws_services/vpc-elastic-network-interface.svg';
// import amazon_sfn from '@assets/img/aws_services/aws_step_functions.svg';
// import amazon_wfa from '@assets/img/aws_services/wfa.svg';
// import amazon_glue from '@assets/img/aws_services/aws-glue.svg';
// import aws_step_functions from '@assets/img/aws_services/aws_step_functions.svg';
// import athena from '@root/assets/img/aws_services/aws_athena.svg';
import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Neptune from '@assets/img/aws_services/AWS_Neptune.svg';
import Ebs from '@assets/img/aws_services/AWS__EBS.svg';
import Account from '@assets/img/aws_services/AWS_Account.svg';
// import Backup from '@assets/img/aws_services/aws_backup_1.svg';
// import eks from '@assets/img/aws_services/aws_eks.svg';
// import aws_system_manager from '@assets/img/aws_services/aws-systems-manager.svg';
// import aws_fsx from '@assets/img/aws_services/aws-fsx.svg';
// import aws_events from '@assets/img/aws_services/aws-events.svg';
import aws_eventbridge from '@assets/img/aws_services/aws-eventbridge.svg';
// import aws_identity_center from '@assets/img/aws_services/aws-identity-center.png';
import cloud_dns from '@assets/img/gcp-service-icons/cloud_dns.svg';
import cloud_load_balancing from '@assets/img/gcp-service-icons/cloud_load_balancing.svg';
// import identity_and_access_management from '@assets/img/gcp-service-icons/identity_and_access_management.svg';
import gcp_iam from '@assets/img/gcp-service-icons/GCP.IAM.svg';
// import Cloud_key_management_service from '@assets/img/gcp-service-icons/key_management_service.svg';
import gcp_kms from '@assets/img/gcp-service-icons/GCP.KMS.svg';
// import compute_engine from '@assets/img/gcp-service-icons/compute_engine.svg';
import gcp_compute_engine from '@assets/img/gcp-service-icons/GCP.COMPUTE_ENGINE.svg';
import cloud_functions from '@assets/img/gcp-service-icons/cloud_functions.svg';
// import cloud_storage from '@assets/img/gcp-service-icons/cloud_storage.svg';
// import cloud_sql from '@assets/img/gcp-service-icons/cloud_sql.svg';
import gcp_cloud_sql from '@assets/img/gcp-service-icons/GCP.CLOUD_SQL.svg';
// import bigquery from '@assets/img/gcp-service-icons/bigquery.svg';
import gcp_big_query from '@assets/img/gcp-service-icons/GCP.BIG_QUERY.svg';
// import pubsub from '@assets/img/gcp-service-icons/pubsub.svg';
import gcp_pubsub from '@assets/img/gcp-service-icons/GCP.PUBSUB.svg';
// import cloud_logging from '@assets/img/gcp-service-icons/cloud_logging.svg';
import service_usage from '@assets/img/gcp-service-icons/service_discovery.svg';
import assetinventory from '@assets/img/gcp-service-icons/asset_inventory.svg';
import gcpvpc from '@assets/img/gcp-service-icons/virtual_private_cloud.svg';
// import dataproc from '@assets/img/gcp-service-icons/dataproc.svg';
import gcp_dataproc from '@assets/img/gcp-service-icons/GCP.DATAPROC.svg';
import { memo } from 'react';
import cognito from '@assets/img/aws_services/aws-cognito-logo.svg';
// import cloud_run from '@assets/img/gcp-service-icons/cloud_run.svg';
import gcp_cloud_run from '@assets/img/gcp-service-icons/GCP.CLOUD_RUN.svg';
import gcp_gke from '@assets/img/gcp-service-icons/gke_on-prem.svg';
import essential_contact from '@assets/img/gcp-service-icons/essential_contact.svg';
// import artifact_registry from '@assets/img/gcp-service-icons/artifact_registry.svg';
import gcp_artifact_registry from '@assets/img/gcp-service-icons/GCP.ARTIFACT_REGISTRY.svg';
// import google_kubernetes_engine from '@assets/img/gcp-service-icons/google_kubernetes_engine.svg';
import gcp_kubernetes_engine from '@assets/img/gcp-service-icons/GCP.KUBERNETES_ENGINE.svg';
// import gcp_lb from '@assets/img/gcp-service-icons/GCP_LB.png';
import gcp_lb from '@assets/img/gcp-service-icons/GCP.CLOUD_LOAD_BALANCING.svg';
// import cloud_monitoring from '@assets/img/gcp-service-icons/Cloud_Monitoring.svg';
import cloude_scheduler_rounded from '@assets/img/gcp-service-icons/Cloud_Scheduler_rounded.svg';
// import cloude_scheduler from '@assets/img/gcp-service-icons/Cloud_Scheduler.svg';
// import secret_manager from '@assets/img/gcp-service-icons/Secret_Manager.svg';
import secret_manager_rounded from '@assets/img/gcp-service-icons/Secret_manager_rounded.svg';
// import certificate_manager from '@assets/img/gcp-service-icons/Certificate_Manager.svg';
import certificate_manager_rounded from '@assets/img/gcp-service-icons/Certificate_manager_Rounded.svg';
// import cloude_armor from '@assets/img/gcp-service-icons/Cloud_Armor.svg';
import cloude_armor_rounded from '@assets/img/gcp-service-icons/Cloud_Scheduler_rounded.svg';
// import cloude_cdn from '@assets/img/gcp-service-icons/Cloud_CDN.svg';
import cloude_cdn_rounded from '@assets/img/gcp-service-icons/Cloud_CDN_rounded.svg';
// import cloude_composer from '@assets/img/gcp-service-icons/Cloud_Composer.svg';
import cloude_composer_rounded from '@assets/img/gcp-service-icons/Cloud_Composer_Rounded.svg';
import cloud_monitoring_rounded from '@assets/img/gcp-service-icons/Cloud_Monitoring_Rounded.svg';
import gcp_cf from '@assets/img/gcp-service-icons/GCP.CLOUD_FUNCTION.svg';
import gcp_cloud_storage from '@assets/img/gcp-service-icons/GCP.CLOUD_STORAGE.svg';
import gcp_cloud_logging from '@assets/img/gcp-service-icons/GCP.CLOUD_LOGGING.svg';

// const IMAGE_MAP = {
//   account: Account,
//   ebs: Ebs,
//   backup: Backup,
//   neptune: Neptune,
//   accessanalyzer: access_analyzer,
//   acm: aws_certificate_manager,
//   apigateway: api_gateway,
//   api: api_gateway,
// cloudtrail: aws_cloudtrail,
//   autoscaling: auto_scaling,
//   ['auto scaling']: auto_scaling,
//   auto: auto_scaling,
//   beanstalk: aws_elastic_beanstalk,
//   elasticbeanstalk: aws_elastic_beanstalk,
//   cloudformation: aws_cloudformation,
//   cloudfront: amazon_cloudfront,
//   ec2: ec2_instances,
//   ['stepfunctions']: aws_step_functions,
//   ['step functions']: aws_step_functions,
//   config: aws_config,
//   cloudwatch: aws_cloudwatch,
//   dynamodb: aws_dynamodb,
//   ecr: ECR,
//   ecs: ECS,
//   efs: EFS,
//   elb: elastic_load_balancing,
//   lambda: aws_lambda,
//   elbv2: elastic_load_balancing,
//   elasticache: amazon_elasticcache,
//   emr: aws_emr,
//   iam: iam,
//   kms: aws_kms,
//   organization: aws_organizations,
//   organizations: aws_organizations,
//   route53: aws_route53,
//   route: aws_route53,
//   ['aws route 53']: aws_route53,
//   ['route 53']: aws_route53,
//   rds: aws_rds,
//   redshift: amazon_redshift,
//   vpc: amazon_vpc,
//   s3: amazon_s3,
//   ses: amazon_ses,
//   secretsmanager: secrets_manager,
//   secrets: secrets_manager,
//   sns: amazon_sns,
//   sqs: amazon_sqs,
//   sfn: amazon_sfn,
//   waf: amazon_wfa,
//   glue: amazon_glue,
//   wafv2: amazon_wfa,
//   ['aws access analyzer']: access_analyzer,
//   ['access analyzer']: access_analyzer,
//   ['athena']: athena,
//   ['aws eks']: eks,
//   eks: eks,
//   gcpclouddns: cloud_dns,
//   gcpdns: cloud_dns,
//   dns: cloud_dns,
//   gcpcloudloadbalancing: cloud_load_balancing,
//   gcploadbalancer: cloud_load_balancing,
//   balancer: cloud_load_balancing,
//   'gcpidentityandaccessmanagement(iam)': identity_and_access_management,
//   gcpiam: identity_and_access_management,
//   'gcpcloudkeymanagementservice(kms)': Cloud_key_management_service,
//   gcpkms: Cloud_key_management_service,
//   gcpcomputeengine: compute_engine,
//   engine: compute_engine,
//   gcpcloudfunctions: cloud_functions,
//   cloudfunctions: cloud_functions,
//   gcpcloudstorage: cloud_storage,
//   cloudstorage: cloud_storage,
//   gcpcloudlogging: cloud_logging,
//   cloudlogging: cloud_logging,
//   gcpcloudsql: cloud_sql,
//   cloudsql: cloud_sql,
//   ['gcpbigquery']: bigquery,
//   bigquery: bigquery,
//   'gcppub/sub': pubsub,
//   'pub/sub': pubsub,
//   gcpserviceusage: service_usage,
//   '(su)': service_usage,
//   'gcpserviceusage(su)': service_usage,
//   gcpfunctions: cloud_functions,
// functions: cloud_functions,
//   gcpstorage: cloud_storage,
//   storage: cloud_storage,
//   gcplogging: cloud_logging,
//   logging: cloud_logging,
//   gcpsql: cloud_sql,
//   sql: cloud_sql,
//   gcpassetinventory: assetinventory,
//   inventory: assetinventory,
//   gcpvpc: gcpvpc,
//   gcpdataproc: dataproc,
//   dataproc: dataproc,
//   gcpgke: gcp_gke,
//   gke: gcp_gke,
//   gcpessentialcontacts: essential_contact,
//   contacts: essential_contact,
//   cloudrun: cloud_run,
//   gcpcloudrun: cloud_run,
//   gcpartifactregistry: artifact_registry,
//   registry: artifact_registry,
//   cognito: cognito,
//   ['aws cognito']: cognito,
//   gcpkubernetesengine: google_kubernetes_engine,
// };

const IMAGE_BG_COLOR_MAP = {
  awsautoscaling: '#d82c6d',
  ['auto scaling']: '#d82c6d',
  auto: '#d82c6d',
  autoscaling: '#d82c6d',
  awsec2: '#C8511B',
  ec2: '#C8511B',
  awssystemsmanager: '#B0084D',
  systemsmanager: '#B0084D',
  awsssm: '#B0084D',
  ssm: '#B0084D',
  awsecs: '#e77a0c',
  ecs: '#e77a0c',
  eks: '#e77a0c',
  ['aws eks']: '#e77a0c',
  awseks: '#e77a0c',
  awsredshift: '#4258da',
  redshift: '#4258da',
  awsbackup: '#589c32',
  backup: '#589c32',
  awsdirectconnect: '#8c56e9',
  awssagemaker: '#3fa58e',
  awsacm: '#f04144',
  acm: '#f04144',
  awsacmpca: '#f04144',
  acmpca: '#f04144',
  awsapigateway: '#E7157B',
  api: '#E7157B',
  awsapigatewayv2: '#E7157B',
  apigatewayv2: '#E7157B',
  apigateway: '#E7157B',
  awsappsync: '#ea3d7b',
  appsync: '#ea3d7b',
  awsdocdb: '#2E27AD',
  docdb: '#2E27AD',
  awsdocumentdb: '#2E27AD',
  documentdb: '#2E27AD',
  cloudtrail: '#e83a79',
  awscloudtrail: '#e83a79',
  awsnetworkfirewall: '#ee3f42',
  networkfirewall: '#ee3f42',
  awsnetwork: '#ee3f42',
  network: '#ee3f42',
  awscloudformation: '#e93c7a',
  cloudformation: '#e93c7a',
  awscloudfront: '#8c56e9',
  awskinesis: '#814ede',
  kinesis: '#814ede',
  awsstepfunctions: '#e73978',
  ['stepfunctions']: '#e73978',
  ['step functions']: '#e73978',
  awssfn: '#e73978',
  sfn: '#e73978',
  awscloudwatch: '#ea3c7b',
  cloudwatch: '#ea3c7b',
  awscodebuild: '#2E27AD',
  codebuild: '#2E27AD',
  awsopensearch: '#4D27A8',
  opensearch: '#4D27A8',
  awsdynamodb: '#2E27AD',
  dynamodb: '#2E27AD',
  awsecr: '#f58534',
  ecr: '#f58534',
  awsefs: '#50962e',
  efs: '#50962e',
  awselb: '#4D27A8',
  elb: '#4D27A8',
  awselb2: '#4D27A8',
  elb2: '#4D27A8',
  awselbv2: '#4D27A8',
  elbv2: '#4D27A8',
  awselasticache: '#4257da',
  awslambda: '#C8511B',
  lambda: '#C8511B',
  awsemr: '#7948d6',
  emr: '#7948d6',
  awsiam: '#BD0816',
  iam: '#BD0816',
  awskms: '#BD0816',
  kms: '#BD0816',
  awsdms: '#4969ea',
  dms: '#4969ea',
  organization: '#e73a78',
  awsorganizations: '#e73a78',
  awsroute53: '#7e4cdb',
  route: '#7e4cdb',
  ['aws route 53']: '#7e4cdb',
  ['route 53']: '#7e4cdb',
  awsrds: '#C925D1',
  rds: '#C925D1',
  awsvpc: '#4D27A8',
  vpc: '#4D27A8',
  awss3: '#1B660F',
  awss3bucket: '#1B660F',
  s3bucket: '#1B660F',
  s3: '#1B660F',
  sns: '#cf2466',
  awssns: '#cf2466',
  awssecretsmanager: '#ee3f43',
  secrets: '#ee3f43',
  awssqs: '#B0084D',
  sqs: '#B0084D',
  awswaf: '#ee3f42',
  awswafv2: '#ee3f42',
  waf: '#ee3f42',
  wafv2: '#ee3f42',
  ['athena']: '#8b55e8',
  awsathena: '#8b55e8',
  gcpbigquery: '#73626e',
  bigquery: '#73626e',
  'gcpidentityandaccessmanagement(iam)': '#00008b',
  gcpiam: '#00008b',
  'gcpcloudkeymanagementservice(kms)': '#001861',
  gcpkms: '#001861',
  gcpcomputeengine: '#00008b',
  computeengine: '#00008b',
  gcpcloudfunctions: '#006161',
  cloudfunctions: '#006161',
  gcpfunctions: '#006161',
  functions: '#006161',
  gcpcloudstorage: '#613a00',
  cloudstorage: '#613a00',
  gcpstorage: '#613a00',
  storage: '#613a00',
  gcpcloudlogging: '#ede574',
  cloudlogging: '#ede574',
  gcplogging: '#ede574',
  logging: '#ede574',
  gcpcloudsql: '#613a00',
  gcpsql: '#613a00',
  sql: '#613a00',
  gcpdataproc: '#00008b',
  dataproc: '#00008b',
  gcpcloudrun: '#00008b',
  cloudrun: '#00008b',
  gcpartifactregistry: '#00008b',
  artifactregistry: '#00008b',
  'gcppub/sub': '#c44d58',
  'pub/sub': '#c44d58',
  gcpkubernetesengine: '#00008b',
  kubernetesengine: '#00008b',
  gcplb: '#001861',
  loadbalancer: '#001861',
  awsses: '#d01c27',
  ses: '#d01c27',
  awsglue: '#4D27A8',
  glue: '#4D27A8',
  awsidentitycenter: '#d21e28',
  identitycenter: '#d21e28',
  awsguardduty: '#f04144',
  guardduty: '#f04144',
  awsfsx: '#448B27',
  fsx: '#448B27',
  awsevents: '#448B27',
  events: '#448B27',
  awsbeanstalk: '#ED7100',
  elasticbeanstalk: '#ED7100',
  awselasticbeanstalk: '#ED7100',
  beanstalk: '#ED7100',
};

const IMAGE_MAP = {
  awsaccount: Account,
  account: Account,
  ebs: Ebs,
  functions: cloud_functions,
  awsebs: Ebs,
  awsbackup: '/images/visualizer/service-icons/AWS_BACKUP.svg',
  awsneptune: Neptune,
  backup: '/images/visualizer/service-icons/AWS_BACKUP.svg',
  neptune: Neptune,
  accessanalyzer: access_analyzer,
  awsaccessanalyzer: access_analyzer,
  awsdirectconnect: '/images/visualizer/service-icons/AWS_DIRECTCONNECT.svg',
  awssagemaker: '/images/visualizer/service-icons/AWS_SAGEMAKER.svg',
  awsacm: '/images/visualizer/service-icons/AWS_ACM.svg',
  acm: '/images/visualizer/service-icons/AWS_ACM.svg',
  awsacmpca: '/images/visualizer/service-icons/AWS_ACM.svg',
  acmpca: '/images/visualizer/service-icons/AWS_ACM.svg',
  awsapigateway: '/images/visualizer/service-icons/AWS_API_GATEWAY.svg',
  api: '/images/visualizer/service-icons/AWS_API_GATEWAY.svg',
  awsapigatewayv2: '/images/visualizer/service-icons/AWS_API_GATEWAY.svg',
  apigatewayv2: '/images/visualizer/service-icons/AWS_API_GATEWAY.svg',
  apigateway: '/images/visualizer/service-icons/AWS_API_GATEWAY.svg',
  awsappsync: '/images/visualizer/service-icons/AWS_APPSYNC.svg',
  appsync: '/images/visualizer/service-icons/AWS_APPSYNC.svg',
  awsdocdb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  cloudtrail: '/images/visualizer/service-icons/AWS_CLOUDTRAIL.svg',
  docdb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  awsdocumentdb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  documentdb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  awsnetworkfirewall: '/images/visualizer/service-icons/AWS_NETWORKFIREWALL.svg',
  networkfirewall: '/images/visualizer/service-icons/AWS_NETWORKFIREWALL.svg',
  awsnetwork: '/images/visualizer/service-icons/AWS_NETWORKFIREWALL.svg',
  network: '/images/visualizer/service-icons/AWS_NETWORKFIREWALL.svg',
  awscloudtrail: '/images/visualizer/service-icons/AWS_CLOUDTRAIL.svg',
  awsautoscaling: '/images/visualizer/service-icons/AWS_AUTO_SCALING.svg',
  autoscaling: '/images/visualizer/service-icons/AWS_AUTO_SCALING.svg',
  ['auto scaling']: '/images/visualizer/service-icons/AWS_AUTO_SCALING.svg',
  auto: '/images/visualizer/service-icons/AWS_AUTO_SCALING.svg',
  awsbeanstalk: '/images/visualizer/service-icons/AWS_Elastic_Beanstalk.svg',
  elasticbeanstalk: '/images/visualizer/service-icons/AWS_Elastic_Beanstalk.svg',
  awselasticbeanstalk: '/images/visualizer/service-icons/AWS_Elastic_Beanstalk.svg',
  beanstalk: '/images/visualizer/service-icons/AWS_Elastic_Beanstalk.svg',
  awscloudformation: '/images/visualizer/service-icons/AWS_CLOUDFORMATION.svg',
  cloudformation: '/images/visualizer/service-icons/AWS_CLOUDFORMATION.svg',
  awscloudfront: '/images/visualizer/service-icons/AWS_CLOUDFRONT.svg',
  awsec2: '/images/visualizer/service-icons/AWS_EC2.svg',
  ec2: '/images/visualizer/service-icons/AWS_EC2.svg',
  awsevents: '/images/visualizer/service-icons/AWS_Events.svg',
  events: '/images/visualizer/service-icons/AWS_Events.svg',
  awsidentitycenter: '/images/visualizer/service-icons/AWS_Identity_Center.svg',
  identitycenter: '/images/visualizer/service-icons/AWS_Identity_Center.svg',
  awseventbridge: aws_eventbridge,
  eventbridge: aws_eventbridge,
  awsfsx: '/images/visualizer/service-icons/AWS_Fsx.svg',
  fsx: '/images/visualizer/service-icons/AWS_Fsx.svg',
  awssystemsmanager: '/images/visualizer/service-icons/SSM.svg',
  systemsmanager: '/images/visualizer/service-icons/SSM.svg',
  awsssm: '/images/visualizer/service-icons/SSM.svg',
  ssm: '/images/visualizer/service-icons/SSM.svg',
  awsdax: aws_dax,
  dax: aws_dax,
  awskinesis: '/images/visualizer/service-icons/AWS_KINESIS.svg',
  kinesis: '/images/visualizer/service-icons/AWS_KINESIS.svg',
  awsstepfunctions: '/images/visualizer/service-icons/AWS_STEPFUNCTION.svg',
  ['stepfunctions']: '/images/visualizer/service-icons/AWS_STEPFUNCTION.svg',
  ['step functions']: '/images/visualizer/service-icons/AWS_STEPFUNCTION.svg',
  awsconfig: aws_config,
  config: aws_config,
  awscloudwatch: '/images/visualizer/service-icons/AWS_CLOUDWATCH.svg',
  cloudwatch: '/images/visualizer/service-icons/AWS_CLOUDWATCH.svg',
  awscodebuild: '/images/visualizer/service-icons/AWS_CODEBUILD.svg',
  codebuild: '/images/visualizer/service-icons/AWS_CODEBUILD.svg',
  awsopensearch: '/images/visualizer/service-icons/AWS_OPENSEARCH.svg',
  opensearch: '/images/visualizer/service-icons/AWS_OPENSEARCH.svg',
  awsdynamodb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  dynamodb: '/images/visualizer/service-icons/AWS_DOCUMENTDB.svg',
  awsecr: '/images/visualizer/service-icons/AWS_ECR.svg',
  awsecs: '/images/visualizer/service-icons/AWS_ECS.svg',
  awsefs: '/images/visualizer/service-icons/AWS_EFS.svg',
  ecr: '/images/visualizer/service-icons/AWS_ECR.svg',
  ecs: '/images/visualizer/service-icons/AWS_ECS.svg',
  efs: '/images/visualizer/service-icons/AWS_EFS.svg',
  awselb2: '/images/visualizer/service-icons/AWS_ELB.svg',
  elb2: '/images/visualizer/service-icons/AWS_ELB.svg',
  awselb: '/images/visualizer/service-icons/AWS_ELB.svg',
  elb: '/images/visualizer/service-icons/AWS_ELB.svg',
  awslambda: '/images/visualizer/service-icons/AWS_LAMBDA.svg',
  lambda: '/images/visualizer/service-icons/AWS_LAMBDA.svg',
  awselbv2: '/images/visualizer/service-icons/AWS_ELB.svg',
  elbv2: '/images/visualizer/service-icons/AWS_ELB.svg',
  awselasticache: '/images/visualizer/service-icons/AWS_ELASTICACHE.svg',
  awsemr: '/images/visualizer/service-icons/AWS_EMR.svg',
  emr: '/images/visualizer/service-icons/AWS_EMR.svg',
  awsiam: '/images/visualizer/service-icons/AWS_IAM.svg',
  iam: '/images/visualizer/service-icons/AWS_IAM.svg',
  awskms: '/images/visualizer/service-icons/AWS_KMS.svg',
  kms: '/images/visualizer/service-icons/AWS_KMS.svg',
  awsdms: '/images/visualizer/service-icons/AWS_DMS.svg',
  dms: '/images/visualizer/service-icons/AWS_DMS.svg',
  awsguardduty: '/images/visualizer/service-icons/AWS_Guardduty.svg',
  guardduty: '/images/visualizer/service-icons/AWS_Guardduty.svg',
  organization: '/images/visualizer/service-icons/AWS_ORGANISATION.svg',
  awsorganizations: '/images/visualizer/service-icons/AWS_ORGANISATION.svg',
  awsroute53: '/images/visualizer/service-icons/AWS_ROUTE53.svg',
  route: '/images/visualizer/service-icons/AWS_ROUTE53.svg',
  ['aws route 53']: '/images/visualizer/service-icons/AWS_ROUTE53.svg',
  ['route 53']: '/images/visualizer/service-icons/AWS_ROUTE53.svg',
  awsrds: '/images/visualizer/service-icons/AWS_RDS.svg',
  rds: '/images/visualizer/service-icons/AWS_RDS.svg',
  awsredshift: '/images/visualizer/service-icons/AWS_REDSHIFT.svg',
  redshift: '/images/visualizer/service-icons/AWS_REDSHIFT.svg',
  awsvpc: '/images/visualizer/service-icons/AWS_VPC.svg',
  vpc: '/images/visualizer/service-icons/AWS_VPC.svg',
  awss3: '/images/visualizer/service-icons/AWS_S3.svg',
  awss3bucket: '/images/visualizer/service-icons/AWS_S3.svg',
  s3bucket: '/images/visualizer/service-icons/AWS_S3.svg',
  s3: '/images/visualizer/service-icons/AWS_S3.svg',
  awsses: '/images/visualizer/service-icons/AWS_SES.svg',
  ses: '/images/visualizer/service-icons/AWS_SES.svg',
  eks: '/images/visualizer/service-icons/AWS_EKS.svg',
  awssecretsmanager: '/images/visualizer/service-icons/AWS_SECRETSMANAGER.svg',
  secrets: '/images/visualizer/service-icons/AWS_SECRETSMANAGER.svg',
  awssns: '/images/visualizer/service-icons/AWS_SNS.svg',
  awssqs: '/images/visualizer/service-icons/AWS_SQS.svg',
  awssfn: '/images/visualizer/service-icons/AWS_STEPFUNCTION.svg',
  awswaf: '/images/visualizer/service-icons/AWS_WAF.svg',
  awsglue: '/images/visualizer/resource-icons/AWS.Glue.svg',
  awswafv2: '/images/visualizer/service-icons/AWS_WAF.svg',
  sns: '/images/visualizer/service-icons/AWS_SNS.svg',
  sqs: '/images/visualizer/service-icons/AWS_SQS.svg',
  sfn: '/images/visualizer/service-icons/AWS_STEPFUNCTION.svg',
  waf: '/images/visualizer/service-icons/AWS_WAF.svg',
  glue: '/images/visualizer/resource-icons/AWS.Glue.svg',
  wafv2: '/images/visualizer/service-icons/AWS_WAF.svg',
  ['aws access analyzer']: access_analyzer,
  ['access analyzer']: access_analyzer,
  ['athena']: '/images/visualizer/service-icons/AWS_ATHENA.svg',
  ['aws eks']: '/images/visualizer/service-icons/AWS_EKS.svg',
  awsathena: '/images/visualizer/service-icons/AWS_ATHENA.svg',
  awseks: '/images/visualizer/service-icons/AWS_EKS.svg',
  gcpclouddns: cloud_dns,
  clouddns: cloud_dns,
  gcpdns: cloud_dns,
  dns: cloud_dns,
  gcpcloudloadbalancing: cloud_load_balancing,
  cloudloadbalancing: cloud_load_balancing,
  gcploadbalancer: cloud_load_balancing,
  balancer: cloud_load_balancing,
  'gcpidentityandaccessmanagement(iam)': gcp_iam,
  gcpiam: gcp_iam,
  'gcpcloudkeymanagementservice(kms)': gcp_kms,
  gcpkms: gcp_kms,
  gcpcomputeengine: gcp_compute_engine,
  computeengine: gcp_compute_engine,
  engine: gcp_compute_engine,
  gcpcloudfunctions: gcp_cf,
  cloudfunctions: gcp_cf,
  gcpcloudstorage: gcp_cloud_storage,
  cloudstorage: gcp_cloud_storage,
  gcpcloudlogging: gcp_cloud_logging,
  cloudlogging: gcp_cloud_logging,
  gcpcloudsql: gcp_cloud_sql,
  cloudsql: gcp_cloud_sql,
  // ['gcpbigquery']: gcp_big_query,
  gcpbigquery: gcp_big_query,
  bigquery: gcp_big_query,
  'gcppub/sub': gcp_pubsub,
  'pub/sub': gcp_pubsub,
  gcpserviceusage: service_usage,
  serviceusage: service_usage,
  'serviceusage(su)': service_usage,
  'gcpserviceusage(su)': service_usage,
  gcpfunctions: gcp_cf,
  // functions: gcp_cf,
  gcpstorage: gcp_cloud_storage,
  storage: gcp_cloud_storage,
  gcplogging: gcp_cloud_logging,
  logging: gcp_cloud_logging,
  gcpsql: gcp_cloud_sql,
  sql: gcp_cloud_sql,
  gcpassetinventory: assetinventory,
  inventory: assetinventory,
  gcpvpc: gcpvpc,
  gcpdataproc: gcp_dataproc,
  dataproc: gcp_dataproc,
  gcpgke: gcp_gke,
  gke: gcp_gke,
  gcpessentialcontacts: essential_contact,
  contacts: essential_contact,
  cloudrun: gcp_cloud_run,
  gcpcloudrun: gcp_cloud_run,
  gcpartifactregistry: gcp_artifact_registry,
  artifactregistry: gcp_artifact_registry,
  registry: gcp_artifact_registry,
  awscognito: cognito,
  cognito: cognito,
  ['aws cognito']: cognito,
  gcpkubernetesengine: gcp_kubernetes_engine,
  gcplb: gcp_lb,
  loadbalancer: gcp_lb,
  gcpcloudmonitoring: cloud_monitoring_rounded,
  cloudmonitoring: cloud_monitoring_rounded,
  gcpcloudschedulerr: cloude_scheduler_rounded,
  cloudschedulerr: cloude_scheduler_rounded,
  gcpcloudscheduler: cloude_scheduler_rounded,
  cloudscheduler: cloude_scheduler_rounded,
  gcpsecretmanager: secret_manager_rounded,
  secretmanager: secret_manager_rounded,
  gcpcertificatemanager: certificate_manager_rounded,
  certificatemanager: certificate_manager_rounded,
  gcpcloudarmor: cloude_armor_rounded,
  cloudarmor: cloude_armor_rounded,
  gcpcloudcdn: cloude_cdn_rounded,
  cloudcdn: cloude_cdn_rounded,
  gcpcloudcomposer: cloude_composer_rounded,
  cloudcomposer: cloude_composer_rounded,
};
const AWSServiceIcons = ({
  height = 20,
  width = 20,
  imgWidth = 28,
  imgHeight = 28,
  name,
  multiple,
  showName = true,
  tooltip = false,
  marginRight = 0,
}) => {
  // console.log(name,"namess",multiple);
  // const serviceName = multiple ? name : name?.replace('gcp', '');
  const getImgName = serviceName => {
    if (!serviceName) return null;
    const formattedName = serviceName.toLowerCase().replace(/\s+/g, '').replace(/-/g, '');

    // console.log(formattedName,"formattedName");
    return IMAGE_MAP[formattedName] || null;
  };

  const getImgBgColor = serviceName => {
    if (!serviceName) return null;
    const formattedName = serviceName.toLowerCase().replace(/\s+/g, '').replace(/-/g, '');

    // console.log(formattedName,"formattedName");
    return IMAGE_BG_COLOR_MAP[formattedName] || null;
  };

  // useEffect(() => {
  //   const getImgName = serviceName => {
  //     if (!serviceName) return null;
  //     const formattedName = serviceName.toLowerCase().replace(/\s+/g, '').replace(/-/g, '');
  //     return formattedName || null;
  //   };
  //   console.log('iconsNames', getImgName(name));
  // }, []);

  if (multiple) {
    return (
      <>
        {/* {name[0]?.toLowerCase().split(' ')?.slice(1).join('') in IMAGE_MAP && (
          <Tooltip title={tooltip ? name : ''}>
            <img
              src={IMAGE_MAP[name[0]?.toLowerCase().split(' ')?.slice(1).join('')]}
              height={height}
              width={width}
              style={{ objectFit: 'contain' }}
              alt={name}
            />
          </Tooltip>
        )} */}
        {name[0] && getImgName(name[0]) && (
          <Tooltip title={tooltip ? name[0] : ''} isIcon={true}>
            {getImgBgColor(name[0]) ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '30px',
                  backgroundColor: getImgBgColor(name[0]),
                  width: width,
                  height: height,
                  marginRight: marginRight ? marginRight : 0,
                }}>
                <img
                  src={getImgName(name[0])}
                  height={imgHeight}
                  width={imgWidth}
                  style={{ objectFit: 'contain' }}
                  alt={name[0]}
                />
              </div>
            ) : (
              <img
                src={getImgName(name[0])}
                height={height}
                width={width}
                style={{ objectFit: 'contain' }}
                alt={name}
              />
            )}
          </Tooltip>
        )}

        {showName && (
          <Tooltip title={name[0]}>
            <span
              className="SingleLine"
              style={{
                margin: '0 5px',
              }}>
              {name[0]}
            </span>
          </Tooltip>
        )}
        {name.length > 1 && (
          <CountTooltip
            // showFirst={false}
            labels={name}
            visibleLabelStyle={{ width: '140px !important' }}
            borderless
            showFirst={false}
          />
        )}
      </>
    );
  } else {
    // const imgName = IMAGE_MAP[name?.toLowerCase()] || null;
    const imgName = getImgName(name);
    const imgBgColor = getImgBgColor(name);
    return (
      <>
        {imgName ? (
          tooltip ? (
            <Tooltip title={tooltip ? name : ''} isIcon={true}>
              {imgBgColor ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '30px',
                    backgroundColor: imgBgColor,
                    width: width,
                    height: height,
                    marginRight: marginRight ? marginRight : 0,
                  }}>
                  <img src={imgName} height={imgHeight} width={imgWidth} style={{ objectFit: 'contain' }} alt={name} />
                </div>
              ) : (
                <img src={imgName} height={height} width={width} style={{ objectFit: 'contain' }} alt={name} />
              )}
            </Tooltip>
          ) : imgBgColor ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '30px',
                backgroundColor: imgBgColor,
                width: width,
                height: height,
                marginRight: marginRight ? marginRight : 0,
              }}>
              <img src={imgName} height={imgHeight} width={imgWidth} style={{ objectFit: 'contain' }} alt={name} />
            </div>
          ) : (
            <img src={imgName} height={height} width={width} style={{ objectFit: 'contain' }} alt={name} />
          )
        ) : null}
      </>
    );
  }
};

export default memo(AWSServiceIcons);
