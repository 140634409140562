import React, { useState, useEffect, useRef, useCallback } from 'react';
import './VerticalNavbar.scss';
import { Box } from '@mui/material';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
// import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const VerticalNavbarItem = ({
  // key,
  route,
  child,
  innerCloseSubMenu,
  triggerChange,
  currentOpen,
  setCurrentOpen,
  expanded,
  setExpanded,
}) => {
  const location = useLocation();
  const [expand, setExpand] = useState(false);
  const [isHoverexpand, setIsHoverExpand] = useState(false);
  const currentOpenSublinks = useRef(null);
  const [selected, setSelected] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    const last = location.pathname.substring(location.pathname.indexOf('/'));

    // check if a child is selected and put to select
    if (route.children) {
      const childSelected = route.children.filter(route => {
        if (last.startsWith(route.to)) {
          setSelected(true);
          return true;
        }
        return false;
      });

      if (_.isEmpty(childSelected)) {
        setSelected(false);
      }
    } else {
      if (last.startsWith(route.to)) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [location, route]);

  useEffect(() => {
    if (route.children) {
      if (currentOpen && route.name !== currentOpen) {
        setExpand(false);
        setIsHoverExpand(true);
      }
    }
  }, [currentOpen, route]);

  useEffect(() => {
    if (!expanded) {
      setExpand(false);
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (currentOpenSublinks.current && !currentOpenSublinks.current.contains(e.target)) {
        setExpand(false);
        setIsHoverExpand(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleHoverChangeRoute = currentOpen => {
    if (route.children) {
      if (isHoverexpand && expanded) {
        if (route.name === currentOpen) {
          setExpand(true);
        }
      }
    }
  };

  const handleDropdownClick = index => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const closeSubMenu = useCallback(() => {
    setIsHoverExpand(false);
    setExpand(false);
  }, []);

  if (route.children) {
    return (
      <div
        onMouseEnter={() => {
          if (expanded) {
            setCurrentOpen(route.name);
            handleHoverChangeRoute(route.name);
            setActiveDropdown(route.name);
            setIsHoverExpand(true);
          }
        }}
        onMouseLeave={() => {
          if (expanded) {
            setIsHoverExpand(false);
            setActiveDropdown(null);
          }
        }}
        // onMouseLeave={() => setExpand(isHoverexpand && false)}
        // className='VerticalNavbar__MainWrapper'
      >
        {/* <div 
            className="VerticalNavbar__RouteWrapper"> */}
        {!expanded ? (
          // <Tooltip title={route.name} placement={'right'} color={'#1A202C'} fontcolor={'#FFFFFF'}>
          <Box
            className={`VerticalNavbar__Item ${!triggerChange ? 'not-expanded' : 'expanded'} ${
              selected || (currentOpen === route.name && isHoverexpand && activeDropdown === route.name)
                ? 'selected'
                : ''
            } ${isHoverexpand ? 'hover-expanded' : ''}`}
            // onMouseEnter={() => {
            //   if (isHoverexpand) {
            //     setCurrentOpen(route.name);
            //     handleHoverChangeRoute(route.name);
            //   }
            // }}
            onClick={e => {
              // if (!(currentOpen === route.name) && !isHoverexpand) {
              //   setExpand(prev => !prev);
              //   setExpanded(true);
              //   setIsHoverExpand(true);
              //   setCurrentOpen(route.name);
              // }
              if (route.children) {
                e.preventDefault();
                handleDropdownClick(route.name);
                setExpand(prev => !prev);
                setExpanded(true);
                setIsHoverExpand(true);
                setCurrentOpen(route.name);
                setActiveDropdown(route.name);
              }
            }}>
            {/* {console.log(route, 'fvdfgxdf')} */}
            <div className={'VerticalNavbar__IconAnimationContainer'}>
              {_.isString(route.icon) ? (
                <Tooltip
                  placement={'right'}
                  color={'#1A202C'}
                  fontcolor={'#FFFFFF'}
                  title={route.name !== 'CDR' ? route.name : 'Cloud Detection &, Response'}
                  isIcon={true}>
                  <img src={selected ? route.icon_selected : route.icon} />
                </Tooltip>
              ) : (
                <Tooltip
                  placement={'right'}
                  color={'#1A202C'}
                  fontcolor={'#FFFFFF'}
                  title={
                    route?.children?.[0]?.to?.includes('data')
                      ? 'Data Security'
                      : route?.children?.[0]?.to?.includes('kubernetes')
                      ? 'Kubernetes Live'
                      : 'Explorer'
                  }
                  isIcon={true}>
                  {''}
                  {selected ? route.icon_selected : route.icon}
                </Tooltip>
              )}
              {/* {triggerChange && ( */}
              <>
                <span
                  className={`VerticalNavbar__Content--label ${selected ? 'selected' : ''} ${
                    triggerChange ? 'show' : 'hide'
                  }`}>
                  {route.name}
                </span>
                <div className={`${triggerChange ? 'show' : 'hide'}`} style={{ flexGrow: '1' }} />
              </>
              {/* )} */}
            </div>
            {/* { expanded &&  */}
            {selected ? (
              <svg
                className={`${triggerChange ? 'show' : 'hide'} `}
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_2)">
                  <path
                    d="M2.31239 9.61756L5.66657 5.98302C6.17163 5.43574 6.16446 4.55839 5.65051 4.01944L2.23736 0.440223C1.40713 -0.430379 0.00130252 0.207815 0.0114355 1.44724L0.0704114 8.66099C0.0805443 9.90042 1.49651 10.5016 2.31239 9.61756Z"
                    fill="url(#paint0_linear_1_2)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_1_2"
                    x1="3.08166"
                    y1="10.0242"
                    x2="2.9999"
                    y2="0.0245234"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00B2FF" />
                    <stop offset="1" stop-color="#00DBDE" />
                  </linearGradient>
                  <clipPath id="clip0_1_2">
                    <rect
                      width="10"
                      height="6"
                      fill="white"
                      transform="translate(0.0817566 10.0487) rotate(-90.4684)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                className={`${triggerChange ? 'show' : 'hide'} `}
                style={{ transform: expand ? 'rotate(270deg)' : 'rotate(270deg)', transition: 'all 500ms' }}>
                <path
                  d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0L1.38776 0C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                  fill="#CBD5E0"
                />
              </svg>
            )}
          </Box>
        ) : (
          // </Tooltip>
          <>
            {route.name === 'CDR' ? (
              <Tooltip
                title={'Cloud Detection & Response'}
                placement={'right'}
                color={'#1A202C'}
                fontcolor={'#FFFFFF'}
                isIcon={true}>
                <Box
                  className={`VerticalNavbar__Item ExpandNavbarItem ${!triggerChange ? 'not-expanded' : 'expanded'} ${
                    selected || (currentOpen === route.name && isHoverexpand && activeDropdown === route.name)
                      ? 'selected'
                      : ''
                  } ${isHoverexpand ? 'hover-expanded' : ''}`}
                  // onMouseEnter={() => {
                  //   if (isHoverexpand) {
                  //     setCurrentOpen(route.name);
                  //     handleHoverChangeRoute(route.name);
                  //   }
                  // }}
                  onClick={e => {
                    // if (!(currentOpen === route.name) && !isHoverexpand) {
                    //   setExpand(prev => !prev);
                    //   setExpanded(true);
                    //   setIsHoverExpand(true);
                    //   setCurrentOpen(route.name);
                    // }
                    if (route.children) {
                      e.preventDefault();
                      handleDropdownClick(route.name);
                      setExpand(prev => !prev);
                      setExpanded(true);
                      setIsHoverExpand(true);
                      setCurrentOpen(route.name);
                      setActiveDropdown(route.name);
                    }
                  }}>
                  <div className={`VerticalNavbar__AnimationContainer ${!triggerChange ? 'justify-center' : ''}`}>
                    <div className={`VerticalNavbar__IconContainer ${!triggerChange ? 'icon-adjust' : ''}`}>
                      {_.isString(route.icon) ? (
                        <img src={selected ? route.icon_selected : route.icon} />
                      ) : selected ? (
                        route.icon_selected
                      ) : (
                        route.icon
                      )}
                    </div>
                    {/* {triggerChange && ( */}
                    <>
                      <span
                        className={`VerticalNavbar__Content--label ${selected ? 'selected' : ''} ${
                          triggerChange ? 'show' : 'hide'
                        }`}>
                        {route.name}
                      </span>
                      <div className={`${triggerChange ? 'show' : 'hide'}`} style={{ flexGrow: '1' }} />
                    </>
                    {/* )} */}
                  </div>
                  {selected ? (
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1_2)">
                        <path
                          d="M2.31239 9.61756L5.66657 5.98302C6.17163 5.43574 6.16446 4.55839 5.65051 4.01944L2.23736 0.440223C1.40713 -0.430379 0.00130252 0.207815 0.0114355 1.44724L0.0704114 8.66099C0.0805443 9.90042 1.49651 10.5016 2.31239 9.61756Z"
                          fill="url(#paint0_linear_1_2)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_1_2"
                          x1="3.08166"
                          y1="10.0242"
                          x2="2.9999"
                          y2="0.0245234"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#00B2FF" />
                          <stop offset="1" stop-color="#00DBDE" />
                        </linearGradient>
                        <clipPath id="clip0_1_2">
                          <rect
                            width="10"
                            height="6"
                            fill="white"
                            transform="translate(0.0817566 10.0487) rotate(-90.4684)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      style={{ transform: expand ? 'rotate(270deg)' : 'rotate(270deg)', transition: 'all 500ms' }}>
                      <path
                        d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0L1.38776 0C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                        fill="#CBD5E0"
                      />
                    </svg>
                  )}
                </Box>
              </Tooltip>
            ) : (
              <Box
                className={`VerticalNavbar__Item ExpandNavbarItem ${!triggerChange ? 'not-expanded' : 'expanded'} ${
                  selected || (currentOpen === route.name && isHoverexpand && activeDropdown === route.name)
                    ? 'selected'
                    : ''
                } ${isHoverexpand ? 'hover-expanded' : ''}`}
                // onMouseEnter={() => {
                //   if (isHoverexpand) {
                //     setCurrentOpen(route.name);
                //     handleHoverChangeRoute(route.name);
                //   }
                // }}
                onClick={e => {
                  // if (!(currentOpen === route.name) && !isHoverexpand) {
                  //   setExpand(prev => !prev);
                  //   setExpanded(true);
                  //   setIsHoverExpand(true);
                  //   setCurrentOpen(route.name);
                  // }
                  if (route.children) {
                    e.preventDefault();
                    handleDropdownClick(route.name);
                    setExpand(prev => !prev);
                    setExpanded(true);
                    setIsHoverExpand(true);
                    setCurrentOpen(route.name);
                    setActiveDropdown(route.name);
                  }
                }}>
                <div className={`VerticalNavbar__AnimationContainer ${!triggerChange ? 'justify-center' : ''}`}>
                  <div className={`VerticalNavbar__IconContainer ${!triggerChange ? 'icon-adjust' : ''}`}>
                    {_.isString(route.icon) ? (
                      <img src={selected ? route.icon_selected : route.icon} />
                    ) : selected ? (
                      route.icon_selected
                    ) : (
                      route.icon
                    )}
                  </div>
                  {/* {triggerChange && ( */}
                  <>
                    <span
                      className={`VerticalNavbar__Content--label ${selected ? 'selected' : ''} ${
                        triggerChange ? 'show' : 'hide'
                      }`}>
                      {route.name}
                    </span>
                    <div className={`${triggerChange ? 'show' : 'hide'}`} style={{ flexGrow: '1' }} />
                  </>
                  {/* )} */}
                </div>
                {selected ? (
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_2)">
                      <path
                        d="M2.31239 9.61756L5.66657 5.98302C6.17163 5.43574 6.16446 4.55839 5.65051 4.01944L2.23736 0.440223C1.40713 -0.430379 0.00130252 0.207815 0.0114355 1.44724L0.0704114 8.66099C0.0805443 9.90042 1.49651 10.5016 2.31239 9.61756Z"
                        fill="url(#paint0_linear_1_2)"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_2"
                        x1="3.08166"
                        y1="10.0242"
                        x2="2.9999"
                        y2="0.0245234"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00B2FF" />
                        <stop offset="1" stop-color="#00DBDE" />
                      </linearGradient>
                      <clipPath id="clip0_1_2">
                        <rect
                          width="10"
                          height="6"
                          fill="white"
                          transform="translate(0.0817566 10.0487) rotate(-90.4684)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    style={{ transform: expand ? 'rotate(270deg)' : 'rotate(270deg)', transition: 'all 500ms' }}>
                    <path
                      d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0L1.38776 0C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                      fill="#CBD5E0"
                    />
                  </svg>
                )}
              </Box>
            )}
          </>
        )}
        {/* </div> */}
        {isHoverexpand && (
          <div
            className="VerticalNavbar__ItemChildren"
            ref={currentOpenSublinks}
            style={{ display: expand ? `flex` : 'none' }}>
            <div style={{ padding: '13px 1px 13px 13px', height: '100%', background: '#232b38' }}></div>
            <div style={{ paddingTop: 16, paddingRight: 16, paddingLeft: 16, width: '210px' }}>
              {route.children.map((route, i) => (
                <VerticalNavbarItem
                  key={i}
                  route={route}
                  child
                  triggerChange={triggerChange}
                  innerCloseSubMenu={closeSubMenu}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  if (!expanded) {
    return (
      // <div className='VerticalNavbar__MainWrapper'>
      //   <div className="VerticalNavbar__RouteWrapper">
      // {/* <Tooltip title={route.name} placement={'right'} color={'#1A202C'} fontcolor={'#FFFFFF'}> */}
      <Link
        className={`VerticalNavbar__Item ${!triggerChange ? 'not-expanded' : 'expanded'} ${
          selected && !child ? 'selected' : selected && child ? 'child-selected' : ''
        }`}
        data-link-id={route.to}
        to={route.to}
        onClick={() => {
          // setExpand(false);
          // setIsHoverExpand(false);
          if (child && innerCloseSubMenu) {
            innerCloseSubMenu();
          }
        }}
        // style={{ paddingLeft: child ? '40px' : '' }}
      >
        <div className={`VerticalNavbar__IconAnimationContainer`}>
          <div className={`VerticalNavbar__IconContainer`}>
            {_.isString(route.icon) ? (
              _.isString(route.to) &&
              (route?.to?.includes('/dashboard') ||
                // route?.to?.includes('/risk') ||
                route?.to?.includes('/data-security') ||
                route?.to?.includes('/explorer')) ? (
                // || route?.to?.includes('/notification')
                <Tooltip
                  placement={'right'}
                  color={'#1A202C'}
                  fontcolor={'#FFFFFF'}
                  title={route.name !== 'CDR' ? route.name : 'Cloud Detection &, Response'}
                  arrow
                  isIcon={true}>
                  <img src={selected ? route.icon_selected : route.icon} />
                </Tooltip>
              ) : (
                // <Tooltip
                //   placement={'right'}
                //   color={'#1A202C'}
                //   fontcolor={'#FFFFFF'}
                //   title={route.name !== 'CDR' ? route.name : 'Cloud Detection &, Response'}
                //   arrow
                //   isIcon={true}>
                <img src={selected ? route.icon_selected : route.icon} />
                // </Tooltip>
              )
            ) : // <Tooltip
            //   placement={'right'}
            //   color={'#1A202C'}
            //   fontcolor={'#FFFFFF'}
            //   title={
            //     route?.to?.includes('data')
            //       ? 'Data Security'
            //       : route?.children?.[0]?.to?.includes('kubernetes')
            //       ? 'Kubernetes Live'
            //       : 'Explorer'
            //   }
            //   arrow
            //   isIcon={true}>
            //   {''}
            selected ? (
              route.icon_selected
            ) : (
              route.icon
            )
            // </Tooltip>
            }
          </div>
          {/* {triggerChange && ( */}
          <span
            className={`VerticalNavbar__Content--label ${selected ? 'selected' : ''} ${
              triggerChange ? 'show' : 'hide'
            }`}>
            {route.name === 'CDR' ? (
              <Tooltip
                title={'Cloud Detection &, Response'}
                placement={'right'}
                color={'#1A202C'}
                fontcolor={'#FFFFFF'}
                arrow
                isIcon={true}>
                {route.name}
              </Tooltip>
            ) : (
              route.name
            )}
          </span>
          {/* )}  */}
        </div>
      </Link>
      // {/* </Tooltip> */}
      //   </div>
      // </div>
    );
  }

  return (
    // <div className='VerticalNavbar__MainWrapper'>
    //   <div className="VerticalNavbar__RouteWrapper">
    <Link
      className={`VerticalNavbar__Item ${!triggerChange ? 'not-expanded' : 'expanded'} ${
        selected && !child ? 'selected' : selected && child ? 'child-selected' : ''
      }`}
      data-link-id={route.to}
      to={route.to}
      onClick={() => {
        // setExpand(false);
        // setIsHoverExpand(false)
        if (child && innerCloseSubMenu) {
          innerCloseSubMenu();
        }
      }}
      // style={{ paddingLeft: child ? '50px' : '' }}
    >
      <div className={`VerticalNavbar__AnimationContainer`}>
        <div className={`VerticalNavbar__IconContainer`}>
          {_.isString(route.icon) ? (
            <img src={selected ? route.icon_selected : route.icon} />
          ) : selected ? (
            route.icon_selected
          ) : (
            route.icon
          )}
        </div>
        {/* {triggerChange && ( */}
        <span
          className={`VerticalNavbar__Content--label ${selected ? 'selected' : ''} ${triggerChange ? 'show' : 'hide'}`}>
          {route.name}
        </span>
        {/* )} */}
      </div>
    </Link>
    //   {/* </div>
    // </div> */}
  );
};

export default VerticalNavbarItem;
