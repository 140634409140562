import { useCallback, useMemo, useState } from 'react';
import './sideoverlay.scss';

// import workload12 from '@assets/svg/icons/workload2.svg';
import { Box, Chip, Tooltip } from '@mui/material';

import CircularCloseButton from '@root/components/v2/ui-elements/CircularCloseButton';
import useEnhancedQuery from '@root/query/useEnhancedQuery';
import agent from '@root/agent';
import { columns } from './vulnerabilitiesColumns';
import MuiTable from '@root/components/table/table';
import TableSkeleton from '@root/components/table/skeleton';
import YesNo from '@root/components/YesNo';
import Text from '@root/components/v2/ui-elements/Text';
import { imageColumns } from './imagesColumns';
import { workloadColumns } from './workloadColumns';
import HealthScoreBreakdown from '@root/pages/inventory1.3/Components/HealthScoreBreakdown';
import ContentBox from '@root/components/v2/content/ContentBox';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import { layersColumnsImages, sbomColumnsImages, vulnerabilitiesImagesColumns } from './ImagesSideoverlayColumns';
import { componentsColumnsWorkload, vulnerabilitiesWorkloadColumns } from './workloadSideoverlayColumn';
import { CvesWorkloadColumns } from './cvesSideoverlayColumns';
import _ from 'lodash';
import Loader from '@root/components/common/Loader';
import { capitalizeFirstLetter } from '@root/utilities/string';
// import StackedSideOverlay from './StackedSideOverlay';
const runtimeColumn = [
  {
    field: 'image_tag',
    headerName: 'Image Tag',
    width: 250,
  },
  {
    field: 'path',
    headerName: 'Path',
    width: 250,
  },
  {
    field: 'in_use',
    headerName: 'In use',
    width: 250,
    renderCell: params => {
      return <Text font="medium">{params.row.in_use ? <YesNo yes /> : <YesNo no />}</Text>;
    },
  },
];

const SideOverlay = props => {
  const { selectedRow, onClose, type, isStacked = false } = props;
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedVulnerabilityRow, setSelectedVulnerabilityRow] = useState(props.selectedRow ?? {});

  const tab = useMemo(() => {
    if (currentSubTab === 0) return 'details';
    else if (currentSubTab === 1) return 'vulnerabilities';
    else if (currentSubTab === 2) return 'images';
    else if (currentSubTab === 3) return 'workloads';
    else if (currentSubTab === 4) return 'sbom';
    else if (currentSubTab === 5) return 'components';
    else if (currentSubTab === 6) return 'layers';
    else if (currentSubTab === 7) return 'runtime';
  }, [currentSubTab]);

  const { data: sbomDetails, isFetching: isFetchingSbomDetails } = useEnhancedQuery(
    ['get_sbom_table_details', selectedRow, tab, type],
    () =>
      agent.Kubernetes.get_sbom_table_details({
        // scope: selectedEnvironmentTags,
        environment: selectedRow?.account_id,
        component_id: selectedRow?.name,
        cluster: selectedRow?.cluster,
        tab: tab,
      }),
    {
      enabled: type === 'sbom',
    },
  );
  const { data: imageDetails, isFetching: isFetchingImageDetails } = useEnhancedQuery(
    ['get_images_table_details', selectedRow, type],
    () =>
      agent.Kubernetes.get_images_table_details({
        // scope: selectedEnvironmentTags,
        environment: selectedRow?.account_id,

        cluster: selectedRow?.cluster,
        image_id: selectedRow?.image_id,
        image_name: selectedRow?.name,
      }),
    {
      enabled: type === 'images',
    },
  );
  console.log(selectedRow, selectedVulnerabilityRow, ',dmngk');
  const { data: workloadsDetails, isFetching: isFetchingWorkloadsDetails } = useEnhancedQuery(
    ['get_workloads_table_details', selectedRow, type],
    () =>
      agent.Kubernetes.get_workloads_table_details({
        // scope: selectedEnvironmentTags,
        environment: selectedRow?.account_id,
        cluster: selectedRow?.cluster,
        namespace: selectedRow?.namespace,
        kind: selectedRow?.kind,
        name: selectedRow?.name,
      }),
    {
      enabled: type === 'workloads',
    },
  );
  const { data: cvesDetails, isFetching: isFetchingCvesDetails } = useEnhancedQuery(
    ['get_cves_table_details', selectedRow, type],
    () =>
      agent.Kubernetes.get_cves_table_details({
        // scope: selectedEnvironmentTags,
        environment: selectedRow?.account_id,
        cluster: selectedRow?.cluster,
        cve_id: selectedRow?.cve_id,
      }),
    {
      enabled: type === 'cves',
    },
  );

  const _tableData = useMemo(() => {
    if (isFetchingSbomDetails && isFetchingImageDetails && isFetchingWorkloadsDetails && isFetchingCvesDetails)
      return [];
    const _data = sbomDetails?.data || imageDetails?.data || workloadsDetails?.data || cvesDetails?.data;
    if (tab === 'vulnerabilities') return _data?.vulnerabilities?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'images') return (_data?.images || _data?.affected_images)?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'workloads')
      return (_data?.workloads || _data?.affected_workloads || [])?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'sbom') return _data?.artifacts?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'components') return _data?.components?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'layers') return _data?.layers?.map((v, i) => ({ ...v, id: i }));
    if (tab === 'runtime')
      return [
        { id: 1, image_tag: selectedVulnerabilityRow?.images[0], path: '/usr/local/bin/mode', in_use: true },
        { id: 2, image_tag: selectedVulnerabilityRow?.images[0], path: '/usr/local/bin/mode', in_use: true },
      ];
  }, [
    cvesDetails?.data,
    imageDetails?.data,
    isFetchingCvesDetails,
    isFetchingImageDetails,
    isFetchingSbomDetails,
    isFetchingWorkloadsDetails,
    sbomDetails?.data,
    selectedVulnerabilityRow?.images,
    tab,
    workloadsDetails?.data,
  ]);

  const handleRowClick = useCallback(
    r => {
      if (isStacked || type !== 'workloads' || tab !== 'vulnerabilities') {
        return;
      }
      setSelectedVulnerabilityRow(r.row);
      setShowDetailsPanel(true);
    },
    [isStacked, tab, type],
  );

  const handleCloseDetailsPanel = useCallback(() => {
    setShowDetailsPanel(false);
    setSelectedVulnerabilityRow(null);
  }, []);

  const _columns = useMemo(() => {
    if (type === 'cves') {
      if (tab === 'workload') return CvesWorkloadColumns;
    }
    if (type === 'workloads') {
      if (tab === 'vulnerabilities') return vulnerabilitiesWorkloadColumns();
    }
    if (type === 'images') {
      if (tab === 'vulnerabilities') return vulnerabilitiesImagesColumns();
    }
    if (tab === 'vulnerabilities') return columns();
    else if (tab === 'images') return imageColumns;
    else if (tab === 'workloads') return workloadColumns;
    else if (tab === 'sbom') return sbomColumnsImages;
    else if (tab === 'components') return componentsColumnsWorkload;
    else if (tab === 'layers') return layersColumnsImages;
    else if (tab === 'runtime') return runtimeColumn;
  }, [tab, type]);

  const _severityData = useMemo(() => {
    if (isFetchingSbomDetails && isFetchingImageDetails && isFetchingWorkloadsDetails) return [];
    const _data = sbomDetails?.data || imageDetails?.data || workloadsDetails?.data;
    const breakupData = _data?.vulnerability_counts;
    return {
      data: {
        total_risk: breakupData?.total,
        risk_breakup: [
          {
            name: 'Critical',
            value: breakupData?.critical,
          },
          {
            name: 'High',
            value: breakupData?.high,
          },
          {
            name: 'Medium',
            value: breakupData?.medium,
          },
          {
            name: 'Low',
            value: breakupData?.low,
          },
          {
            name: 'Unknown',
            value: breakupData?.unknown,
          },
        ],
      },
    };
  }, [
    imageDetails?.data,
    isFetchingImageDetails,
    isFetchingSbomDetails,
    isFetchingWorkloadsDetails,
    sbomDetails?.data,
    workloadsDetails?.data,
  ]);
  const detailsKey = useMemo(() => {
    if (isFetchingSbomDetails && isFetchingImageDetails && isFetchingWorkloadsDetails) return [];
    if (type === 'sbom' || type === 'images' || type === 'workloads' || type === 'cves') {
      const _data = sbomDetails?.data || imageDetails?.data || workloadsDetails?.data || cvesDetails?.data || {};
      console.log(_data, workloadsDetails?.data, 'nkjsnfkj');
      return Object.keys(_data)?.filter(
        k =>
          k !== 'account_id' &&
          k !== 'vulnerability_counts' &&
          k !== 'workloads' &&
          k !== 'vulnerabilities' &&
          k !== 'layers' &&
          k !== 'components' &&
          k !== 'images' &&
          k !== 'affected_workloads' &&
          k !== 'affected_artifacts' &&
          k !== 'affected_images' &&
          k !== 'artifacts' &&
          k !== 'urls',
      );
    }
  }, [
    cvesDetails?.data,
    imageDetails?.data,
    isFetchingImageDetails,
    isFetchingSbomDetails,
    isFetchingWorkloadsDetails,
    sbomDetails?.data,
    type,
    workloadsDetails?.data,
  ]);

  const isStatusKey = useCallback(
    (...args) => {
      if (isFetchingImageDetails || isFetchingSbomDetails || isFetchingWorkloadsDetails || isFetchingCvesDetails)
        return false;
      return args?.includes('active');
    },
    [isFetchingCvesDetails, isFetchingImageDetails, isFetchingSbomDetails, isFetchingWorkloadsDetails],
  );

  return (
    <div className="k8Vulnebarlaity">
      <div className={`Resources__Backdrop2`} />
      <div className={`Resources__DataSidebar2 ${isStacked ? 'Resources__DataStackedSidebar2' : ''}`}>
        <div className={`Resources__SidebarHeader`}>
          <div style={{ display: 'flex', padding: '15px', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                {/* <img src={workload12} /> */}
                <span
                  className="text font-16 weight-600"
                  style={{
                    marginLeft: '10px',
                    color: 'var(--secondary-500)',
                    lineHeight: 1,
                  }}>
                  {isStacked ? selectedRow.cve_id : selectedRow?.name}
                </span>
                {/* <Box flex={1} width={'1100px'} /> */}
              </div>
              {/* <span
                style={{
                  marginLeft: '40px',
                  marginTop: '-12px',
                  // color: 'var(--secondary-400)',
                  fontSize: '12px',
                  fontWeight: 500,
                }}>
                Common Vulnerability and Exposures
              </span> */}
            </div>
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <Tooltip title="Viper AI is your AI security analyst"> </Tooltip>

              <CircularCloseButton onClick={() => onClose()} />
            </Box>
          </div>

          <div
            className="text font-16 weight-600"
            style={{
              color: 'var(--secondary-600)',
              paddingLeft: '20px',
            }}>
            {selectedRow?.service}
          </div>
        </div>

        <div className="Resources__SidebarContent CustomScrollbar">
          <div
            className="Resources__SidebarSubTabs"
            style={{ display: 'flex', borderBottom: '1px solid #CBD5E0', marginLeft: '8px' }}>
            <Box onClick={() => setCurrentSubTab(0)} className={currentSubTab === 0 ? 'Resources__SelectedSubTab' : ''}>
              Details
            </Box>
            {type !== 'cves' && (
              <Box
                onClick={() => setCurrentSubTab(1)}
                className={currentSubTab === 1 ? 'Resources__SelectedSubTab' : ''}>
                Vulnerabilities
              </Box>
            )}

            {type !== 'images' && (
              <Box
                onClick={() => setCurrentSubTab(2)}
                className={currentSubTab === 2 ? 'Resources__SelectedSubTab' : ''}>
                Images
              </Box>
            )}

            {type !== 'workloads' && (
              <Box
                onClick={() => setCurrentSubTab(3)}
                className={currentSubTab === 3 ? 'Resources__SelectedSubTab' : ''}>
                Workloads
              </Box>
            )}

            {type !== 'workloads' && type !== 'sbom' && type !== 'cves' && (
              <Box
                onClick={() => setCurrentSubTab(4)}
                className={currentSubTab === 4 ? 'Resources__SelectedSubTab' : ''}>
                SBOM
              </Box>
            )}
            {type === 'workloads' && (
              <Box
                onClick={() => setCurrentSubTab(5)}
                className={currentSubTab === 5 ? 'Resources__SelectedSubTab' : ''}>
                Components
              </Box>
            )}
            {type === 'images' && (
              <Box
                onClick={() => setCurrentSubTab(6)}
                className={currentSubTab === 6 ? 'Resources__SelectedSubTab' : ''}>
                Layers
              </Box>
            )}
            {type === 'cves' && isStacked && (
              <Box
                onClick={() => setCurrentSubTab(7)}
                className={currentSubTab === 7 ? 'Resources__SelectedSubTab' : ''}>
                Runtime Analysis
              </Box>
            )}
          </div>
          {!isStacked &&
          (isFetchingImageDetails && isFetchingSbomDetails && isFetchingWorkloadsDetails && isFetchingCvesDetails) ? (
            <Loader />
          ) : (
            <>
              {' '}
              {currentSubTab === 0 && (
                <Box
                  display={'grid'}
                  padding={'15px'}
                  gap={'15px'}
                  gridTemplateColumns={type === 'cves' ? '100%' : '70% 29%'}>
                  <ContentBox style>
                    {detailsKey?.map(k => {
                      const formattedKey = k
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');

                      return (
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '8px',
                            fontSize: '13px',
                            alignItems: 'center',
                          }}>
                          <span
                            style={{
                              fontWeight: '600',
                              color: 'rgb(85, 85, 85)',
                              marginRight: '8px',
                              minWidth: '150px',
                              fontSize: '14px',
                            }}>
                            {formattedKey}:
                          </span>
                          <span style={{ flex: 1, color: '#484d56' }}>
                            {k === 'status' ? (
                              <Chip
                                label={
                                  sbomDetails?.data[k] === 'active' ||
                                  imageDetails?.data[k] === 'active' ||
                                  workloadsDetails?.data[k] === 'active' ||
                                  cvesDetails?.data[k] === 'active'
                                    ? 'Active'
                                    : sbomDetails?.data[k] ||
                                      imageDetails?.data[k] ||
                                      workloadsDetails?.data[k] ||
                                      cvesDetails?.data[k]
                                }
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '400',
                                  fontFamily: 'Manrope',

                                  height: '20px',
                                  color: isStatusKey(
                                    sbomDetails?.data[k],
                                    imageDetails?.data[k],
                                    workloadsDetails?.data[k],
                                    cvesDetails?.data[k],
                                  )
                                    ? '#0d5230'
                                    : '#F62121',
                                  backgroundColor: isStatusKey(
                                    sbomDetails?.data[k],
                                    imageDetails?.data[k],
                                    workloadsDetails?.data[k],
                                    cvesDetails?.data[k],
                                  )
                                    ? '#acf3d9'
                                    : '#FDD3D3',
                                  // border: '1px solid #F62121',
                                  borderRadius: '8px',
                                }}
                              />
                            ) : k === 'last_updated' ? (
                              DateFormatPipe(
                                sbomDetails?.data[k] ||
                                  imageDetails?.data[k] ||
                                  workloadsDetails?.data[k] ||
                                  cvesDetails?.data[k],
                              )
                            ) : k === 'last_modified' ? (
                              DateFormatPipe(cvesDetails?.data[k])
                            ) : k === 'discovered_at' ? (
                              DateFormatPipe(cvesDetails?.data[k])
                            ) : _.isNumber(
                                sbomDetails?.data[k] ||
                                  imageDetails?.data[k] ||
                                  workloadsDetails?.data[k] ||
                                  cvesDetails?.data[k],
                              ) ||
                              _.isBoolean(
                                sbomDetails?.data[k] ||
                                  imageDetails?.data[k] ||
                                  workloadsDetails?.data[k] ||
                                  cvesDetails?.data[k],
                              ) || 
                              !_.isString(
                                sbomDetails?.data[k] ||
                                  imageDetails?.data[k] ||
                                  workloadsDetails?.data[k] ||
                                  cvesDetails?.data[k],
                              ) ? (
                              capitalizeFirstLetter(
                                JSON.stringify(
                                  sbomDetails?.data[k] ||
                                    imageDetails?.data[k] ||
                                    workloadsDetails?.data[k] ||
                                    cvesDetails?.data[k],
                                ),
                              )
                            ) : (
                              sbomDetails?.data[k] ||
                              imageDetails?.data[k] ||
                              workloadsDetails?.data[k] ||
                              cvesDetails?.data[k] ||
                              '-'
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </ContentBox>
                  {type !== 'cves' && (
                    <HealthScoreBreakdown
                      title={'Vulnerabilities By Severity'}
                      health_score_breakdown={_severityData}
                      isFetchingHealthScoreBreakdown={
                        isFetchingSbomDetails && isFetchingImageDetails && isFetchingWorkloadsDetails
                      }
                      isKubernetes={true}
                      count_msg={'Vulnerabilities'}
                      infoMsg={''}
                    />
                  )}
                </Box>
              )}
              {currentSubTab !== 0 && (
                <Box padding={'15px'}>
                  {(isFetchingSbomDetails && isFetchingImageDetails && isFetchingWorkloadsDetails) || !_tableData ? (
                    <div className=" card" style={{ height: '85vh', width: '100%' }}>
                      <TableSkeleton />
                    </div>
                  ) : (
                    <MuiTable
                      columns={_columns}
                      rows={_tableData}
                      paginationMode="server"
                      sortingMode="server"
                      total_size={_tableData?.length}
                      //  handleSortModelChange={handleSortModelChange}
                      // LoaderState={isFetchingNextPage}
                      onRowClick={handleRowClick}
                      //  page={currentPage}
                      //  onPageChange={handlePageChange}
                      //  pageSize={pageSize}
                      //  onPageSizeChange={handlePageSizeChange}
                      rowHeight={49.9}
                      // isDynamicHeight={true}
                      tableHeight={'637px'}
                    />
                  )}
                  {showDetailsPanel && (
                    <>
                      {isStacked && (isFetchingImageDetails || isFetchingWorkloadsDetails || isFetchingCvesDetails) ? (
                        <Loader />
                      ) : (
                        <SideOverlay
                          onClose={handleCloseDetailsPanel}
                          selectedRow={selectedVulnerabilityRow}
                          type={'cves'}
                          isStacked={true}
                        />
                      )}
                    </>
                  )}
                </Box>  
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SideOverlay;
