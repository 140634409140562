import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import MuitoolTip from '@root/components/Tooltip/materialUiTooltip';
import './style.scss';
import { Box, Button, Menu, MenuItem, Skeleton } from '@mui/material';
import { useOCRPermissions } from '@root/hooks/permissions';
import { useTheme } from '@root/context/ThemeContext';

import filterIcon from '@assets/svg/filter.svg';
import whiteFilterIcon from '@assets/svg/filter_white.svg';
import searchIcon from '@assets/svg/search.svg';
import cancel_icon from '@assets/svg/cancel_icon.svg';
import AddIcon from '@mui/icons-material/Add';
import { Download } from 'lucide-react';
import FilterListIcon from '@mui/icons-material/FilterList';
// import _ from 'lodash';

// import { Settings } from '@mui/icons-material';

function HeaderBar({
  setOpenFilter,
  openFilter,
  style,
  searchBar,
  setSearchTerm,
  searchBarPlaceholder,
  showCreateNotificationButton,
  onCreateNotificationClick,
  title,
  showCreatePolicyButton,
  isCreatePolicyAllowed,
  leftsideContent,
  isFilterActive,
  showNewScanButton,
  cloudProvider,
  disableFilter,
  bulkRemediation,
  // runScan,
  onFilterButtonClick,
  settingButton,
  handleSettingsClick,
  handleExportData,
  exportDataText1,
  exportDataText2,
  onExportButtonClick,
  searchBarValue,
  disableExportMenu,
  disableExportButton,
  searchBarOnChange,
  onSearchBarSubmit,
  children,
  marginBottom,
  marginTop,
  disableCreateNotificaiton,
  height,
  exportButton,
  exportdata,
  isFilterSelected = false,
  buttonGroup = false,
  complianceInnerSearchBar = false,
  createnewenv,
  showingEnvironment,
  hideFilterButton,
  regionFilter,
  addResourceTag = true,
  disableResourceExportButton = true,
  handleAttackPathExport,
  searchWidth = '400px',
  isFilterDisabled = false,
  isFilterDataFetching = false,
  //envcount = 0,

  //triggerCollapse,
  //collapse,
}) {
  const [isFilterIconHover, setIsFilterIconHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const { runScanAllowed } = useOCRPermissions();
  const { isDarkMode } = useTheme();

  //const [environmentsArray, setEnvironmentsArray] = useState([]);
  const [, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFocusClick = () => {
    // Set focus on the input element
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(isInputFocused, 'isInputFocused');
  return (
    <div
      className="HeaderBar_Container__New"
      style={{
        ...style,
        ...(marginTop && { marginTop }),
        ...(marginBottom && { marginBottom }),
        ...(height && { height }),
      }}>
      {/* <MuitoolTip label="Filters"> */}
      {!!onFilterButtonClick && (
        <div
          className="HeaderBar__IconContainer"
          onClick={onFilterButtonClick}
          style={{
            background:
              isFilterIconHover || isFilterActive
                ? isDarkMode
                  ? '#4a5568'
                  : '#194BFB'
                : 'var(--headerbar-icon-container-bg)',
            pointerEvents: disableFilter ? 'none' : 'all',
          }}
          onMouseEnter={() => setIsFilterIconHover(true)}
          onMouseOut={() => setIsFilterIconHover(false)}>
          <img
            src={isFilterIconHover || isFilterActive ? whiteFilterIcon : filterIcon}
            style={{
              width: '24px',
              background:
                isFilterIconHover || isFilterActive
                  ? isDarkMode
                    ? '#4a5568'
                    : '#194BFB'
                  : 'var(--headerbar-icon-container-bg)',
            }}
            alt=""
            onMouseEnter={() => setIsFilterIconHover(true)}
          />
        </div>
      )}
      {/* </MuitoolTip> */}

      {title && <h2>{title}</h2>}

      {leftsideContent && leftsideContent}

      {searchBar && (
        <div
          className="HeaderBar__SearchContainer"
          onClick={handleFocusClick}
          style={{
            // flexGrow: 1,
            width: searchWidth,
            marginLeft: onFilterButtonClick ? 16 : 0,
            border: '0px solid #cbd5e0',
            padding: complianceInnerSearchBar ? '10px 10px' : '10px 0px',
          }}>
          {onSearchBarSubmit ? (
            <form onSubmit={onSearchBarSubmit}>
              {/* {!isInputFocused && searchBarValue.length === 0 && ( */}
              <img
                src={searchIcon}
                style={{
                  width: '16px',
                  marginLeft: '10px',
                  // position: 'relative',
                  // marginRight: '-23px',
                  // zIndex: 1000
                }}
                alt="Search Icon"
              />
              {/* )} */}

              <input
                // style={{
                //   border: '1px solid #CBD5E0',
                //   borderRadius: '5px',
                //   // marginLeft: searchBarValue.length > 0 ? '-11px' : '',
                // }}
                className="HeaderBar__SearchBar"
                placeholder={searchBarPlaceholder ? searchBarPlaceholder : 'Search'}
                value={searchBarValue}
                onChange={searchBarOnChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={inputRef}
              />
              {/* {searchBarValue.length > 0 ? ( */}
              <img
                src={cancel_icon}
                style={{
                  width: '16px',
                  // position: 'relative',
                  // marginLeft: '-23px',
                  // zIndex: 1000,
                  marginRight: '10px',
                  cursor: 'pointer',
                  opacity: searchBarValue.length > 0 ? 1 : 0,
                  pointerEvents: searchBarValue.length > 0 ? 'unset' : 'none',
                }}
                alt="Search Icon"
                onClick={() => setSearchTerm('')}
              />
              {/* // ) : (
              //   <div style={{ width: '16px' }} />
              // )} */}
            </form>
          ) : (
            <>
              {/* {!isInputFocused && searchBarValue.length === 0 && ( */}
              <img
                src={searchIcon}
                style={{
                  width: '16px',
                  marginLeft: '10px',
                  //  position: 'relative',
                  //  marginRight: '-23px',
                  //  zIndex: 1000
                }}
                alt="Search Icon"
              />
              {/* )} */}
              <input
                // style={{
                //   border: '1px solid #CBD5E0',
                //   borderRadius: '5px',
                //   // marginLeft: isInputFocused || searchBarValue.length > 0 ? '-11px' : '',
                //   // marginRight: searchBarValue.length > 0 ? '11px' : '',
                // }}
                className="HeaderBar__SearchBar"
                placeholder={searchBarPlaceholder ? searchBarPlaceholder : 'Search'}
                value={searchBarValue}
                onChange={searchBarOnChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={inputRef}
              />
              {/* {/* {searchBarValue.length > 0 ? ( */}
              <img
                src={cancel_icon}
                style={{
                  width: '16px',
                  // position: 'relative',
                  // marginLeft: '-23px',
                  // zIndex: 1000,

                  marginRight: '10px',
                  cursor: 'pointer',
                  opacity: searchBarValue?.length > 0 ? 1 : 0,
                  pointerEvents: searchBarValue?.length > 0 ? 'unset' : 'none',
                }}
                alt="Search Icon"
                onClick={() => setSearchTerm('')}
              />
              {/* // ) : (
              //   <div style={{ width: '16px' }} />
              // )} */}
            </>
          )}
        </div>
      )}

      <div style={{ flexGrow: 1 }} />

      {children}
      {setOpenFilter && (
        <>
          {isFilterDataFetching ? (
            <Skeleton variant="rectangular" height={'35px'} width={'65px'} sx={{ borderRadius: '8px' }} />
          ) : (
            <>
              {' '}
              <Button
                disabled={isFilterDisabled}
                className="filter-button-header"
                onClick={() => setOpenFilter(prev => !prev)}
                style={{
                  background: isDarkMode ? (openFilter ? '#4299e1' : '#2d3748') : openFilter ? '#e6efff' : '#fff',
                  opacity: isFilterDisabled ? 0.5 : 1,
                  color: isDarkMode
                    ? isFilterDisabled
                      ? '#4a5568'
                      : '#e2e8f0'
                    : isFilterDisabled
                    ? '#767980'
                    : 'rgb(24, 95, 236)',
                  // border: isDarkMode ? '1px solid #4a5568' : 'none',
                }}>
                <FilterListIcon
                  sx={{
                    marginRight: '5px',
                    height: '15px',
                    width: '15px',
                    color: isDarkMode ? '#e2e8f0' : 'inherit',
                  }}
                />
                Filter
              </Button>
            </>
          )}
        </>
      )}

      {showNewScanButton &&
        (!runScanAllowed ? (
          <MuitoolTip label={`No permission available. Please contact Organization Admin`}>
            <button disabled={!runScanAllowed} className="HeaderBar__CreateButton">
              <AddIcon sx={{ color: '#194bfb', marginBottom: '1px', height: '19px' }} />
              New Scan
            </button>
          </MuitoolTip>
        ) : (
          <Link to={runScanAllowed ? `/scan-history?new_scan=true&cloud=${cloudProvider.toUpperCase()}` : '#'}>
            <button disabled={!runScanAllowed} className="HeaderBar__CreateButton">
              <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} /> New Scan
            </button>
          </Link>
        ))}
      {showCreatePolicyButton &&
        (!isCreatePolicyAllowed ? (
          <MuitoolTip label={`No permission available. Please contact Organization Admin`}>
            <button disabled={!isCreatePolicyAllowed} className="HeaderBar__CreateButton">
              <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} />
              Create Policy
            </button>
          </MuitoolTip>
        ) : (
          <Link to="/remediation-policy/create">
            <button disabled={!isCreatePolicyAllowed} className="HeaderBar__CreateButton">
              <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} />
              Create Policy
            </button>
          </Link>
        ))}
      {showCreateNotificationButton && (
        <>
          {disableCreateNotificaiton ? (
            <MuitoolTip label={`No permission available. Please contact Organization Admin`}>
              <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} />
              <input
                // onClick={onCreateNotificationClick}
                disabled={disableCreateNotificaiton}
                className="HeaderBar__CreateButton"
                value="Create Alert"
                type="submit"
              />
            </MuitoolTip>
          ) : (
            <>
              {' '}
              {/* <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} />{' '}
              <input
                onClick={onCreateNotificationClick}
                disabled={disableCreateNotificaiton}
                className="HeaderBar__CreateButton"
                value="Create Alert"
                type="submit"
              /> */}
              <button
                disabled={disableCreateNotificaiton}
                onClick={onCreateNotificationClick}
                className="HeaderBar__CreateButton">
                <AddIcon sx={{ color: '#194bfb', height: '19px', marginBottom: '1px' }} />
                Create Alert
              </button>
            </>
          )}
        </>
      )}

      {settingButton && (
        <MuitoolTip
          label={
            addResourceTag ? `Tag Critical Resources` : `No permission available. Please contact Organization Admin`
          }>
          <Box
            sx={{
              fill: 'var(--headerbar-text-color)',
              background: 'var(--headerbar-icon-container-bg)',
              padding: '5px 10px',
              margin: '0 3px 0 10px',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              pointerEvents: !addResourceTag ? 'none' : 'auto',
              opacity: !addResourceTag ? 0.5 : 1,
              '&:hover': {
                background: 'var(--headerbar-icon-container-hover)',
              },
            }}
            onClick={handleSettingsClick}>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 7C5.10218 7 4.72064 6.84196 4.43934 6.56066C4.15804 6.27936 4 5.89782 4 5.5C4 5.10218 4.15804 4.72064 4.43934 4.43934C4.72064 4.15804 5.10218 4 5.5 4C5.89782 4 6.27936 4.15804 6.56066 4.43934C6.84196 4.72064 7 5.10218 7 5.5C7 5.89782 6.84196 6.27936 6.56066 6.56066C6.27936 6.84196 5.89782 7 5.5 7ZM21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.89 2 2 2.89 2 4V11C2 11.55 2.22 12.05 2.59 12.41L11.58 21.41C11.95 21.77 12.45 22 13 22C13.55 22 14.05 21.77 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.44 21.77 11.94 21.41 11.58Z"
                fill="var(--headerbar-text-color)"
              />
            </svg>
          </Box>
        </MuitoolTip>
      )}
      {showingEnvironment}
      {/* {showingEnvironment && (
        <div className="col-2 text-center p-2 ">
          Showing &nbsp;{envcount} of {envcount} Environments
        </div>
      )} */}
      {createnewenv}
      {bulkRemediation}
      {exportdata}
      {regionFilter}
      {handleAttackPathExport && (
        <MuitoolTip
          label={
            !disableResourceExportButton
              ? `No permission available. Please contact Organization Admin`
              : `Download Data`
          }>
          <Box
            sx={{
              pointerEvents: disableExportButton || !disableResourceExportButton ? 'none' : '',
              opacity: disableExportButton || !disableResourceExportButton ? '0.5' : '1',
              fill: 'var(--headerbar-text-color)',
              background: 'var(--headerbar-icon-container-bg)',
              /* Boder & Line/Light */
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid var(--headerbar-border-color)',
              boxShadow: '0px 8px 18px rgba(93, 106, 131, 0.05)',

              borderRadius: '5px',
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              marginLeft: '15px',

              '&:hover': {
                fill: 'white',
                background: '#194bfb',

                svg: {
                  fill: 'white',
                },
              },
            }}
            onClick={disableExportMenu ? onExportButtonClick : () => handleAttackPathExport('tableData', 'all')}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
              className="resources-tabbar-svg">
              <path d="M16.875 12.375H12.1641L10.582 13.9922C10.1602 14.4141 9.59766 14.625 9 14.625C8.36719 14.625 7.80469 14.4141 7.38281 13.9922L5.80078 12.375H1.125C0.492188 12.375 0 12.9023 0 13.5V16.875C0 17.5078 0.492188 18 1.125 18H16.875C17.4727 18 18 17.5078 18 16.875V13.5C18 12.9023 17.4727 12.375 16.875 12.375ZM15.1875 16.0312C14.6953 16.0312 14.3438 15.6797 14.3438 15.1875C14.3438 14.7305 14.6953 14.3438 15.1875 14.3438C15.6445 14.3438 16.0312 14.7305 16.0312 15.1875C16.0312 15.6797 15.6445 16.0312 15.1875 16.0312ZM8.19141 13.1836C8.40234 13.3945 8.68359 13.5 9 13.5C9.28125 13.5 9.5625 13.3945 9.77344 13.1836L14.2734 8.68359C14.7305 8.26172 14.7305 7.52344 14.2734 7.10156C13.8516 6.64453 13.1133 6.64453 12.6914 7.10156L10.125 9.66797V1.125C10.125 0.527344 9.59766 0 9 0C8.36719 0 7.875 0.527344 7.875 1.125V9.66797L5.27344 7.10156C4.85156 6.64453 4.11328 6.64453 3.69141 7.10156C3.23438 7.52344 3.23438 8.26172 3.69141 8.68359L8.19141 13.1836Z" />
            </svg>
          </Box>
        </MuitoolTip>
      )}
      {(!!handleExportData || exportButton) && (
        <>
          <MuitoolTip
            label={
              disableExportButton || !disableResourceExportButton
                ? `No permission available. Please contact Organization Admin`
                : `Download Data`
            }>
            <Box
              sx={{
                fill: 'var(--headerbar-text-color)',
                background: 'var(--headerbar-icon-container-bg)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                cursor: 'pointer',
                height: '33px',
                width: '35px',
                marginLeft: '10px',
                pointerEvents: disableExportButton || !disableResourceExportButton ? 'none' : '',
                opacity: disableExportButton || !disableResourceExportButton ? '0.5' : '1',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'var(--headerbar-icon-container-hover)',
                },
              }}
              onClick={disableExportMenu ? onExportButtonClick : handleClick}>
              <Download style={{ height: '20px' }} />
            </Box>
          </MuitoolTip>
          {!disableExportMenu && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{
                '& .MuiMenu-paper': {
                  border: '1px solid var(--headerbar-border-color)',
                  boxShadow: '0',
                  borderRadius: '8px',

                  padding: '0px',

                  '.MuiList-root': {
                    padding: '0',
                  },

                  '& .MuiMenuItem-root': {
                    color: 'var(--headerbar-text-color)',
                  },
                  // selecte first child in .MuiMenuItem-root
                  '& .MuiList-root>li:first-child': {
                    borderBottom: '1px solid var(--headerbar-border-color)',
                  },
                },
              }}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleExportData('tableData', 'all');
                }}>
                {exportDataText1 || 'Download All'}
              </MenuItem>
              {hideFilterButton ? (
                ''
              ) : (
                <MenuItem
                  disabled={!isFilterSelected}
                  onClick={() => {
                    handleClose();
                    handleExportData('tableData', 'filter');
                  }}>
                  {exportDataText2 || 'Download Filtered'}
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}

      {buttonGroup}

      {/* <Link
            to="/remediation-policy/create"
            onClick={(e) => {
              if (!createButtonEnabled) {
                e.preventDefault();
              }
            }}
          >
            <div
              className="HeaderBar__IconContainer"
              style={{
                cursor: createButtonEnabled ? "pointer" : "not-allowed",
              }}
            >
              <img
                src={
                  !createButtonEnabled
                    ? CreateIcon
                    : isCreateIconHover
                    ? CreateIconActive
                    : CreateIcon
                }
                alt=""
                onMouseEnter={() => setIsCreateIconHover(true)}
                onMouseOut={() => setIsCreateIconHover(false)}
              />
            </div>
          </Link> */}
    </div>
  );
}

export default HeaderBar;
