import { useDispatch, useSelector } from 'react-redux';
import { setTimeFilter, setTimeRange, setRangeValue } from '@root/containers/global_actions';
import { useCallback } from 'react';

const useTimeFilter = () => {
  const dispatch = useDispatch();
  const timeFilter = useSelector(state => state.timeFilterReducer.timeFilter);
  const timeRange = useSelector(state => state.timeFilterReducer.timeRange);
  const rangeValue = useSelector(state => state.timeFilterReducer.rangeValue);

  const updateTimeFilter = useCallback(
    newFilter => {
      dispatch(setTimeFilter(newFilter));
    },
    [dispatch],
  );

  const updateTimeRange = useCallback(
    newRange => {
      dispatch(setTimeRange(newRange));
    },
    [dispatch],
  );
  const updateRangeValue = useCallback(
    newRange => {
      dispatch(setRangeValue(newRange));
    },
    [dispatch],
  );

  return { timeFilter, timeRange, updateTimeFilter, updateTimeRange, rangeValue, updateRangeValue };
};

export default useTimeFilter;
