import { Box } from '@mui/material';
import CountsTooltip from '@root/components/Tooltip/CountTooltip';
import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import { useHistory } from 'react-router';

// import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
// import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
// import AWSIcon from '@root/assets/img/header/cloud_providers/aws.png';
// import AzureIcon from '@root/assets/img/header/cloud_providers/azure.png';
// import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';

const AlertKeysMapping = notification => {
  if (notification.notification_name.toUpperCase().includes('CQ SHIELD')) {
    return {
      notificationName: notification.notification_name,
      path: `/inventory/resources-table`,
      redirectionValue: notification.Environment_Id,
      queryParams: `&severity=${notification.child_event?.toLowerCase()}`,
    };
  } else if (notification.notification_name.toUpperCase().includes('NON COMPLIANT')) {
    return {
      notificationName: notification.notification_name,
      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('DRIFT EVENT ACTIVITY')) {
    return {
      notificationName: notification.notification_name,
      path: '/events/all',
      redirectionValue: notification.Event_Id,
    };
  } else if (notification.notification_name.toUpperCase().includes('REMEDIATION PERFORMED')) {
    return {
      notificationName: notification.notification_name,
      path: '/events/all',
      queryParams: `&tab=Remediation`,
      redirectionValue: '',
    };
  } else if (
    notification.notification_name.toUpperCase().includes('THREAT ACTIVITY DETECTED') ||
    notification.notification_name.toUpperCase().includes('ANOMALOUS ACTIVITY DETECTED')
  ) {
    return {
      notificationName: notification.notification_name,
      path: '/events/all',
      queryParams: `&tab=Threat`,
      redirectionValue: notification.Event_Id,
    };
  } else if (notification.notification_name.toUpperCase().includes('VULNERABILITY DETECTED')) {
    return {
      notificationName: notification.notification_name,
      path: '/events/all',
      queryParams: `&tab=Vulnerability`,
      redirectionValue: notification.CVE_Id,
    };
  } else if (notification.notification_name.toUpperCase().includes('EXCEPTION')) {
    return {
      notificationName: notification.notification_name,
      path: `/exceptions`,
      redirectionValue: notification.child_event,
      isDisable: notification.child_event === 'Revoke',
    };
  } else if (notification.notification_name.toUpperCase().includes('ENVIRONMENT')) {
    return {
      notificationName: notification.notification_name,
      path: '/environments',
      redirectionValue: notification.Environment_Id,
      isDisable: notification.child_event === 'Delete',
    };
  } else if (notification.notification_name.toUpperCase().includes('GROUP')) {
    return {
      notificationName: notification.notification_name,
      tab: 'Groups',
      redirectionValue: notification.Group_Name,
      path: '/rbac',
      isDisable: notification.child_event === 'Delete',
    };
  } else if (notification.notification_name.toUpperCase().includes('USER')) {
    return {
      notificationName: notification.notification_name,
      tab: 'Users',
      redirectionValue: notification?.['Added_User_Email']?.[0] ?? '',
      path: '/rbac',
      isDisable: notification.child_event === 'Remove',
    };
  } else if (notification.notification_name.toUpperCase().includes('MALWARE DETECTED')) {
    return {
      notificationName: notification.notification_name,
      path: '/data-security',
      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('MALWARE')) {
    return {
      notificationName: notification.notification_name,
      path: '/data-security',
      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('SCAN')) {
    return {
      notificationName: notification.notification_name,
      path: '/scan-history',
      redirectionValue: notification.Scan_ID,
      tab: notification.Scan_Type,
    };
  } else if (notification.notification_name.toUpperCase().includes('REMEDIATION POLICY')) {
    return {
      notificationName: notification.notification_name,
      path: '/remediation-policy',
      isDisable: notification.child_event === 'Delete',
      redirectionValue: notification.Policy_Name,
    };
  } else if (notification.notification_name.toUpperCase().includes('RULE')) {
    return {
      notificationName: notification.notification_name,
      path: '/rules/table',
      redirectionValue: notification.Disabled_Rule_Id ? notification.Disabled_Rule_Id : notification.Enabled_Rule_Id,
    };
  } else if (notification.notification_name.toUpperCase().includes('THREAT')) {
    return {
      notificationName: notification.notification_name,

      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('JIRA')) {
    return {
      notificationName: notification.notification_name,

      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('MODULE')) {
    return {
      notificationName: notification.notification_name,

      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('MONITORING')) {
    return {
      notificationName: notification.notification_name,

      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('PII/PCI/PHI')) {
    return {
      notificationName: notification.notification_name,
      path: '/data-security',
      redirectionValue: '',
    };
  } else if (notification.notification_name.toUpperCase().includes('ATTACK PATH')) {
    return {
      notificationName: notification.notification_name,
      path: '/attack-path-analysis',
      redirectionValue: notification.Environment_Id,
      queryParams: `&severity=${notification.child_event?.toLowerCase()}`,
    };
  }
};

const NotificationMap = ({ notification }) => {
  const history = useHistory();
  const iconColorMapping = (alert, shield) => {
    if (shield === 'Shield_Severity') {
      return `${alert.Shield_Severity[0].toUpperCase() + alert.Shield_Severity.slice(1).toLowerCase() + '__Status'}`;
    }
    if (notification.child_event === 'Data Classification') {
      return `DS__Status`;
    }
    if (shield === 'CVSS_Severity') {
      return `${alert.CVSS_Severity[0].toUpperCase() + alert.CVSS_Severity.slice(1).toLowerCase() + '__Status'}`;
    }
    if (shield === 'CVE_Risk') {
      return 'Critical__Status';
    }
    return `${alert?.child_event?.[0]?.toUpperCase() + alert?.child_event?.slice(1).toLowerCase() + '__Status'}`;
  };
  const currentDate = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  const alertDateGMT = notification.created_time;

  const alertDate = moment(alertDateGMT).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');

  const timeDifference = useMemo(() => {
    if (!notification && !notification.created_time) return '';

    const diffInMilliseconds = Math.abs(new Date(currentDate) - new Date(alertDate));

    const seconds = Math.floor(diffInMilliseconds / 1000);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }, [currentDate, alertDate, notification]);

  const handleOnClickRedirection = useCallback(
    notificationValue => {
      const redirectionData = AlertKeysMapping(notificationValue);
      console.log(notificationValue, 'notificationValuenotificationValue');
      if (!redirectionData.isDisable) {
        const url = buildBreadcrumbUrl({
          path: `${redirectionData.path}?notification_key=${redirectionData?.redirectionValue}${
            redirectionData?.tab ? '&_tab=' : ''
          }${redirectionData?.tab ?? ''}${redirectionData?.queryParams ?? ''}`,
          breadcrumbs: [{ title: 'Notification' }],
        });
        history.push(url);
      }
    },
    [history],
  );

  if (!notification.notification_name) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">
                {notification.notification_name || 'Notification name is NULL'}
              </div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">Notification Name is not Coming from Backend</div>
            <div className="NotificationPanel__ItemList">
              Affected Resources •&nbsp; <span>80</span>
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('CQ SHIELD')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {notification.Child_Events[0].toUpperCase() + notification.Child_Events.slice(1).toLowerCase()}
            </div>
            <div className="NotificationPanel__ItemList">
              Affected Resources • <span>{notification.Total_Affected_Resources}</span>
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('NON COMPLIANT')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            {notification.child_event && (
              <div className={`NotificationPanel__ItemList ${iconColorMapping(notification)} `}>
                {notification.child_event}
              </div>
            )}
            <div className="NotificationPanel__ItemList">{notification.Event_Source}</div>
            <div className="NotificationPanel__ItemList">{notification.Generated_By_Email_Id}</div>

            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('ANOMALOUS ACTIVITY DETECTED')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div
              className={`NotificationPanel__ItemStatusIndicator ${
                notification.Severity[0].toUpperCase() + notification.Severity.slice(1).toLowerCase()
              }__Status`}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div
              className={`NotificationPanel__ItemList ${
                notification.Severity[0].toUpperCase() + notification.Severity.slice(1).toLowerCase()
              }__Status`}>
              {notification.Severity[0].toUpperCase() + notification.Severity.slice(1).toLowerCase()}
            </div>
            <div className="NotificationPanel__ItemList">{notification.Master_Rule_Id}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('DRIFT EVENT ACTIVITY')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          {/* <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div> */}
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Event_Name}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }

  if (notification.notification_name.toUpperCase().includes('THREAT ACTIVITY DETECTED')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div
              className={`NotificationPanel__ItemStatusIndicator ${
                notification.Severity[0].toUpperCase() + notification.Severity.slice(1).toLowerCase()
              }__Status `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {notification.Severity[0].toUpperCase() + notification.Severity.slice(1).toLowerCase()}
            </div>
            <div className="NotificationPanel__ItemList">{notification.Master_Rule_Id}</div>
            <div className="NotificationPanel__ItemList">{notification.Event_Source}</div>

            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('VULNERABILITY DETECTED')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div
              className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(
                notification,
                notification['Shield_Severity']
                  ? 'Shield_Severity'
                  : notification['CVSS_Severity']
                  ? 'CVSS_Severity'
                  : 'CVE_Risk',
              )} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {notification['CVE Risk']
                ? notification['CVE Risk']
                : notification['CVSS_Severity']
                ? notification['CVSS_Severity']
                : notification['Shield_Severity']}
            </div>
            <div className="NotificationPanel__ItemList">{notification.CVE_Id}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('EXCEPTION')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>

            <div className="NotificationPanel__ItemList">
              {' '}
              <CountsTooltip
                showFirst={true}
                labels={notification['Master_Rule_Id']}
                visibleLabelStyle={{ width: '140px !important' }}
                borderless
              />{' '}
            </div>
            <div className="NotificationPanel__ItemList">
              {notification['Created_By_Email']
                ? notification['Created_By_Email']
                : notification['Edited_By_Email']
                ? notification['Edited_By_Email']
                : notification['Revoked_By_Email']}
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('ENVIRONMENT')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {notification['Onboarded_By_Email']
                ? notification['Onboarded_By_Email']
                : notification['Edited_By_Email']
                ? notification['Edited_By_Email']
                : notification['Deleted_By_Email']
                ? notification['Deleted_By_Email']
                : notification['Suppressed_By']}
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('GROUP')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification['Group_Name']}</div>
            {notification['Created_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Created_By_Email']}</div>
            )}
            {notification['Edited_By_Email_Id'] && (
              <div className="NotificationPanel__ItemList">{notification['Edited_By_Email_Id']}</div>
            )}
            {notification['Removed_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Removed_By_Email']}</div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('MALWARE DETECTED')) {
    console.log('this has the error', notification);

    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Bucket_name}</div>
            <div className="NotificationPanel__ItemList">
              Malware Object Count • <span>{notification.Mal_object_count}</span>
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('MALWARE')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.child_event}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('SCAN')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)}`}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>

            <div className="NotificationPanel__ItemList">{notification.Scan_Type}</div>
            <div className="NotificationPanel__ItemList">{notification.Scan_ID}</div>
            <div className="NotificationPanel__ItemList">{notification.Generated_By_Email_Id}</div>

            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('USER')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.child_event}</div>
            {notification['Added_User_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Added_User_Email']}</div>
            )}
            {notification['User'] && <div className="NotificationPanel__ItemList">{notification['User']}</div>}
            {notification['Removed_User_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Removed_User_Email']}</div>
            )}
            {notification['Added_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Added_By_Email']}</div>
            )}
            {notification['Edited_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Edited_By_Email']}</div>
            )}
            {notification['Removed_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Removed_By_Email']}</div>
            )}
          </div>
        </Box>
      </div>
    );
  }

  if (notification.notification_name.toUpperCase().includes('RULE')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.child_event}</div>
            {notification['Disabled_Rule_Id'] && (
              <div className="NotificationPanel__ItemList">{notification['Disabled_Rule_Id']}</div>
            )}
            {notification['Enabled_Rule_Id'] && (
              <div className="NotificationPanel__ItemList">{notification['Enabled_Rule_Id']}</div>
            )}
            {notification['Disabled_By_Email_Id'] && (
              <div className="NotificationPanel__ItemList">{notification['Disabled_By_Email_Id']}</div>
            )}
            {notification['Enabled_By_Email_Id'] && (
              <div className="NotificationPanel__ItemList">{notification['Enabled_By_Email_Id']}</div>
            )}
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('THREAT')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Event_Name}</div>
            <div className="NotificationPanel__ItemList">{notification.Admin_Email_Id}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('JIRA')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>

            <div className="NotificationPanel__ItemList">
              {notification['Edited_By'] ? notification['Edited_By'] : notification['Created_By']}
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('MODULE')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {' '}
              {`Modules •`}&nbsp;
              <span>
                <CountsTooltip
                  showFirst={true}
                  labels={notification.Modules}
                  visibleLabelStyle={{ width: '140px !important' }}
                  borderless
                />{' '}
              </span>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Edited_By_Email}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('MONITORING')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${notification.Event_Source}__Status `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Event_Source}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('PII/PCI/PHI')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            {/* <div className="NotificationPanel__ItemList">
              {' '}
              {`Modules •`}&nbsp;
              <span>
                <CountsTooltip
                  showFirst={true}
                  labels={notification.DS_label}
                  visibleLabelStyle={{ width: '140px !important' }}
                  borderless
                />{' '}
              </span>
            </div> */}
            {notification.DS_label && notification.DS_label[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Labels •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.DS_label}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
            <div className="NotificationPanel__ItemList">{notification.DS_scan}</div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('ATTACK PATH')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.Child_Events}</div>
            <div className="NotificationPanel__ItemList">
              Attack Path Count • <span>{notification.Attack_Path_Count}</span>
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('BULK')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator Success__Status `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification.child_event}</div>
            <div className="NotificationPanel__ItemList">
              Success Count • <span>{notification.Success_count}</span>
            </div>
            <div className="NotificationPanel__ItemList">
              Resources Count • <span>{notification.Resources_count}</span>
            </div>
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.trim().toUpperCase().includes('REMEDIATION POLICY')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">{notification['Remediation_Type']}</div>
            <div className="NotificationPanel__ItemList">{notification['Service']}</div>
            {notification['Created_By'] && (
              <div className="NotificationPanel__ItemList">{notification['Created_By']}</div>
            )}
            {notification['Edited_By'] && (
              <div className="NotificationPanel__ItemList">{notification['Edited_By']}</div>
            )}
            {notification['Revoked_By_Email'] && (
              <div className="NotificationPanel__ItemList">{notification['Revoked_By_Email']}</div>
            )}
            {notification.Environment_Id && notification.Environment_Id[0] ? (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </div>
    );
  }
  if (notification.notification_name.toUpperCase().includes('REMEDIATION PERFORMED')) {
    return (
      <div
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        className="NotificationPanel__Item__Wrapper"
        onClick={() => handleOnClickRedirection(notification)}>
        <Box className="CustomScrollbar NotificationPanel__Item">
          <div className="NotificationPanel__ItemStatusIndicatorWrapper">
            <div
              className={`NotificationPanel__ItemStatusIndicator ${
                notification?.['Result']?.[0]?.toUpperCase() + notification?.['Result']?.slice(1)?.toLowerCase()
              }__Status `}></div>
          </div>
          <div className="NotificationPanel__ItemInfoWrapper">
            <div className="NotificationPanel__ItemEventTimeWrapper">
              <div className="NotificationPanel__Event">{AlertKeysMapping(notification).notificationName}</div>
              {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
              <div className="NotificationPanel__DateTime">{timeDifference}</div>
            </div>
            <div className="NotificationPanel__ItemList">
              {notification['Result'][0].toUpperCase() + notification['Result'].slice(1).toLowerCase()}
            </div>
            {notification.Executed_By && <div className="NotificationPanel__ItemList">{notification.Executed_By}</div>}
            {notification.Environment_Id && notification.Environment_Id[0] && (
              <div
                className="NotificationPanel__EnvironmnetID"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', display: 'flex' }}>
                  {`Environment ID •`}&nbsp;
                  <span>
                    <CountsTooltip
                      showFirst={true}
                      labels={notification.Environment_Id}
                      visibleLabelStyle={{ width: '140px !important' }}
                      borderless
                    />{' '}
                  </span>
                </div>

                {/* <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  {Array.from(new Set(notification.cloud_provider)).map(cloud => (
                    <img
                      src={cloud === 'aws' ? AWSIcon : cloud === 'gcp' ? GCPIcon : cloud === 'azure' ? AzureIcon : ''}
                      style={{ height: '20px', marginRight: '8px' }}
                    />
                  ))}
                </div> */}
              </div>
            )}
          </div>
        </Box>
      </div>
    );
  }
  //   if (notification.notification_name.toUpperCase() === 'ANAMALOUS ACTIVITY DETECTED') {
  //     return (
  //      <div style={{ textDecoration: 'none', cursor: 'pointer' }} className="NotificationPanel__Item__Wrapper" onClick={handleAlertClck(notification.id)}>
  //         <Box className="CustomScrollbar NotificationPanel__Item">
  //           <div className="NotificationPanel__ItemStatusIndicatorWrapper">
  //             <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
  //           </div>
  //           <div className="NotificationPanel__ItemInfoWrapper">
  //             <div className="NotificationPanel__ItemEventTimeWrapper">
  //               <div className="NotificationPanel__Event">{notification.event}</div>
  //               {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
  //               <div className="NotificationPanel__DateTime"></div>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               {notification.child_event[0].toUpperCase() + notification.child_event.slice(1).toLowerCase()}
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Affected Resources: <span>{notification['Total Effected Resources']}</span>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Environment ID: <span>{notification.Environment_Id}</span>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     );
  //   }
  //   if (notification.notification_name.toUpperCase() === 'ANAMALOUS ACTIVITY DETECTED') {
  //     return (
  //      <div style={{ textDecoration: 'none', cursor: 'pointer' }} className="NotificationPanel__Item__Wrapper" onClick={handleAlertClck(notification.id)}>
  //         <Box className="CustomScrollbar NotificationPanel__Item">
  //           <div className="NotificationPanel__ItemStatusIndicatorWrapper">
  //             <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
  //           </div>
  //           <div className="NotificationPanel__ItemInfoWrapper">
  //             <div className="NotificationPanel__ItemEventTimeWrapper">
  //               <div className="NotificationPanel__Event">{notification.event}</div>
  //               {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
  //               <div className="NotificationPanel__DateTime"></div>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               {notification.child_event[0].toUpperCase() + notification.child_event.slice(1).toLowerCase()}
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Affected Resources: <span>{notification['Total Effected Resources']}</span>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Environment ID: <span>{notification.Environment_Id}</span>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     );
  //   }
  //   if (notification.notification_name.toUpperCase() === 'ANAMALOUS ACTIVITY DETECTED') {
  //     return (
  //      <div style={{ textDecoration: 'none', cursor: 'pointer' }} className="NotificationPanel__Item__Wrapper" onClick={handleAlertClck(notification.id)}>
  //         <Box className="CustomScrollbar NotificationPanel__Item">
  //           <div className="NotificationPanel__ItemStatusIndicatorWrapper">
  //             <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
  //           </div>
  //           <div className="NotificationPanel__ItemInfoWrapper">
  //             <div className="NotificationPanel__ItemEventTimeWrapper">
  //               <div className="NotificationPanel__Event">{notification.event}</div>
  //               {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
  //               <div className="NotificationPanel__DateTime"></div>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               {notification.child_event[0].toUpperCase() + notification.child_event.slice(1).toLowerCase()}
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Affected Resources: <span>{notification['Total Effected Resources']}</span>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Environment ID: <span>{notification.Environment_Id}</span>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     );
  //   }
  //   if (notification.notification_name.toUpperCase() === 'ANAMALOUS ACTIVITY DETECTED') {
  //     return (
  //      <div style={{ textDecoration: 'none', cursor: 'pointer' }} className="NotificationPanel__Item__Wrapper" onClick={handleAlertClck(notification.id)}>
  //         <Box className="CustomScrollbar NotificationPanel__Item">
  //           <div className="NotificationPanel__ItemStatusIndicatorWrapper">
  //             <div className={`NotificationPanel__ItemStatusIndicator ${iconColorMapping(notification)} `}></div>
  //           </div>
  //           <div className="NotificationPanel__ItemInfoWrapper">
  //             <div className="NotificationPanel__ItemEventTimeWrapper">
  //               <div className="NotificationPanel__Event">{notification.event}</div>
  //               {/* <div className="NotificationPanel__DateTime">2 day ago</div> */}
  //               <div className="NotificationPanel__DateTime"></div>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               {notification.child_event[0].toUpperCase() + notification.child_event.slice(1).toLowerCase()}
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Affected Resources: <span>{notification['Total Effected Resources']}</span>
  //             </div>
  //             <div className="NotificationPanel__ItemList">
  //               Environment ID: <span>{notification.Environment_Id}</span>
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     );
  //   }

  return <></>;
};
export default NotificationMap;
