export const VulnerabilityCounts = ({ vulnerabilities }) => {
  // Define severity levels and their colors
  const severityConfig = [
    { type: 'critical', color: '#FF0000', value: vulnerabilities.critical ?? 0 },
    { type: 'high', color: '#e74c3c', value: vulnerabilities.high ?? 0 },
    { type: 'medium', color: '#f39c12', value: vulnerabilities.medium ?? 0 },
    { type: 'low', color: '#f1c40f', value: vulnerabilities.low ?? 0 },
  ];

  const styles = {
    container: { display: 'flex', alignItems: 'center', gap: '16px' },
    item: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid var(--border-color-light)',
      borderRadius: '0px 2px 2px 0px',
    },
    bar: {
      width: '6px',
      height: '24px',
      borderRadius: '2px 0px 0px 2px',
      marginRight: '6px',
    },
    count: { fontSize: '14px', fontWeight: '500',paddingRight:'6px' },
  };

  return (
    <div style={styles.container}>
      {severityConfig
        // .filter(level => level.value > 0)
        .map(level => (
          <div key={level.type} style={styles.item}>
            <div style={{ ...styles.bar, backgroundColor: level.color }}></div>
            <span style={styles.count}>{level.value}</span>
          </div>
        ))}
    </div>
  );
};
