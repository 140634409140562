// import networkIcon from '@assets/svg/risk/Network.svg';
// import dataIcon from '@assets/svg/risk/Data.svg';
// import identityIcon from '@assets/svg/risk/Identity.svg';
// import lateralMovementIcon from '@assets/svg/risk/LateralMovement.svg';
// import encryptionAtRestIcon from '@assets/svg/risk/EncryptionAtRest.svg';
// import encryptionInEncryptionIcon from '@assets/svg/risk/EncryptionInEncryption.svg';
// import highAvailabilityIcon from '@assets/svg/risk/HighAvailability.svg';
// import loggingAndMonitoringIcon from '@assets/svg/risk/LoggingAndMonitoring.svg';

// import anomalousLogo from '@root/assets/svg/Cloud_Events/Anomalous.svg';
import anomalousLogo from '@root/assets/svg/Cloud_Events/anamolus-logo.svg';
import dataLogo from '@root/assets/svg/Cloud_Events/data-security-logo.svg';
// import encryptionLogo from '@root/assets/svg/Cloud_Events/Encryption.svg';
import encryptionLogo from '@root/assets/svg/Cloud_Events/encryption-logo.svg';
// import exposedSecretsLogo from '@root/assets/svg/Cloud_Events/ExposedSecrets.svg';
import exposedSecretsLogo from '@root/assets/svg/Cloud_Events/exposed-secret.svg';
// import highAvailabilityLogo from '@root/assets/svg/Cloud_Events/HighAvailability.svg';
import highAvailabilityLogo from '@root/assets/svg/Cloud_Events/high-availability.svg';
// import identityLogo from '@root/assets/svg/Cloud_Events/Identity.svg';
import identityLogo from '@root/assets/svg/Cloud_Events/identity-logo.svg';
// import loggingMonitoringLogo from '@root/assets/svg/Cloud_Events/LoggingMonitoring.svg';
import loggingMonitoringLogo from '@root/assets/svg/Cloud_Events/logging-and-monitoring.svg';
// import networkLogo from '@root/assets/svg/Cloud_Events/Network.svg';
import networkLogo from '@root/assets/svg/Cloud_Events/network-logo.svg';
// import noRiskLogo from '@root/assets/svg/Cloud_Events/No_Risk.svg';
import noRiskLogo from '@root/assets/svg/Cloud_Events/no-risk-logo.svg';
import threatLogo from '@root/assets/svg/Cloud_Events/Threat.svg';
// import userLogo from '@root/assets/svg/Cloud_Events/User.svg';
import userLogo from '@root/assets/svg/Cloud_Events/user-logo.svg';
// import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/Vulnerabilities.svg';
import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/vulnerabilities-logo.svg';
// import administratorLogo from '@root/assets/svg/Cloud_Events/administrator.svg';
import administratorLogo from '@root/assets/svg/Risk/RiskPriority/admin.svg';
// import credentialExposureLogo from '@root/assets/svg/Cloud_Events/credentialExposure.svg';
import credentialExposureLogo from '@root/assets/svg/Risk/RiskPriority/credential-exposure.svg';
// import inactiveLogo from '@root/assets/svg/Cloud_Events/inactive.svg';
import inactiveLogo from '@root/assets/svg/Risk/RiskPriority/inactive.png';
// import infrastructureModificationLogo from '@root/assets/svg/Cloud_Events/infrastructureModification.svg';
import infrastructureModificationLogo from '@root/assets/svg/Risk/RiskPriority/infrastructure-modification.svg';
// import misconfigurationIcon from '@root/assets/svg/Cloud_Events/misconfiguration.svg';
import misconfigurationIcon from '@root/assets/svg/Cloud_Events/misconfigurationlogo.svg';
// import overPrivilegedLogo from '@root/assets/svg/Cloud_Events/overPrivileged.svg';
import overPrivilegedLogo from '@root/assets/svg/Risk/RiskPriority/over-privileged.svg';
// import privilegeEscalationLogo from '@root/assets/svg/Cloud_Events/privilegeEscalation.svg';
import privilegeEscalationLogo from '@root/assets/svg/Risk/RiskPriority/privilege-escalation.svg';
// import privilegedLogo from '@root/assets/svg/Cloud_Events/privileged.svg';
import privilegedLogo from '@root/assets/svg/Risk/RiskPriority/privledge.png';
// import reconnaissanceLogo from '@root/assets/svg/Cloud_Events/reconnaissance.svg';
import reconnaissanceLogo from '@root/assets/svg/Risk/RiskPriority/reconnaissance.svg';
import Malware from '@root/assets/svg/Cloud_Events/malware.svg';
// import Malware from '@root/assets/svg/Threats/Malware.svg';
import RuntimeLogo from '@root/assets/svg/Cloud_Events/runtimelogo.svg';
// import RuntimeLogo from '@root/assets/svg/Threats/Runtime.svg';

// Lateral Movement New Logo
import lateralMovementLogo from '@root/assets/svg/Cloud_Events/lateral-movement.svg';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
// import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.85rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#fafafa',
  },
}))(Tooltip);

const IMAGE_MAP = {
  ['Data']: dataLogo,
  ['Credential Exposure']: credentialExposureLogo,
  ['Privilege Escalation']: privilegeEscalationLogo,
  ['Reconnaissance']: reconnaissanceLogo,
  ['Privileged']: privilegedLogo,
  ['Inactive']: inactiveLogo,
  ['Threat']: threatLogo,
  ['Administrator']: administratorLogo,
  ['Admin']: administratorLogo,
  ['Infrastructure Modification']: infrastructureModificationLogo,
  ['Over Privileged']: overPrivilegedLogo,
  ['Data Security']: dataLogo,
  ['Encryption']: encryptionLogo,
  ['Exposed Secrets']: exposedSecretsLogo,
  ['Network']: networkLogo,
  ['Anomalous']: anomalousLogo,
  ['Logging And Monitoring']: loggingMonitoringLogo,
  ['Logging & Monitoring']: loggingMonitoringLogo,
  ['Lateral Movement']: lateralMovementLogo,
  ['High Availability']: highAvailabilityLogo,
  ['Identity']: identityLogo,
  ['User']: userLogo,
  ['Vulnerabilities']: vulnerabilitiesLogo,
  ['NoRisk']: noRiskLogo,
  ['Misconfiguration']: misconfigurationIcon,
  ['Malware']: Malware,
  ['Runtime']: RuntimeLogo,

  // Kubernetes Risk Temp
  ['Effective Admin']: administratorLogo,
  ['Data Access']: dataLogo,
  ['High Privileged']: overPrivilegedLogo,

  // ['']

  ['Misconfigurations']: misconfigurationIcon,
  ['Threats anomaly']: threatLogo,
};
// const COLOR_MAP = {
//   ['Data']: '#f9efff',
//   ['Credential Exposure']: '#fbf3eb',
//   ['Privilege Escalation']: '#f7e8e6',
//   ['Reconnaissance']: '#f4fdf9',
//   ['Privileged']: '#f1f2f3',
//   ['Inactive']: '#fff5e6',
//   ['Threat']: '#fdebe8',
//   ['Administrator']: '#f3f1ff',
//   ['Admin']: '#f3f1ff',
//   ['Infrastructure Modification']: '#eefaef',
//   ['Over Privileged']: '#f0f5ff',
//   ['Data Security']: '#f9efff',
//   ['Encryption']: '#f3f1ff',
//   ['Exposed Secrets']: '#fff2df',
//   ['Network']: '#e6efff',
//   ['Anomalous']: '#dedede',
//   ['Logging And Monitoring']: '#fff2f1',
//   ['Logging & Monitoring']: '#fff2f1',
//   ['Lateral Movement']: '#ffeaea',
//   ['High Availability']: '#ebeced',
//   ['Identity']: '#effcf5',
//   ['User']: '#fceaed',
//   ['Vulnerabilities']: '#f0f5ff',
//   ['NoRisk']: '#ecf9ec',
//   ['Misconfiguration']: '#ebf2ff',
//   ['Malware']: '#e6efff',
//   ['Runtime']: '#f9efff',

//   // Kubernetes Risk Temp
//   ['Effective Admin']: administratorLogo,
//   ['Data Access']: dataLogo,
//   ['High Privileged']: overPrivilegedLogo,

//   // ['']

//   ['Misconfigurations']: misconfigurationIcon,
//   ['Threats anomaly']: threatLogo,
// };
export const RiskTypeIcons = ({
  name,
  paddingRight = '5px',
  rowIndex,
  width = '25px',
  tooltip,
  height = '25px',
  // heightbg = '30px',
  // widthbg = '30px',
  count,
  bgNone = false,
}) => {
  return (
    <>
      {name && IMAGE_MAP[name] && count ? (
        <>
          <span style={{ paddingRight: paddingRight }}>
            {tooltip ? (
              <Tooltip title={name} isIcon={true}>
                {/* <div
                  style={{
                    background: COLOR_MAP[name],
                    width: heightbg,
                    height: widthbg,
                    borderRadius: '7px',
                    display: 'flex',
                    padding: '3px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}> */}
                <img
                  id={`${name?.toLowerCase()}-${rowIndex}`}
                  src={IMAGE_MAP[name]}
                  style={{ objectFit: 'contain', width: width, height: height }}
                  alt={name}
                />
                {/* </div> */}
              </Tooltip>
            ) : (
              // <div
              // style={{
              //   background: COLOR_MAP[name],
              //   width: heightbg,
              //   height: widthbg,
              //   borderRadius: '7px',
              //   display: 'flex',
              //   padding: '3px',
              //   justifyContent: 'center',
              //   alignItems: 'center',
              // }}>
              <img
                id={`${name?.toLowerCase()}-${rowIndex}`}
                src={IMAGE_MAP[name]}
                style={{ objectFit: 'contain', width: width, height: height }}
                alt={name}
              />
              // </div>
            )}
          </span>
          {count > 1 && <span style={{ color: 'navy' }}>{`+${count - 1}`}</span>}
        </>
      ) : name && IMAGE_MAP[name] ? (
        <span style={{ paddingRight: paddingRight }}>
          {tooltip ? (
            <Tooltip title={name} isIcon={true}>
              {/* <div
                style={{
                  background: COLOR_MAP[name],
                  width: heightbg,
                  height: widthbg,
                  borderRadius: '7px',
                  display: 'flex',
                  padding: '3px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}> */}
              <img
                id={`${name?.toLowerCase()}-${rowIndex}`}
                src={IMAGE_MAP[name]}
                style={{ objectFit: 'contain', width: width, height: height }}
                alt={name}
              />
              {/* </div> */}
            </Tooltip>
          ) : (
            <>
              {' '}
              {bgNone ? (
                <img
                  id={`${name?.toLowerCase()}-${rowIndex}`}
                  src={IMAGE_MAP[name]}
                  style={{ objectFit: 'contain', width: width, height: height }}
                  alt={name}
                />
              ) : (
                // <div
                //   style={{
                //     background: COLOR_MAP[name],
                //     width: heightbg,
                //     height: widthbg,
                //     borderRadius: '7px',
                //     display: 'flex',
                //     padding: '3px',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                //   }}>
                <img
                  id={`${name?.toLowerCase()}-${rowIndex}`}
                  src={IMAGE_MAP[name]}
                  style={{ objectFit: 'contain', width: width, height: height }}
                  alt={name}
                />
                // </div>
              )}{' '}
            </>
          )}
        </span>
      ) : (
        name
      )}
    </>
  );
};
export const RiskCountTooltip = ({
  labels,
  customLength = 1, // New prop to control how many icons to show
}) => {
  if (!labels || labels.length === 0) return '-';

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      {/* Show first customLength icons directly */}
      {customLength > 0 &&
        labels.slice(0, customLength).map((risk, index) => <RiskTypeIcons key={index} tooltip={true} name={risk} />)}

      {/* If there are more icons than customLength, show tooltip for remaining */}
      {labels.length > customLength ? (
        <HtmlTooltip
          title={
            <div className="CountTooltip__Content">
              {labels.slice(customLength).map(label => (
                <div className="CountTooltip__Item" key={label}>
                  {label}
                </div>
              ))}
            </div>
          }
          arrow
          isIcon={true}>
          <span className="CountTooltip__CountContainer"> +{labels.length - customLength}</span>
        </HtmlTooltip>
      ) : (
        <></>
      )}
    </div>
  );
};
