import {
  AreaChart as AChart,
  Area,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
// import { scaleSymlog } from 'd3-scale';
// import _ from 'lodash';
// import { makeStyles } from '@mui/styles';
import './style.scss';

// const useStyles = makeStyles(theme => ({
//   tooltip: {
//     backgroundColor: '#fff',
//     // boxShadow: theme.shadows[1],
//     color: theme.palette.text.primary,
//     padding: theme.spacing(1),
//   },
// }));

const CustomAreaChart = ({ data, type }) => {
  const renderColorfulLegendText = value => {
    return (
      <span
        style={{
          fontSize: '12px',
          fontWeight: '600',
          fontFamily: 'Manrope',
          color: '#767980',
          // margin: '20px',
        }}>
        {value === 'average.average_score' ? 'Average' : ''}
        {value === 'security_score.security_score' ? 'Score' : ''}
        {value === 'compliant_data.total_score'
          ? 'Compliant'
          : value === 'non_compliant_data.total_score'
          ? 'Non Compliant'
          : ''}
        {value === 'remediation.total' ? 'Remediations' : value === 'exception.total' ? 'Exceptions' : ''}
      </span>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    // const classes = useStyles();

    if (active && payload) {
      // console.log(payload, 'sdfasdfasdfasdfasdfafads');

      // if (type === 'remediation_exception') {
      return (
        <div className="custom_tooltip">
          <div className="custom_tooltip_count">{payload[0]?.payload.name}</div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {payload.map((item, index) => (
              <p
                key={index}
                className="custom_tooltip_severity m-0"
                style={{
                  borderLeft: `4px solid ${item.color}`,
                  paddingLeft: '4px',
                  borderRadius: '1px',
                }}>
                {item.dataKey.split('.')[0].charAt(0).toUpperCase() +
                  item.dataKey.split('.')[0].replace('_', ' ').slice(1).split('data')[0].split('_')[0]}
                :{' '}
                <span className="custom_tooltip_count">
                  {
                    Object.entries(item.payload[item.dataKey.split('.')[0]])
                      .filter(
                        ([key]) => key.includes('total') || key.includes('average') || key.includes('security_score'),
                      )
                      .map(([, value]) => `${value}`)[0]
                  }
                </span>
              </p>
            ))}
          </div>
          {/* <Typography variant="subtitle2">{label}</Typography> */}
          {/* <span
            style={{
              fontFamily: 'Manrope',
              fontStyle: 'bold',
            }}>{`${payload[0]?.payload.name}`}</span>
          {payload.map(item => (
            <>
              <div
                style={{
                  display: 'flex',
                  marginTop: '2px',
                }}></div>
              {Object.entries(item.payload[item.dataKey.split('.')[0]]).map(([key, value]) => (
                <>
                  {(key.includes('total') || key.includes('average')) && (
                    <>
                      <span
                        style={{
                          color: `${item.color}`,
                          marginRight: '5px',
                        }}>
                        {item.dataKey.split('.')[0].charAt(0).toUpperCase() +
                          item.dataKey.split('.')[0].replace('_', ' ').slice(1).split('data')[0].split('_')[0]}
                        :
                      </span>
                      <span
                        style={{
                          color: `${item.color}`,
                        }}>{`${value}`}</span>
                    </>
                  )}
                </>
              ))}
            </>
          ))} */}
        </div>
      );
    }

    return null;
  };

  const CustomLightTooltip = props => {
    return <CustomTooltip {...props} />;
  };

  return (
    <ResponsiveContainer>
      <AChart width={460} height={190} data={data} margin={{ top: 10, right: 15, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stop-color="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stop-color="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorXv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stop-color="#f62121" stopOpacity={0.8} />
            <stop offset="95%" stop-color="#f62121" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorZv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stop-color="#03BB34" stopOpacity={0.8} />
            <stop offset="95%" stop-color="#03BB34" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={false} stroke="var(--areachart-bar-color)" strokeDasharray="1 1" />
        <XAxis
          fontFamily="Manrope"
          fontSize={'14px'}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          // interval={
          //   inHours ? 2 : data.length === 7 || type === 'remediation_exception' ? 1 : Math.round(data.length / 6)
          // }
          interval="preserveStartEnd"
          minTickGap={20}
          // allowDataOverflow={true}
        />
        <YAxis
          className="y-axis--resource--trend"
          // tickLine={false}
          // tick={false}
          fontFamily="Manrope"
          padding={{ right: 15, left: 0, bottom: 5 }}
          fontWeight={500}
          fontSize={'14px'}
          axisLine={false}
          tickLine={false}
          tick={{ fill: 'var(--label-color)' }}
          // scale={scale}
          // domain={domain}
        />
        {/* <CartesianGrid  /> */}
        <Tooltip content={<CustomLightTooltip />} />

        {type === 'remediation_exception' ? (
          <>
            <Area
              activeDot={false}
              // dot={{ stroke: '#03BB34', strokeWidth: 1, r: 3 }}
              strokeWidth={2}
              type="monotone"
              dataKey="remediation.total"
              stroke="#03BB34"
              fillOpacity={0.1}
              fill="url(#colorXv)"
            />
            <Area
              activeDot={false}
              // dot={{ stroke: '#A0AEC0', strokeWidth: 1, r: 3 }}
              strokeWidth={2}
              type="monotone"
              dataKey="exception.total"
              stroke="#A0AEC0"
              fillOpacity={0.1}
              fill="url(#colorZv)"
            />
          </>
        ) : type === 'overtime' ? (
          <Area
            activeDot={false}
            // dot={{ stroke: '#f62121', strokeWidth: 1, r: 3 }}
            strokeWidth={2}
            type="monotone"
            dataKey="average.average_score"
            stroke="#f62121"
            fillOpacity={0.1}
            fill="url(#colorXv)"
          />
        ) : type === 'security_score' ? (
          <Area
            activeDot={false}
            // dot={{ stroke: '#f62121', strokeWidth: 1, r: 3 }}
            strokeWidth={2}
            type="monotone"
            dataKey="security_score.security_score"
            stroke="#f62121"
            fillOpacity={0.1}
            fill="url(#colorXv)"
          />
        ) : (
          <>
            <Area
              activeDot={false}
              // dot={{ stroke: '#03BB34', strokeWidth: 1, r: 3 }}
              strokeWidth={2}
              type="monotone"
              dataKey="compliant_data.total_score"
              stroke="#03BB34"
              fillOpacity={0.1}
              fill="url(#colorZv)"
            />
            <Area
              activeDot={false}
              // dot={{ stroke: '#f62121', strokeWidth: 1, r: 3 }}
              strokeWidth={2}
              type="monotone"
              dataKey="non_compliant_data.total_score"
              stroke="#f62121"
              fillOpacity={0.1}
              fill="url(#colorXv)"
            />
          </>
        )}
        {data?.map((entry, index) => (
          <ReferenceLine key={index} x={entry.name} stroke="#CBD5E0" strokeWidth={1} />
        ))}
        <Legend formatter={renderColorfulLegendText} />
      </AChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
