import { useCallback, useMemo, useState } from 'react';
import HeaderBarNew from '@root/components/HeaderBarNew';
import CVE from './CVEs';
import { Box } from '@mui/material';
// import PageHeader from '@root/components/common/PageHeader';
import Breadcrumbs from '@root/components/v2/global/breadcrumbs';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageHeader from '@root/components/v2/layout/PageHeader';
import SBOM from './SBOM';
import Images from './Images';
import Workload from './workload';
import RiskSpotlightToggle from './RiskSpotlight';
// import { useTheme } from '@root/context/ThemeContext';

const BREADCRUMBS = [{ title: 'Kubernetes' }, { title: 'Vulnerabilities' }];

const K8Vulnerabilities = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false);
  // const { isDarkMode } = useTheme();



  const handleTabChange = useCallback(tab => {
    setActiveTab(tab);
  }, []);
  const content = useMemo(
    () => ({
      0: <CVE  isEnabled={isEnabled}/>,
      1: <Workload isEnabled={isEnabled}/>,
      2: <Images isEnabled={isEnabled}/>,
      3: <SBOM isEnabled={isEnabled}/>,
    }),
    [isEnabled],
  );
  return (
    <PageContainer>
      <PageHeader
        title={<Breadcrumbs breadcrumbs={BREADCRUMBS} />}
        breadcrumb={true}
        path={'/kubernetes-live/vulnerabilities'}></PageHeader>
      <HeaderBarNew
        marginBottom={'10px'}
        leftsideContent={
          <Box
            // className="border-bottom-tab"
            borderRadius={'5px'}
            // border={'1px solid var(--border-color-light)'}
            display="flex"
            // backgroundColor={'#ffffff'}
            gap="10px"
          
            padding={'4px'}
            marginTop={'10px'}
            // marginBottom={'5px'}
            marginLeft={'15px'}>
            <Box
              onClick={() => handleTabChange(0)}
              className={`HeaderBar__ResourceTab Left ${activeTab === 0 ? 'HeaderBar__ResourceTab--Selected' : ''}`}
            >
              CVEs
            </Box>
            <Box
              onClick={() => handleTabChange(1)}
              className={`HeaderBar__ResourceTab Right ${activeTab === 1 ? 'HeaderBar__ResourceTab--Selected' : ''}`}
            >
              Workloads
            </Box>
            <Box
              onClick={() => handleTabChange(2)}
              className={`HeaderBar__ResourceTab Right ${activeTab === 2 ? 'HeaderBar__ResourceTab--Selected' : ''}`}
            >
              Images
            </Box>
            <Box
              onClick={() => handleTabChange(3)}
              className={`HeaderBar__ResourceTab Right ${activeTab === 3 ? 'HeaderBar__ResourceTab--Selected' : ''}`}
           >
              SBOM
            </Box>
          </Box>
        }></HeaderBarNew>
        <RiskSpotlightToggle setIsEnabled={setIsEnabled} isEnabled={isEnabled} type={activeTab}/>
      {content[activeTab]}
    </PageContainer>
  );
};
export default K8Vulnerabilities;
