import commonTableColumns from '@root/components/table/commonTableColumns';
import { VulnerabilityCounts } from '../VulnerabilityCounts';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';

export const sbomColumns = [
  commonTableColumns.environment('account_id','cluster' ),
  {
    field: 'namespace',
    headerName: 'Namespace',
    width: 250,
  },
  {
    field: 'name',
    headerName: 'Workload',
    width: 250,
  },
  {
    field: 'kind',
    headerName: 'Kind',
    width: 250,
  },

  // {
  //   field: 'cluster',
  //   headerName: 'Cluster',
  //   width: 230,
  // },

  {
    field: 'containers',
    headerName: 'Containers',
    renderCell: parmas => {
      return parmas.row.containers.length > 0 && parmas.row.containers[0];
    },
    width: 230,
  },

  {
    field: 'pod',
    headerName: 'Pods',
    width: 250,
  },
  {
    field: 'vulnerabilities',
    headerName: 'Vulnerabilities',
    width: 250,
    renderCell: params => {
      return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts} />;
    },
  },
  {
    field: 'image_count',
    headerName: 'Image',
    renderCell: parmas => {
      return parmas.row?.image_count ?? '-';
    },
    width: 230,
  },
  {
    field: 'status',
    headerName: 'State',
    width: 200,
  },
  {
    field: 'last_scan',
    headerName: 'Last Scan',
    renderCell: parmas => {
      return DateFormatPipe(parmas.row.last_scan);
    },
    width: 230,
  },


];
