import { Box } from '@mui/material';
import { Clock, TrendingUp } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './securityscore.scss'; // Assume CSS is in a separate file
// import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import agent from '@root/agent';
import { useCloudProvider } from '@root/components/v2/content/CloudProviderBar/CloudProviderContext';
import ContentBox from '@root/components/v2/content/ContentBox';
import Breadcrumbs from '@root/components/v2/global/breadcrumbs';
import PageHeader from '@root/components/v2/layout/PageHeader';
import CustomAreaChart from '@root/containers/compliance/components/rechartContainers/areaChart';
import { useSelectedEnvironments } from '@root/hooks/environments';
import useTimeFilter from '@root/hooks/SelectTimeFilter';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useHistory } from 'react-router';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import { useTheme } from '@root/context/ThemeContext';
// import SecurityScoreComposition from './SecurityScoreCompostion';
const BREADCRUMBS = [{ title: 'Security Score' }];
const SecurityImprovementDashboard = () => {
  const { selectedEnvironments } = useSelectedEnvironments();
  const { cloudProvider } = useCloudProvider();
  const { timeFilter } = useTimeFilter();
  const history = useHistory();
  const [barColor, setBarColor] = useState('#FFA825');
  const { isDarkMode } = useTheme();
  const environments = useMemo(() => {
    if (selectedEnvironments.length && cloudProvider !== 'all') {
      return selectedEnvironments.filter(environment => environment.cloudProvider === cloudProvider);
    } else if (cloudProvider === 'all') {
      return selectedEnvironments.filter(
        environment => environment.cloudProvider === 'aws' || environment.cloudProvider === 'gcp',
      );
    }
    return selectedEnvironments;
  }, [selectedEnvironments, cloudProvider]);

  const { data: improvementPlan, isFetching: isFetchingImprovementPlan } = useQuery(
    ['improvement-plan', environments.map(e => e.id)],
    () =>
      agent.SecurityScore.improvement_plan({
        environments: environments.map(e => e.id),
      }),
  );

  const { data: composition, isFetching: isFetchingCompostion } = useQuery(
    ['composition', environments.map(e => e.id)],
    () =>
      agent.SecurityScore.composition({
        environments: environments.map(e => e.id),
      }),
  );
  const { data: trendData, isFetching: isFetchingTrend } = useQuery(
    ['trend', environments.map(e => e.id), timeFilter],
    () =>
      agent.SecurityScore.trend({
        environments: environments.map(e => e.id),
        range: timeFilter,
      }),
  );
  console.log(
    improvementPlan,
    isFetchingImprovementPlan,
    trendData,
    isFetchingImprovementPlan,
    isFetchingCompostion,
    'sfdlkjoi',
  );
  console.log(composition, 'djflslrji');
  // const [data] = useState({
  //   currentScore: 77,
  //   targetScore: 100,
  //   improvementOpportunities: [
  //     {
  //       category: 'Public Bucket Access',
  //       affectedResources: 6,
  //       currentImpact: -12.5,
  //       effortLevel: 'Medium',
  //       timeToRemediate: '2-3 days',
  //       potentialImprovement: 10.2,
  //       steps: ['Enable AWS S3 Block Public Access', 'Review bucket policies', 'Implement least privilege access'],
  //     },
  //     {
  //       category: 'IAM Role Permissions',
  //       affectedResources: 5,
  //       currentImpact: -8.3,
  //       effortLevel: 'High',
  //       timeToRemediate: '4-5 days',
  //       potentialImprovement: 7.5,
  //       steps: ['Review excessive permissions', 'Implement role boundaries', 'Enable AWS IAM Access Analyzer'],
  //     },
  //     {
  //       category: 'Security Group Rules',
  //       affectedResources: 4,
  //       currentImpact: -5.2,
  //       effortLevel: 'Low',
  //       timeToRemediate: '1 day',
  //       potentialImprovement: 4.8,
  //       steps: [
  //         'Remove overly permissive rules',
  //         'Implement security group policy',
  //         'Enable regular compliance checks',
  //       ],
  //     },
  //   ],
  //   quickWins: [
  //     {
  //       action: 'Enable S3 Block Public Access',
  //       impact: '+5.2%',
  //       effort: '15 mins',
  //       resources: 6,
  //     },
  //     {
  //       action: 'Remove unused IAM roles',
  //       impact: '+3.8%',
  //       effort: '30 mins',
  //       resources: 3,
  //     },
  //     {
  //       action: 'Update security group rules',
  //       impact: '+2.5%',
  //       effort: '45 mins',
  //       resources: 4,
  //     },
  //   ],
  //   attackPaths: [
  //     {
  //       path: 'Public S3 → IAM Role → Lambda → RDS',
  //       impact: -2.5,
  //       affectedResources: 3,
  //     },
  //     {
  //       path: 'Public EC2 → IAM → Sensitive Data',
  //       impact: -2.0,
  //       affectedResources: 2,
  //     },
  //   ],
  // });
  // const trendData = [
  //   { month: '2024-01', score: 65 },
  //   { month: '2024-02', score: 67 },
  //   { month: '2024-03', score: 67 },
  // ];

  const scoreBreakdown = useMemo(() => {
    if (!composition) return [];
    return [
      { label: 'Base Score', value: 100, type: 'neutral' },
      // { label: 'Public Exposure', value: 0, type: 'negative' },
      // { label: 'Misconfigurations', value: 0, type: 'negative' },
      {
        label: 'Attack Paths',
        value: composition?.data?.breakup?.active_attack_paths?.final_deduction,
        type: 'negative',
      },
      {
        label: 'Vulnerability',
        value: composition?.data?.breakup?.active_vulnerability?.final_deduction,
        type: 'negative',
      },
      {
        label: 'Data Security',
        value: composition?.data?.breakup?.active_data_security?.final_deduction,
        type: 'negative',
      },
      {
        label: 'Anomaly Threat',
        value: composition?.data?.breakup?.active_anomaly_threat?.final_deduction,
        type: 'negative',
      },
      {
        label: 'Misconfiguration',
        value: composition?.data?.breakup?.misconfiguration?.final_deduction,
        type: 'negative',
      },
      // {
      //   label: 'Data Security',
      //   value: composition?.data?.breakup?.active_data_security?.final_deduction,
      //   type: 'negative',
      // },
      // { label: 'Automated', value: 2.8, type: 'positive' },
    ];
  }, [composition]);

  const overTimeData = useMemo(() => {
    if (isFetchingTrend) return [];

    let data = trendData?.data.map(date => {
      return {
        name:
          timeFilter === '24h' ? moment(date.time_bucket).format('HH:mm') : moment(date.time_bucket).format('D MMM'),
        security_score: {
          security_score: date.score?.toFixed(2),
        },
      };
    });
    return data;
  }, [isFetchingTrend, timeFilter, trendData?.data]);

  const handleRedirection = useCallback(
    label => {
      if (label === 'Attack Paths') {
        history.push(
          buildBreadcrumbUrl({
            path: `/attack-path-analysis`,
            breadcrumbs: [{ title: 'Security Score', path: '/security-score' }, { title: 'Attack Path Analysis' }],
          }),
        );
      } else if (label === 'Vulnerability') {
        history.push(
          buildBreadcrumbUrl({
            path: `/vulnerabilities`,
            breadcrumbs: [{ title: 'Security Score', path: '/security-score' }, { title: 'Vulnerabilities' }],
          }),
        );
      } else if (label === 'Data Security') {
        history.push(
          buildBreadcrumbUrl({
            path: `/data-security`,
            breadcrumbs: [{ title: 'Security Score', path: '/security-score' }, { title: 'Data Security' }],
          }),
        );
      } else if (label === 'Anomaly Threat') {
        history.push(
          buildBreadcrumbUrl({
            path: `/events/all?tab=Threat&from=security_score`,
            breadcrumbs: [{ title: 'Security Score', path: '/security-score' }, { title: 'All Events' }],
          }),
        );
      } else if (label === 'Misconfiguration') {
        history.push(
          buildBreadcrumbUrl({
            path: `/risk/risk-table?from=security_score`,
            breadcrumbs: [{ title: 'Security Score', path: '/security-score' }, { title: 'Risk' }],
          }),
        );
      }
    },
    [history],
  );

  useEffect(() => {
    /*

      High = Green | Score between 90-100  #03CB38
      Medium = Orange |  Score between 50-89 #FFA825
      Low = Light Red | Score between 31-49 #EB6C6C
      Critical = Dark Red | Score 0 - 30 #CD0404

    */
    const score = improvementPlan?.data?.current_score ?? 0;

    if (score >= 90) setBarColor('#03CB38');
    else if (score >= 50 && score < 90) setBarColor('#FFA825');
    else if (score >= 31 && score < 50) setBarColor('#EB6C6C');
    else if (score >= 0 && score < 31) setBarColor('#CD0404');
  }, [improvementPlan?.data?.current_score]);

  const chartStyles = {
    text: {
      fill: isDarkMode ? '#f7fafc' : '#1a202c',
    },
    grid: {
      stroke: isDarkMode ? '#4a5568' : '#E5E7EB',
    },
  };

  return (
    <div className="security-score-dashboard">
      {' '}
      <PageHeader title={<Breadcrumbs breadcrumbs={BREADCRUMBS} />}></PageHeader>
      <div className="SecurityScore__Dashboard__Container">
        <ContentBox
          title="Security Score Improvement Roadmap"
          containerClassName={'border-box'}
          paddingMode={'content'}
          titleSize="medium">
          <div className="card-content" style={{ marginBottom: '25px' }}>
            <div className="metrics-grid">
              <div className="score-metric">
                <div className="score-circle">
                  <svg className="score-svg" viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                      className="score-background"
                    />
                    <path
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                      className="score-progress"
                      style={{ stroke: barColor }}
                      strokeDasharray={`${improvementPlan?.data?.current_score ?? 0}, 100`}
                    />
                  </svg>
                  <div className="score-value">{parseInt(improvementPlan?.data?.current_score ?? 0)}%</div>
                </div>
                <div className="score-details">
                  {/* <div className="score-label">Current Score</div> */}
                  <div className="score-target">Target: 100%</div>
                </div>
              </div>

              <div className="metric-box quick-wins">
                <div className="metric-header">
                  <div>
                    <div className="metric-label">Quick Wins Impact</div>
                    <div className="metric-value">+ {improvementPlan?.data?.quick_wins?.impact ?  Math.abs(improvementPlan?.data?.quick_wins?.impact): 0}</div>
                  </div>
                  <Clock className="metric-icon" />
                </div>
                <div className="metric-subtitle">
                  Achievable in {improvementPlan?.data?.quick_wins?.achievable_in ?? 0}
                </div>
              </div>

              <div className="metric-box total-improvement">
                <div className="metric-header">
                  <div>
                    <div className="metric-label">Total Improvement</div>
                    <div className="metric-value">{improvementPlan?.data?.total_improvement?.impact ?? 0}</div>
                  </div>
                  <TrendingUp className="metric-icon" />
                </div>
                <div className="metric-subtitle">Complete remediation</div>
              </div>
            </div>
          </div>
        </ContentBox>
        {/* {composition && (
          <ContentBox title="Security Score Composition">
            <SecurityScoreComposition data={composition?.data} />
          </ContentBox>
        )} */}

        {/* <div className="card">
          <div className="card-header">
            <p className="card-title">Security Score Improvement Roadmap</p>
          </div>
          <div className="card-content" style={{ marginBottom: '25px' }}>
            <div className="metrics-grid">
              <div className="score-metric">
                <div className="score-circle">
                  <svg className="score-svg" viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                      className="score-background"
                    />
                    <path
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                      className="score-progress"
                      strokeDasharray={`${data.currentScore}, 100`}
                    />
                  </svg>
                  <div className="score-value">{data.currentScore}%</div>
                </div>
                <div className="score-details">
                  <div className="score-label">Current Score</div>
                  <div className="score-target">Target: {data.targetScore}%</div>
                </div>
              </div>

              <div className="metric-box quick-wins">
                <div className="metric-header">
                  <div>
                    <div className="metric-label">Quick Wins Impact</div>
                    <div className="metric-value">+11.5%</div>
                  </div>
                  <Clock className="metric-icon" />
                </div>
                <div className="metric-subtitle">Achievable in 2 hours</div>
              </div>

              <div className="metric-box total-improvement">
                <div className="metric-header">
                  <div>
                    <div className="metric-label">Total Improvement</div>
                    <div className="metric-value">+23%</div>
                  </div>
                  <TrendingUp className="metric-icon" />
                </div>
                <div className="metric-subtitle">Complete remediation</div>
              </div>
            </div>
          </div>
        </div> */}
        <Box display={'grid'} sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }} gap={'15px'}>
          {/* <ContentBox
            title="Quick Wins (2-Hour Impact)"
            containerClassName={'border-box'}
            paddingMode={'content'}
            style={{ height: '460px' }}
            titleSize="medium">
            <div
              className="card-content CustomScrollbar"
              style={{ height: '415px', overflow: 'auto', padding: '15px' }}>
              <div className="quick-wins-list">
                {data.quickWins.map((win, idx) => (
                  <div key={idx} className="quick-win-item">
                    <div className="quick-win-content">
                      <div className="quick-win-info">
                        <div className="quick-win-title">
                          <Shield className="win-icon" />
                          <p className="card-sub-heading">{win.action}</p>
                        </div>
                        <p className="quick-win-details">
                          Affects {win.resources} resources • Effort: {win.effort}
                        </p>
                      </div>
                      <div className="quick-win-impact">
                        <div className="impact-value">{win.impact}</div>
                        <ChevronRight className="chevron-icon" />
                      </div>
                    </div>
                    <div className="progress-bar">
                      <div className="progress-fill" style={{ width: `${parseInt(win.impact)}0%` }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ContentBox> */}

          {/* <div className="card ">
            <div className="card-header">
              <p className="card-title">Quick Wins (2-Hour Impact)</p>
            </div>
            <div className="card-content CustomScrollbar" style={{ height: '400px', overflow: 'auto' }}>
              <div className="quick-wins-list">
                {data.quickWins.map((win, idx) => (
                  <div key={idx} className="quick-win-item">
                    <div className="quick-win-content">
                      <div className="quick-win-info">
                        <div className="quick-win-title">
                          <Shield className="win-icon" />
                          <p className="card-sub-heading">{win.action}</p>
                        </div>
                        <p className="quick-win-details">
                          Affects {win.resources} resources • Effort: {win.effort}
                        </p>
                      </div>
                      <div className="quick-win-impact">
                        <div className="impact-value">{win.impact}</div>
                        <ChevronRight className="chevron-icon" />
                      </div>
                    </div>
                    <div className="progress-bar">
                      <div className="progress-fill" style={{ width: `${parseInt(win.impact)}0%` }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* <ContentBox
            title="Comprehensive Improvement Plan"
            containerClassName={'border-box'}
            paddingMode={'content'}
            style={{ height: '460px' }}
            titleSize="medium">
            <div
              className="card-content CustomScrollbar"
              style={{ height: '410px', overflow: 'auto', padding: '15px' }}>
              <div className="opportunities-list">
                {data.improvementOpportunities.map((opportunity, idx) => (
                  <div key={idx} className="opportunity-item">
                    <div className="opportunity-header">
                      <div className="opportunity-title">
                        <AlertCircle className={`alert-icon ${opportunity.effortLevel.toLowerCase()}`} />
                        <p className="card-sub-heading">{opportunity.category}</p>
                      </div>
                      <p className="opportunity-meta">
                        {opportunity.affectedResources} resources affected • Effort: {opportunity.effortLevel}
                      </p>
                    </div>
                    <div className="opportunity-metrics">
                      <div className="improvement-value">+{opportunity.potentialImprovement}%</div>
                      <div className="time-estimate">Time: {opportunity.timeToRemediate}</div>
                    </div>
                    <div className="remediation-steps">
                      <h4>Remediation Steps:</h4>
                      <ol>
                        {opportunity.steps.map((step, stepIdx) => (
                          <li key={stepIdx}>{step}</li>
                        ))}
                      </ol>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ContentBox>
          <ContentBox
            title="Attack Paths (2)"
            containerClassName={'border-box'}
            paddingMode={'content'}
            style={{ height: '460px' }}
            titleSize="medium">
            <div
              className="card-content CustomScrollbar"
              style={{ height: '410px', overflow: 'auto', padding: '15px' }}>
              <div className="attack-paths-list">
                {data.attackPaths.map((path, idx) => (
                  <div key={idx} className="attack-path-item">
                    <div className="attack-path-content">
                      <div className="attack-path-info">
                        <div className="attack-path-title">{path.path}</div>
                        <div className="attack-path-resources">{path.affectedResources} affected resources</div>
                      </div>
                      <div className="attack-path-impact">{path.impact}%</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ContentBox> */}
          {/* <div className="card">
            <div className="card-header">
              <p className="card-title">Comprehensive Improvement Plan</p>
            </div>
            <div
              className="card-content CustomScrollbar"
              style={{ height: '400px', overflow: 'auto', paddingBottom: '25px' }}>
              <div className="opportunities-list">
                {data.improvementOpportunities.map((opportunity, idx) => (
                  <div key={idx} className="opportunity-item">
                    <div className="opportunity-header">
                      <div className="opportunity-title">
                        <AlertCircle className={`alert-icon ${opportunity.effortLevel.toLowerCase()}`} />
                        <p className="card-sub-heading">{opportunity.category}</p>
                      </div>
                      <p className="opportunity-meta">
                        {opportunity.affectedResources} resources affected • Effort: {opportunity.effortLevel}
                      </p>
                    </div>
                    <div className="opportunity-metrics">
                      <div className="improvement-value">+{opportunity.potentialImprovement}%</div>
                      <div className="time-estimate">Time: {opportunity.timeToRemediate}</div>
                    </div>
                    <div className="remediation-steps">
                      <h4>Remediation Steps:</h4>
                      <ol>
                        {opportunity.steps.map((step, stepIdx) => (
                          <li key={stepIdx}>{step}</li>
                        ))}
                      </ol>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* <div className="card">
            <div className="card-header">
              <p className="card-title">Attack Paths (2)</p>
            </div>
            <div className="card-content" style={{ height: '400px', overflow: 'auto' }}>
              <div className="attack-paths-list">
                {data.attackPaths.map((path, idx) => (
                  <div key={idx} className="attack-path-item">
                    <div className="attack-path-content">
                      <div className="attack-path-info">
                        <div className="attack-path-title">{path.path}</div>
                        <div className="attack-path-resources">{path.affectedResources} affected resources</div>
                      </div>
                      <div className="attack-path-impact">{path.impact}%</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </Box>
        <ContentBox>
          <div className="score-card">
            <div className="score-sections" style={{ padding: '15px' }}>
              {/* Score Composition */}
              <div className="composition-section">
                <p className="card-title">Score Composition</p>
                <div className="score-list">
                  {scoreBreakdown.map((item, index) => (
                    <div key={index} className="score-item">
                      <span className="score-label">{item.label}</span>
                      <span
                        className={`score-value-composition ${item.type}`}
                        onClick={() => handleRedirection(item.label)}>
                        {item.type !== 'neutral' && (item.type === 'negative' ? '-' : '')}
                        {item.value?.toFixed(2)}
                      </span>
                    </div>
                  ))}
                  <div className="final-score">
                    <span className="score-label">Final Score</span>
                    <span className="score-value-composition">
                      {composition?.data?.security_score?.toFixed(2) ?? 0}
                    </span>
                  </div>
                </div>
              </div>

              {/* Score Trend */}
              <div className="trend-section">
                <p className="card-title">Score Trend</p>
                <div className="trend-chart">
                  {/* <ResponsiveContainer width="100%" height={250}>
                    <LineChart data={trendData} margin={{ top: 20, right: 40, left: -10, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" vertical={false} />
                      <XAxis
                        dataKey="month"
                        tickFormatter={value => {
                          const date = new Date(value);
                          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                        }}
                        stroke="#6B7280"
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                        axisLine={false}
                      />
                      <YAxis
                        domain={[0, 100]}
                        ticks={[0, 25, 50, 75, 100]}
                        stroke="#6B7280"
                        tick={{ fill: '#6B7280', fontSize: 12 }}
                        axisLine={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="score"
                        stroke="#3B82F6"
                        strokeWidth={2}
                        dot={{ fill: '#3B82F6', r: 4 }}
                      />
                    </LineChart>
                  </ResponsiveContainer> */}
                  <CustomAreaChart
                    data={overTimeData}
                    type={'security_score'}
                    chartStyles={chartStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};

export default SecurityImprovementDashboard;
