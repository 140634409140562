import NoData from '@root/components/common/NoData';
import MuiTable, { DEFAULT_PAGE_SIZE } from '@root/components/table/table';
// import { VulnerabilityCounts } from './VulnerabilityCounts';
import useEnhancedQuery from '@root/query/useEnhancedQuery';
import useDebouncedValue from '@root/hooks/useDebouncedValue';
import agent from '@root/agent';
import useSortData from '@root/hooks/useSortData';
import HeaderBarNew from '@root/components/HeaderBarNew';
import { useCallback, useMemo, useState } from 'react';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { sbomColumns } from './sbomColumns';
import TableSkeleton from '@root/components/table/skeleton';
import SideOverlay from '../Sideoverlay';
import FilterComponent from '@root/components/FilterNew';
import { capitalizeFirstLetter } from '@root/utilities/string';
const SBOM = ({ isEnabled }) => {
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // mui counts pages from 0
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const debouncedSearchString = useDebouncedValue(searchString, 750);
  const { sortData, handleSortModelChange } = useSortData();
  const { selectedEnvironments } = useSelectedEnvironments();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  // const [isDefaultValue, setIsDefaultValue] = useState(false);
  const { data: sbomData, isFetching: isFetchingSbomData } = useEnhancedQuery(
    [
      'get_sbom_table_data',
      debouncedSearchString,
      selectedEnvironments.map(item => item.id),
      // selectedEnvironmentTags,
      currentPage,
      pageSize,
      sortData,
      isEnabled,
      selectedFilters,
    ],
    () =>
      agent.Kubernetes.get_sbom_table_data({
        // scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),

        sort: sortData,

        clusters: selectedFilters?.clusters || [],
        risk_spotlight: isEnabled,
        search_query: debouncedSearchString ? debouncedSearchString : '',
        page_number: currentPage,
        rows_per_page: pageSize,
        type: selectedFilters?.type || [],
      }),
  );
  const { data: filterData, isFetching: isFetchingFilterData } = useEnhancedQuery(
    ['get_sbom_filter_data', selectedEnvironments.map(item => item.id)],
    () =>
      agent.Kubernetes.get_sbom_filter_data({
        // scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),
        clusters: [],
      }),
  );
  const propertyValues = useMemo(() => {
    if (isFetchingFilterData) return [];
    return Object.fromEntries(
      Object.entries(filterData?.data)
        ?.filter(property => property[1].length > 0 && property[0] != 'cvss')
        ?.map(([key, values]) => [
          // Transform key
          key
            ?.split('_')
            ?.map(word => capitalizeFirstLetter(word))
            ?.join(' '),

          // Transform array values
          values.map(value => value),
        ]),
    );
  }, [isFetchingFilterData, filterData?.data]);
  const _tableData = useMemo(() => {
    if (isFetchingSbomData || !sbomData?.data) return [];
    return sbomData?.data?.map((v, i) => ({ ...v, id: i }));
  }, [isFetchingSbomData, sbomData?.data]);

  const handleRowClick = useCallback(r => {
    setSelectedRow(r.row);
    setShowDetailsPanel(true);
  }, []);
  const handleCloseDetailsPanel = useCallback(() => {
    setShowDetailsPanel(false);
    setSelectedRow(null);
  }, []);

  return (
    <div style={{ margin: '15px' }}>
      <HeaderBarNew
        // marginTop={'5px'}
        marginBottom={'13px'}
        title={''}
        searchBar
        searchBarValue={searchString}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        setSearchTerm={setSearchString}
        // handleExportData={handleExportData}
        exportButton={true}
        searchBarOnChange={e => setSearchString(e.target.value)}
        // disableResourceExportButton={disableAllTableExportButton}
        // isFilterDataFetching={isFilterDataFetching}
        // isFilterDisabled={isFilterDisabled}
      />{' '}
      <FilterComponent
        openFilter={openFilter}
        option={propertyValues}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        defaultValue={{}}
      />
      {isFetchingSbomData ? (
        <div className=" card" style={{ height: '85vh', width: '100%' }}>
          <TableSkeleton />
        </div>
      ) : (
        <MuiTable
          columns={sbomColumns}
          paginationMode={'server'}
          rows={_tableData}
          components={{
            NoRowsOverlay: () => <NoData />,
          }}
          sortingMode="server"
          handleSortModelChange={handleSortModelChange}
          onPageChange={setCurrentPage}
          total_size={sbomData?.pagination?.total}
          pageSize={pageSize}
          page={currentPage}
          onPageSizeChange={setPageSize}
          noRowsMessage={'No Data Found'}
          rowHeight={49.9}
          onRowClick={handleRowClick}
          isDynamicHeight={true}
        />
      )}
      {showDetailsPanel && (
        <SideOverlay
          key={showDetailsPanel.toString()}
          onClose={handleCloseDetailsPanel}
          selectedRow={selectedRow}
          type={'sbom'}
        />
      )}
    </div>
  );
};
export default SBOM;
