import { Box, Grid, Skeleton } from '@mui/material';
import agent from '@root/agent';
// import NoData from '@root/components/common/NoData';
// import agent from '@root/agent';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// import agent from '@root/agent';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
// import CloudProviderBar from '@root/components/v2/content/CloudProviderBar';
import { useCloudProvider } from '@root/components/v2/content/CloudProviderBar/CloudProviderContext';
import ContentBox from '@root/components/v2/content/ContentBox';
// import Breadcrumbs from '@root/components/v2/global/breadcrumbs';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import { useSelectedEnvironments } from '@root/hooks/environments';
// import { useSelectedEnvironments } from '@root/hooks/environments';
// import PageHeader from '@root/components/v2/layout/PageHeader';
// import Select from '@root/components/v2/ui-elements/Select';
// import { useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import useTimeFilter from '@root/hooks/SelectTimeFilter';

import HealthScoreBreakdown from '@root/pages/inventory1.3/Components/HealthScoreBreakdown';

import HeaderBarNew from '@root/components/HeaderBarNew';
import useEnhancedQuery from '@root/query/useEnhancedQuery';
// import _ from 'lodash';
// import useEnhancedQuery from '@root/query/useEnhancedQuery';
// import useEnhancedQuery from '@root/query/useEnhancedQuery';
// import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import TableSkeleton from '@root/components/table/skeleton';
import useDebouncedValue from '@root/hooks/useDebouncedValue';
import useSortData from '@root/hooks/useSortData';
import MuiTable, { DEFAULT_PAGE_SIZE } from '@root/components/table/table';
import { k8columns } from './k8Columns';
import SideOverlay from '../Sideoverlay';
import { capitalizeFirstLetter } from '@root/utilities/string';
import FilterComponent from '@root/components/FilterNew';
dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE = dayjs.tz.guess();
// import { runtimeColumns, runtimeRows } from '../compliance/sample';
const renderLegend = props => {
  const { payload } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px',
        marginTop: '10px',
        flexDirection: 'row-reverse',
      }}>
      {payload &&
        payload?.map((entry, index) => (
          <span key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: entry.color,
                marginRight: '15px',
                borderRadius: '50%',
              }}></div>
            <div className="legend-text">{entry.value}</div>
          </span>
        ))}
    </div>
  );
};
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { severity, date } = payload[0].payload;
    return (
      <div className="custom_tooltip" style={{ outline: 0 }}>
        <p className="custom_tooltip_count m-0 p-0">{date}</p>
        <div className="count_container">
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #CD0404', paddingLeft: '4px', borderRadius: '1px' }}>
            Critical: <span className="custom_tooltip_count"> {severity.CRITICAL ? severity.CRITICAL : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #EB6C6C', paddingLeft: '4px', borderRadius: '1px' }}>
            High: <span className="custom_tooltip_count"> {severity.HIGH ? severity.HIGH : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #FFA825', paddingLeft: '4px', borderRadius: '1px' }}>
            Medium: <span className="custom_tooltip_count"> {severity.MEDIUM ? severity.MEDIUM : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #E8DF07', paddingLeft: '4px', borderRadius: '1px' }}>
            Low: <span className="custom_tooltip_count"> {severity.LOW ? severity.LOW : '0'}</span>
          </p>
        </div>
      </div>
    );
  }
  return null;
};
const CVE = ({isEnabled}) => {
  const { cloudProvider } = useCloudProvider();
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebouncedValue(searchString, 750);
  const [currentPage, setCurrentPage] = useState(1); // mui counts pages from 0
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { sortData, handleSortModelChange } = useSortData();
  const { selectedEnvironments } = useSelectedEnvironments();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  //   const allEnvironments = useEnvironments();
  const { timeFilter } = useTimeFilter();

  const { data: severityData, isFetching: isFetchingSeverityData } = useEnhancedQuery(
    ['get_vulnerabilities_severity', cloudProvider, selectedEnvironments.map(item => item.id)],
    () =>
      agent.Kubernetes.get_vulnerabilities_severity({
        environments: selectedEnvironments.map(item => item.id),
        clusters: [],
      }),
  );
  const { data: trendData, isFetching: isFetchingTrendData } = useEnhancedQuery(
    ['get_vulnerabilities_trend', cloudProvider, selectedEnvironments.map(item => item.id), timeFilter, TIMEZONE],
    () =>
      agent.Kubernetes.get_vulnerabilities_trend({
        environments: selectedEnvironments.map(item => item.id),
        clusters: [],
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const { data: allDataK8, isFetching: isFetchingAllDataK8 } = useEnhancedQuery(
    [
      'get_vulnerability_datak8',
      debouncedSearchString,
      selectedEnvironments.map(item => item.id),
      // selectedEnvironmentTags,
      currentPage,
      pageSize,
      cloudProvider,
      sortData,
      isEnabled,
      selectedFilters,

    ],
    () =>
      agent.Vulnerabilites.get_vulnerability_datak8({
        // scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),

        sort: sortData,

        clusters: selectedFilters?.clusters || [],
        severity: selectedFilters?.severity || [],
        fixable: selectedFilters?.fixable || [],
        exploitable: selectedFilters?.exploitable || [],
        search_query: debouncedSearchString ? debouncedSearchString : '',
        page_number: currentPage,
        rows_per_page: pageSize,
        risk_spotlight: isEnabled,
        cve_id: selectedFilters?.cve_id || [],
        
      }),
  );
  const { data: filterData, isFetching: isFetchingFilterData } = useEnhancedQuery(
    ['get_cve_filter_data', selectedEnvironments.map(item => item.id)],
    () =>
      agent.Kubernetes.get_cve_filter_data({
        // scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),
        clusters: [],
      }),
    );
    const propertyValues = useMemo(() => {
      if (isFetchingFilterData) return [];
      return Object.fromEntries(
        Object.entries(filterData?.data)
          ?.filter(property => property[1].length > 0 && property[0] != 'cvss')
          ?.map(([key, values]) => [
            // Transform key
            key
              ?.split('_')
              ?.map(word => word ==='id'? 'ID':  word ==='cve'? 'CVE' : capitalizeFirstLetter(word))
              ?.join(' '),
  
            // Transform array values
            values.map(value => value),
          ]),
      );
    }, [isFetchingFilterData, filterData?.data]);
  const _severityData = useMemo(() => {
    if (isFetchingSeverityData) return [];
    const breakupData = severityData?.data;
    return {
      data: {
        total_risk: breakupData.total,
        risk_breakup: [
          {
            name: 'Critical',
            value: breakupData?.critical,
          },
          {
            name: 'High',
            value: breakupData?.high,
          },
          {
            name: 'Medium',
            value: breakupData?.medium,
          },
          {
            name: 'Low',
            value: breakupData?.low,
          },
        ],
      },
    };
  }, [isFetchingSeverityData, severityData?.data]);

  const ChartData = useMemo(() => {
    if (isFetchingTrendData) return [];
    return {
      data: trendData?.data?.map(item => ({
        date:
          timeFilter === '24h'
            ? moment(item.date ? item.date : moment()).format('HH:mm')
            : moment(item.date ? item.date : moment()).format('D MMM'),
        severity: {
          CRITICAL: item.critical,
          HIGH: item.high,
          MEDIUM: item.medium,
          LOW: item.low,
        },
      })),
    };
  }, [isFetchingTrendData, timeFilter, trendData?.data]);
  const handlePageChange = useCallback(page => {
    setCurrentPage(page);
  }, []);

  const handlePageSizeChange = useCallback(_pageSize => {
    setCurrentPage(1);
    setPageSize(_pageSize);
  }, []);
  const handleRowClick = useCallback(r => {
    setSelectedRow(r.row);
    setShowDetailsPanel(true);
  }, []);
  const handleCloseDetailsPanel = useCallback(() => {
    setShowDetailsPanel(false);
    setSelectedRow(null);
  }, []);
  return (
    <PageContainer>
      <PageContent>
        <Grid container direction={'row'} spacing={2}>
          <Grid item md={4}>
            <HealthScoreBreakdown
              title={'Vulnerabilities By Severity'}
              cloudProvider={cloudProvider}
              health_score_breakdown={_severityData}
              isFetchingHealthScoreBreakdown={isFetchingSeverityData && _severityData}
              count_msg={'Vulnerabilities'}
              infoMsg={''}
            />
          </Grid>
          <Grid item md={8}>
            <ContentBox
              titleSize="medium"
              title={'Vulnerabilities Over Time'}
              titleContainerStyle={{
                // border: "0px",
                fontSize: '16px !important',
                color: 'var(--keyinsight-text-color)',
                // background: '#F7FAFC',
                // backgroundColor: 'var(--keyinsight-box-header-background)',
              }}
              paddingMode="content"
              containerClassName={'border-box-no-pad'}
              height="406px">
              {isFetchingTrendData ? (
                <Box display={'flex'} flexDirection={'column'} gap={'15px'} padding={'15px'}>
                  <Skeleton variant="rectangular" height={'300px'} sx={{ borderRadius: '8px' }} />
                  <Box display={'flex'} gap={'15px'} alignItems={'center'} justifyContent={'center'}>
                    <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                    <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                    <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                    <Skeleton variant="rectangular" height={'20px'} width={'100px'} sx={{ borderRadius: '8px' }} />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    padding: '15px',
                    height: '100%',
                  }}>
                  <ResponsiveContainer height={338} width={'104%'}>
                    <AreaChart width={500} data={ChartData?.data} dot={{ stroke: '#0F2D97', strokeWidth: 1.5, r: 8 }}>
                      <defs>
                        <linearGradient id="colorLow" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#E8DF07" stopOpacity={1} />
                          <stop offset="95%" stopColor="#E8DF07" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorMedium" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#FFA825" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#FFA825" stopOpacity={0.2} />
                        </linearGradient>
                        <linearGradient id="colorHigh" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#EB6C6C" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#EB6C6C" stopOpacity={0.2} />
                        </linearGradient>
                        <linearGradient id="colorCritical" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#CD0404" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#CD0404" stopOpacity={0.2} />
                        </linearGradient>
                      </defs>

                      <XAxis dataKey="date" axisLine={false} tickLine={false} />
                      <YAxis axisLine={false} tickLine={false} padding={{ right: 15, left: 0, bottom: 5 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <Area
                        type="monotone"
                        name="Low"
                        dataKey="severity.LOW"
                        stroke="#E8DF07"
                        stackId="1"
                        fill="url(#colorLow)"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <Area
                        type="monotone"
                        name="Medium"
                        dataKey="severity.MEDIUM"
                        stroke="#FFA825"
                        stackId="1"
                        fill="url(#colorMedium)"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <Area
                        type="monotone"
                        name="High"
                        dataKey="severity.HIGH"
                        stroke="#EB6C6C"
                        stackId="1"
                        fill="url(#colorHigh)"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <Area
                        type="monotone"
                        name="Critical"
                        dataKey="severity.CRITICAL"
                        stroke="#CD0404"
                        stackId="1"
                        fill="url(#colorCritical)"
                        strokeWidth="1.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <Legend content={renderLegend} />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              )}
            </ContentBox>
          </Grid>
        </Grid>
        <HeaderBarNew
          marginTop={'15px'}
          marginBottom={'13px'}
          title={''}
          searchBar
          setOpenFilter={setOpenFilter}
          openFilter={openFilter}
          searchBarValue={searchString}
          setSearchTerm={setSearchString}
          exportButton={true}
          searchBarOnChange={e => setSearchString(e.target.value)}
        />
        <FilterComponent
        openFilter={openFilter}
        option={propertyValues}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        defaultValue={{}}
      />
        {isFetchingAllDataK8 ? (
          <div className=" card" style={{ height: '85vh', width: '100%' }}>
            <TableSkeleton />
          </div>
        ) : (
          <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
            {' '}
            <MuiTable
              columns={k8columns()}
              rows={
                allDataK8?.data?.map((obj, index) => {
                  return { ...obj, id: index + 1 };
                }) || []
              }
              onRowClick={handleRowClick}
              paginationMode="server"
              sortingMode="server"
              total_size={allDataK8?.pagination?.total}
              handleSortModelChange={handleSortModelChange}
              // LoaderState={isFetchingNextPage}
              page={currentPage}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              rowHeight={49.9}
              // isDynamicHeight={true}
              tableHeight={'637px'}
            />
          </div>
        )}
          {showDetailsPanel  && (
          <SideOverlay
            key={showDetailsPanel.toString()}
            onClose={handleCloseDetailsPanel}
            selectedRow={selectedRow}
            type={'cves'}
        
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

export default CVE;
