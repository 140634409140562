import {
  TABLE_SORT_TRIGGERED,
  SET_TIME_FILTER,
  SET_SELECTED_RESOURCES,
  SEND_RESOURCES,
  SET_TIME_RANGE,
  SET_RANGE_VALUE,
} from '@root/constants/actionTypes';
import { store } from '@root/store';
// import { removeLastScan } from "../remediation/action"

export const triggerSort = (dispatch, payload) => {
  dispatch({
    type: TABLE_SORT_TRIGGERED,
    payload: payload,
  });
};

export const triggerSortAction = (payload = {}) => {
  triggerSort(store.dispatch, payload);
};

export const setTimeFilter = filter => ({
  type: SET_TIME_FILTER,
  payload: filter,
});
// Action creator for setting time range
export const setTimeRange = timeRange => ({
  type: SET_TIME_RANGE,
  payload: timeRange,
});
export const setRangeValue = rangeValue => ({
  type: SET_RANGE_VALUE,
  payload: rangeValue,
});
export const setSelectedResources = selectedResources => {
  return {
    type: SET_SELECTED_RESOURCES,
    selectedResources,
  };
};
// rules

export const sendResources = (resourceIds, resourceTypes) => ({
  type: SEND_RESOURCES,
  payload: { resourceIds, resourceTypes },
});

// export const removeLastScannedData = (resourceID, ruleName) => {
// 	console.log('Remove Called')
// 	let newScanned =  store.getState().remediation.scanned.filter(
// 		(e) => e["resource_id"] == resourceID && e["rule_name"] == ruleName
// 	);
// 	if(newScanned.length > 0)
// 	store.dispatch({ type: REMEDIATION_REMOVE_LAST_DATA, payload: newScanned[0]})
// }

// export const updateLastScannedData = (resourceID, ruleName) => {
// 	console.log('Remove Called')
// 	let newScanned =  store.getState().remediation.scanned.filter(
// 		(e) => e["resource_id"] == resourceID && e["rule_name"] == ruleName
// 	);
// 	if(newScanned.length > 0) {
// 		newScanned[0]["status"] = "done"
// 		store.dispatch({ type: REMEDIATION_UPDATE_LAST_DATA, payload: newScanned[0]})
// 	}
// }
