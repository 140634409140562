import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import MultiReactSelect from "@root/components/ui/multiReactSelect";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";

// import {
//   KeyboardDoubleArrowDown,
//   KeyboardDoubleArrowUp,
// } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import agent from "@root/agent";
import InfoBox from "@root/components/InfoBox";
import { SubmitButton } from "@root/components/v2/ui-elements/SubmitButton";
import { axiosPost } from "@root/services/axios/axiosPost";
import { isEmpty } from "lodash";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SelectedRegionsModal from "../components/SelectedRegionsModal";
import SelectedResourcesModal from "../components/SelectedResourcesModal";
import AwsRegions from "./aws-regions.json";
import GcpRegions from "./gcp-region.json";
import { GetCloudProvider } from "@root/pipes/getEnvironmentDetails";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fafafa",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.85rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  arrow: {
    color: "#fafafa",
  },
}))(Tooltip);

const BpIcon = styled("span")(() => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow: "inset 0px 0px 0px 2px #888888",

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  // 'input:hover ~ &': {
  //   backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  // },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  // },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#194BFB",
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 30%,transparent 32%)",
    content: '""',
  },
});

const customSelect = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid #CBD5E0;",
    borderRadius: "5px",
    padding: "4px",
    boxShadow: "0px 8px 18px 0px rgba(93, 106, 131, 0.05);",

    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "5px",
    padding: "10px",

    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: "#767980",
    background: state.isFocused ? "#EDF2F7" : "#FFF",

    fontSize: "14px",
    fontFamily: "Manrope",
    ":active": {
      ...baseStyles[":active"],
      backgroundColor: state.isSelected ? "#FFF" : "#EDF2F9",
    },
  }),
  indicatorSeparator: () => ({}),
  input: (baseStyles) => ({
    ...baseStyles,
    color: "#484D56",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
  }),
};

const InputForm = ({
  META,
  isEditMode,
  currentSetup,
  currentInput,
  setCurrentInput,
  services,
  setServices,
  // resourceTypes,
  setResourceTypes,
  setDisableNext,
  rules,
  alreadyExistingPolicy,
  setAlreadyExistingPolicy,
}) => {
  const routeParams = useParams();
  const [regionSearchTerm, setRegionSearchTerm] = useState("");
  const [focusedCustomInputId, setFocusedCustomInputId] = useState(null);
  const [error, setError] = useState("");
  const [showValidation, setShowValidation] = useState(true);
  const [disableVerify, setDisableVerify] = useState(false);

  const [showSelectedRegionsModal, setShowSelectedRegionsModal] =
    useState(false);
  const [showSelectedResourcesModal, setShowSelectedResourcesModal] =
    useState(false);

  const [disableInitialCheckbox, setDisableInitialCheckbox] = useState(true);
  // const [isRegionsExpanded, setIsRegionsExpanded] = useState(false);
  // const Regions = useMemo(()=>{
  //   if() return
  // })
  // const Regions = useMemo(() => {
  //   if ("label" in currentSetup.envId) {
  //     console.log("this is triggered");

  //     return GetCloudProvider(currentSetup.envId.label) === "aws"
  //       ? AwsRegions
  //       : GcpRegions;
  //   } else if (currentSetup?.envId?.data.cloudProvider === "aws") {
  //     return AwsRegions;
  //   } else return GcpRegions;
  // }, []);
  // console.log(Regions, "sdfsdf");
  const Regions =
    "label" in currentSetup.envId
      ? GetCloudProvider(currentSetup?.envId.label) === "aws"
        ? AwsRegions
        : GcpRegions
      : currentInput?.envId?.data?.cloudProvider === "aws"
      ? AwsRegions
      : GcpRegions;
  console.log(Regions, "sdf");

  const handleValidatePolicy = async () => {
    const res = await axiosPost(
      "policy-input-validate",
      {
        master_rule_id: currentInput.rule.ruleId,
        custom_input: currentInput.customInput,
      },
      true
    );
    if (res.data.is_validated) {
      setError("");
      setDisableNext(false);
      setShowValidation(false);
    } else {
      setError(res.data.msg.slice(0, 1).toUpperCase() + res.data.msg.slice(1));
      showValidation(true);
    }
  };

  const filteredRegions = useMemo(() => {
    if (!regionSearchTerm || !regionSearchTerm.trim()) return Regions;

    const searchTerm = regionSearchTerm.toLowerCase();

    return Regions.filter((regionGroup) => {
      // Find in group names
      const matchedRegions = regionGroup.regions.filter((region) => {
        if (region.name.toLowerCase().includes(searchTerm)) return true;
        return false;
      });

      if (matchedRegions.length) return true;
    }).map((regionGroup) => {
      const matchedRegions = regionGroup.regions.filter((region) => {
        // Find in region names
        if (region.name.toLowerCase().includes(searchTerm)) return true;
        return false;
      });

      return {
        ...regionGroup,
        regions: matchedRegions,
      };
    });
  }, [regionSearchTerm, Regions]);
  console.log();

  const filterRuleOptions = (rule, input) => {
    if (input)
      return (
        rule.data.ruleId
          .toLowerCase()
          .includes(input.toString().toLowerCase()) ||
        rule.data.description
          .toLowerCase()
          .includes(input.toString().toLowerCase())
      );

    return true;
  };

  // Indicates if currently displayed list (after applying filters) contains
  // any unselected regions.
  const hasUnselectedRegions = useMemo(() => {
    if (!currentInput.regions || !currentInput.regions.length) return true;

    let anyUnselectedRegion;

    for (const regionGroup of filteredRegions) {
      for (const region of regionGroup.regions) {
        const isRegionSelected = currentInput.regions.find(
          (regionId) => regionId === region.id
        );

        if (!isRegionSelected) {
          anyUnselectedRegion = region;
          break;
        }
      }

      if (anyUnselectedRegion) break;
    }

    return Boolean(anyUnselectedRegion);
  }, [filteredRegions, currentInput.regions]);

  const selectedRuleInputs = useMemo(() => {
    if (!currentInput.rule) return [];
    setError("");
    setShowValidation(true);

    // console.log(META.data, currentInput, "=======");
    const selectedRule = META.data.rulesDetails.find(
      (r) => r.rulesId === currentInput.rule.ruleId
    );

    const _inputs = [];

    for (
      let i = 1;
      i <= Object.keys(selectedRule.inputParameters).length;
      i++
    ) {
      const key = `p${i}`;
      // const nameKey = `parameter_${i}_ui_param_name`;
      // const placeholderKey = `parameter_${i}__ui_placeholder_text`;
      const nameKey = `ui_parameter`;
      const placeholderKey = `ui_placeholder_text`;

      if (
        selectedRule.inputParameters[key][nameKey] &&
        selectedRule.inputParameters[key][placeholderKey]
      ) {
        _inputs.push({
          ...selectedRule.inputParameters[key],
          nameKey,
          placeholderKey,
        });
      }
    }

    return _inputs;
  }, [currentInput, META]);

  const existingPolicyShouldDisableNext = useMemo(() => {
    return (
      alreadyExistingPolicy &&
      alreadyExistingPolicy.policy_id !== routeParams.policyId &&
      !isEditMode
    );
  }, [alreadyExistingPolicy, routeParams, isEditMode]);

  useEffect(() => {
    const allEmpty = Object.values(currentInput.customInput).reduce(
      (bool, value) => {
        if (!bool) return bool;

        if (value !== "") {
          return false;
        } else {
          return true;
        }
      },
      true
    );

    allEmpty ? setDisableVerify(true) : setDisableVerify(false);

    if (!existingPolicyShouldDisableNext && selectedRuleInputs.length) {
      if (
        selectedRuleInputs.length === 1 &&
        selectedRuleInputs[0][selectedRuleInputs[0].placeholderKey].slice(
          0,
          10
        ) === "(optional)"
      ) {
        if (Object.values(currentInput.customInput)[0] === "") {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
      } else {
        setDisableNext(true);
      }
    } else {
      setDisableNext(false);
    }
  }, [
    existingPolicyShouldDisableNext,
    selectedRuleInputs,
    currentInput.customInput,
    setDisableNext,
  ]);

  useEffect(() => {
    setCurrentInput((prev) => {
      if (selectedRuleInputs.length && isEmpty(prev.customInput)) {
        return {
          ...prev,
          customInput: selectedRuleInputs.reduce((obj, rule) => {
            obj[rule[rule.nameKey]] = "";
            return obj;
          }, {}),
        };
      }
      return prev;
    });
  }, [selectedRuleInputs, setCurrentInput]);

  useEffect(() => {
    (async () => {
      try {
        // Fetch policy meta which contains
        // - services

        const _services = META.data.rulesDetails
          .filter(
            (item) =>
              item.cloudProvider ===
              (currentSetup?.envId.data
                ? currentSetup.envId.data.cloudProvider
                : GetCloudProvider(currentSetup?.envId?.value))
          )
          .map((r) => r.service)
          .reduce((acc, curr) => {
            if (!acc.includes(curr)) return [...acc, curr];
            return acc;
          }, []);

        setServices(_services);

        // if (
        //   META?.data?.rulesDetails?.length &&
        //   currentSetup?.envId &&
        //   currentInput?.service?.value
        // ) {
        //   const _resourceTypes = META.data.rulesDetails
        //     .filter(
        //       (item) =>
        //         item.cloudProvider ===
        //           (currentSetup?.envId.data
        //             ? currentSetup.envId.data.cloudProvider
        //             : GetCloudProvider(currentSetup?.envId?.value)) &&
        //         item.service === currentInput?.service?.value
        //     )
        //     .map((r) => r.resourceType)
        //     .reduce((acc, curr) => {
        //       if (!acc.includes(curr)) return [...acc, curr];
        //       return acc;
        //     }, [])

        //   setResourceTypes(_resourceTypes);
        // }

        // const _rules = META.data.rulesDetails
        //   .map((r) => r.ruleDescription)
        //   .reduce((acc, curr) => {
        //     if (!acc.includes(curr)) return [...acc, curr];
        //     return acc;
        //   }, []);

        // setRules(_rules);
      } catch (err) {
        /* empty */
      }
    })();
  }, [
    META.data.rulesDetails,
    currentInput.service,
    currentSetup.envId.data,
    currentSetup.envId?.value,
    setResourceTypes,
    setServices,
  ]);

  // useEffect(() => {
  //   setCurrentInput((prev) => {
  //     return {
  //       ...prev,
  //       resources_manual: resources,
  //     };
  //   });
  // }, [resources]);

  // On rule selection changing, check if any policy
  // exists with the selected rule id.
  useEffect(() => {
    setAlreadyExistingPolicy(null);

    if (!currentInput.rule) return;

    (async () => {
      try {
        const response = await agent.Policy.get_table_data({
          scope: ["Global"],
          scope_environment_id: [currentSetup.envId.value],
          cloud_provider: "",

          search_keyword: "",

          all_resource: {
            service_category: [],
            service: [],
            resource_type: [],
          },
          date_filter: "all",
          policy_id: "",
          properties_filter: {
            rule_id: [currentInput.rule.ruleId],
            region: [],
            severity: [],
            user_groups: "",
            match: "any",
          },
          unique_id: "121",
          page_no: 1,
          row_per_page: 1000,
        });

        if (response.error) {
          throw new Error(response.msg);
        }

        if (response.data) {
          setAlreadyExistingPolicy(response.data);
        } else {
          setAlreadyExistingPolicy(null);
        }
      } catch (err) {
        console.log(err);

        toast(`Failed to check existing policy. ${err.message}`, {
          position: "top-right",
          type: "error",
          newestOnTop: true,
        });

        setAlreadyExistingPolicy({}); // To hide edit policy link
      }
    })();
  }, [currentSetup, currentInput.rule, setAlreadyExistingPolicy]);

  // console.log(
  //   "selectedValues",
  //   currentInput,
  //   services,
  //   resourceTypes,
  //   META.data.rulesDetails
  // );

  return (
    <div id="inputForm">
      <div className="row-container">
        <div className="w-100">
          <h5 className="form__InnerTitle" style={{ marginTop: "0" }}>
            1. Service<span style={{ color: "red" }}>*</span>{" "}
          </h5>
          <div>
            <MultiReactSelect
              disabled={isEditMode}
              allowSelectAll={false}
              name="service_select"
              value={currentInput.service}
              onChange={(e) => {
                setCurrentInput((currentConfig) => ({
                  ...currentConfig,
                  service: e,
                  ...(e.value === "IAM" && {
                    regions: ["global"],
                  }),
                }));
              }}
              inputProps={{
                id: "service_select",
              }}
              className="service_select"
              options={services.sort().map((e) => {
                return { value: e, label: e };
              })}
              placeholder="Select Service"
              styles={customSelect}
            />
            {/* <AWSServiceIcons
              name={itm.Service.replace("AWS", "")
                .replace(/ +/g, "")
                .toLowerCase()}
            /> */}
          </div>

          <div>
            <h5 className="form__InnerTitle" style={{ marginTop: "25px" }}>
              3. Select Regions<span style={{ color: "red" }}>*</span>{" "}
            </h5>
            <div className="d-flex flex-row pb-3 radio-group">
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                alignItems={"center"}
              >
                <Radio
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  checked={currentInput.isRegionAny}
                  disabled={
                    !currentInput.resourceType ||
                    (currentInput.service &&
                      (currentInput.service.value === "IAM" ||
                        currentInput.resourceType?.value ===
                          "AWS.S3.PublicAccessBlockConfiguration"))
                  }
                  onChange={(e) =>
                    setCurrentInput((prev) => ({
                      ...prev,
                      isRegionAny: e.target.checked,
                    }))
                  }
                />

                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    color: "#484D56",
                    paddingRight: "1rem",
                  }}
                >
                  Any Region
                </span>

                <Radio
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  checked={!currentInput.isRegionAny}
                  disabled={!currentInput.resourceType}
                  onChange={(e) =>
                    setCurrentInput((prev) => ({
                      ...prev,
                      isRegionAny: !e.target.checked,
                    }))
                  }
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    color: "#484D56",
                  }}
                >
                  Select Region
                </span>
                <Box flexGrow={1} />
                <Box
                  // marginLeft="0.8rem"
                  fontSize="14px"
                  color="#476FFC"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontFamily: "Manrope",
                  }}
                  onClick={() => setShowSelectedRegionsModal(true)}
                >
                  View Selected Regions
                </Box>
              </Box>
            </div>
            <div className="CreateRemediationPolicy__TableContainer d-flex flex-column w-100">
              {/* <div
                key="Region Name"
                className="CreateRemediationPolicy__TableHeader"
                style={{
                  background: !currentInput.resourceType ? "hsl(0,0%,95%)" : "",
                }}
              >
                Region Name
                <span>
                  <span style={{ fontFamily: "Manrope", fontSize: "14px" }}>
                    Include All
                  </span>
                  <Form.Check
                    onChange={(e) => {
                      if (e.target.checked) {
                        // Add all regions in displayed list to selection
                        // while making sure we don't add already selected
                        // regions again.
                        setCurrentInput((prev) => ({
                          ...prev,
                          regions: [
                            ...prev.regions,
                            ...filteredRegions.reduce((acc, curr) => {
                              return [
                                ...acc,
                                ...curr.regions
                                  .map((item) => item.id)
                                  .filter(
                                    (r) =>
                                      !prev.regions.find(
                                        (regionId) => r === regionId
                                      )
                                  ),
                              ];
                            }, []),
                          ],
                        }));
                      } else {
                        setCurrentInput((prev) => ({
                          ...prev,
                          regions: [],
                        }));
                      }
                    }}
                    inline
                    name="all props.regions"
                    type="checkbox"
                    className="CreateRemediationPolicy__HeaderCheckbox"
                    checked={
                      !hasUnselectedRegions ||
                      currentInput.isRegionAny ||
                      (currentInput.service &&
                        currentInput.service.value === "IAM")
                    }
                    disabled={
                      currentInput.isRegionAny ||
                      !currentInput.resourceType ||
                      (currentInput.service &&
                        currentInput.service.value === "IAM")
                    }
                  />
                </span>
              </div> */}

              <div
                className="CreateRemediationPolicy__RegionsOuter"
                style={{
                  background: !currentInput.resourceType ? "hsl(0,0%,95%)" : "",
                }}
              >
                {!currentInput.service ||
                (currentInput.service.value !== "IAM" &&
                  currentInput.resourceType?.value !==
                    "AWS.S3.PublicAccessBlockConfiguration") ? (
                  <>
                    <div
                      className="CreateRemediationPolicy__Searchbar"
                      style={{
                        background: !currentInput.resourceType
                          ? "hsl(0,0%,95%)"
                          : "",
                      }}
                    >
                      <div>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                          placeholder="Search a Region"
                          disabled={!currentInput.resourceType}
                          onChange={(e) => setRegionSearchTerm(e.target.value)}
                        />
                      </div>
                      <span>
                        <span
                          style={{ fontFamily: "Manrope", fontSize: "14px" }}
                        >
                          Include All
                        </span>
                        <Form.Check
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDisableInitialCheckbox(false);
                              setCurrentInput((prev) => ({
                                ...prev,
                                regions: [
                                  ...prev.regions,
                                  ...filteredRegions.reduce((acc, group) => {
                                    return [
                                      ...acc,
                                      ...group.regions.reduce(
                                        (regionAcc, region) => {
                                          return [
                                            ...regionAcc,
                                            region.id,
                                            ...(region.zones || []), // Add all zones of each region
                                          ];
                                        },
                                        []
                                      ),
                                    ];
                                  }, []),
                                ],
                              }));
                            } else {
                              setCurrentInput((prev) => ({
                                ...prev,
                                regions: [],
                              }));
                            }
                          }}
                          inline
                          name="all props.regions"
                          type="checkbox"
                          className="CreateRemediationPolicy__HeaderCheckbox"
                          checked={
                            !hasUnselectedRegions ||
                            currentInput.isRegionAny ||
                            (currentInput.service &&
                              (currentInput.service.value === "IAM" ||
                                currentInput.resourceType?.value ===
                                  "AWS.S3.PublicAccessBlockConfiguration"))
                          }
                          disabled={
                            currentInput.isRegionAny ||
                            !currentInput.resourceType ||
                            (currentInput.service &&
                              (currentInput.service.value === "IAM" ||
                                currentInput.resourceType?.value ===
                                  "AWS.S3.PublicAccessBlockConfiguration"))
                          }
                        />
                      </span>
                    </div>
                    <Box
                      // className={`CreateRemediationPolicy__RegionsList ${
                      //   isRegionsExpanded ? "Expanded" : ""
                      // }`}
                      className={`CreateRemediationPolicy__RegionsList Expanded`}
                    >
                      {!filteredRegions.length ? (
                        <Box
                          className="fs__-2"
                          style={{
                            padding: "24px",
                            textAlign: "center",
                            fontStyle: "italic",
                          }}
                        >
                          No Regions Available
                        </Box>
                      ) : null}

                      {filteredRegions.map((group) => (
                        <>
                          <Box className="CreateRemediationPolicy__SectionCheckboxContainer">
                            <p>{group.groupName}</p>
                            <Form.Check
                              inline
                              type="checkbox"
                              checked={
                                group.regions.every(
                                  (region) =>
                                    currentInput.regions.includes(region.id) &&
                                    (region.zones || []).every((zone) =>
                                      currentInput.regions.includes(zone)
                                    )
                                ) || currentInput.isRegionAny
                              }
                              disabled={
                                currentInput.isRegionAny ||
                                !currentInput.resourceType
                              }
                              onChange={(e) => {
                                setDisableInitialCheckbox(false);
                                if (e.target.checked) {
                                  setCurrentInput((prev) => ({
                                    ...prev,
                                    regions: [
                                      ...prev.regions,
                                      ...group.regions.reduce((acc, region) => {
                                        return [
                                          ...acc,
                                          region.id,
                                          ...(region.zones || []), // Add all zones of each region
                                        ];
                                      }, []),
                                    ],
                                  }));
                                } else {
                                  setCurrentInput((prev) => ({
                                    ...prev,
                                    regions: prev.regions.filter(
                                      (region) =>
                                        !group.regions.find(
                                          (r) => r.id === region
                                        ) &&
                                        !group.regions.some((r) =>
                                          (r.zones || []).includes(region)
                                        )
                                    ),
                                  }));
                                }
                              }}
                            />
                          </Box>

                          {group.regions.map((region) => (
                            <div key={region.id}>
                              <Form className="CreateRemediationPolicy__RegionItem d-flex flex-row justify-content-between">
                                <span>{region.id}</span>
                                <Form.Check
                                  id={region.id}
                                  radioGroup="props.regions"
                                  inline
                                  checked={
                                    currentInput.regions.includes(region.id) ||
                                    (disableInitialCheckbox &&
                                      (region.zones || []).every((zone) =>
                                        currentInput.regions.includes(zone)
                                      )) ||
                                    currentInput.isRegionAny
                                  }
                                  disabled={
                                    disableInitialCheckbox ||
                                    currentInput.isRegionAny ||
                                    !currentInput.resourceType
                                  }
                                  name={region.id}
                                  onChange={(ev) => {
                                    if (ev.target.checked) {
                                      setCurrentInput((currentConfig) => ({
                                        ...currentConfig,
                                        regions: [
                                          ...currentConfig.regions,
                                          region.id,
                                          ...(region.zones || []), // Add all zones if region is selected
                                        ],
                                      }));
                                    } else {
                                      setCurrentInput((currentConfig) => ({
                                        ...currentConfig,
                                        regions: currentConfig.regions.filter(
                                          (r) =>
                                            r !== region.id &&
                                            !(region.zones || []).includes(r)
                                        ),
                                      }));
                                    }
                                  }}
                                  value={region.id}
                                  type="checkbox"
                                />
                              </Form>

                              {/* Conditionally render zones if available */}
                              {region.zones && (
                                <div className="CreateRemediationPolicy__ZonesList">
                                  {region.zones.map((zone) => (
                                    <Form
                                      key={zone}
                                      className="CreateRemediationPolicy__ZoneItem d-flex flex-row justify-content-between"
                                    >
                                      <span>{zone}</span>
                                      <Form.Check
                                        id={zone}
                                        inline
                                        checked={
                                          currentInput.regions.includes(zone) ||
                                          currentInput.isRegionAny
                                        }
                                        disabled={
                                          currentInput.isRegionAny ||
                                          !currentInput.resourceType
                                        }
                                        name={zone}
                                        onChange={(ev) => {
                                          if (ev.target.checked) {
                                            setCurrentInput(
                                              (currentConfig) => ({
                                                ...currentConfig,
                                                regions: [
                                                  ...currentConfig.regions,
                                                  zone,
                                                  region.id, // Ensure region is selected if any zone is selected
                                                ],
                                              })
                                            );
                                          } else {
                                            setCurrentInput(
                                              (currentConfig) => ({
                                                ...currentConfig,
                                                regions:
                                                  currentConfig.regions.filter(
                                                    (r) => r !== zone
                                                  ),
                                              })
                                            );
                                          }
                                        }}
                                        value={zone}
                                        type="checkbox"
                                      />
                                    </Form>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Box
                    // className={`CreateRemediationPolicy__RegionsList ${
                    //   isRegionsExpanded ? "Expanded" : ""
                    // }`}
                    className={`CreateRemediationPolicy__RegionsList Expanded`}
                  >
                    <Box className="CreateRemediationPolicy__SectionCheckboxContainer">
                      <p>Global</p>
                      <Form.Check
                        inline
                        type="checkbox"
                        checked={true}
                        disabled={true}
                      />
                    </Box>
                  </Box>
                )}

                <Box
                  className="CreateRemediationPolicy__TableFooter"
                  style={{
                    background: !currentInput.resourceType
                      ? "hsl(0,0%,95%)"
                      : "",
                  }}
                >
                  {/* <Box onClick={() => setIsRegionsExpanded((prev) => !prev)}>
                    {isRegionsExpanded ? (
                      <KeyboardDoubleArrowUp />
                    ) : (
                      <KeyboardDoubleArrowDown />
                    )}
                    <span style={{ color: "#476FFC" }}>
                      {isRegionsExpanded ? "Collapse" : "Expand"} Region
                    </span>
                  </Box> */}
                  <Box flexGrow={1} />
                  <Box>{currentInput.regions.length} Selected</Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <h5 className="form__InnerTitle">
            2. Resource Type<span style={{ color: "red" }}>*</span>
          </h5>
          <div>
            <MultiReactSelect
              disabled={!currentInput.service || isEditMode}
              allowSelectAll={false}
              name="rtype_select"
              value={currentInput.resourceType}
              onChange={(e) => {
                setCurrentInput((currentConfig) => ({
                  ...currentConfig,
                  resourceType: e,
                  ...(e.value === "AWS.S3.PublicAccessBlockConfiguration" && {
                    regions: ["global"],
                  }),
                }));
              }}
              inputProps={{
                id: "rtype_select",
              }}
              className="rtype_select"
              options={META.data.rulesDetails
                .filter(
                  (item) =>
                    item.cloudProvider ===
                      (currentSetup?.envId.data
                        ? currentSetup.envId.data.cloudProvider
                        : GetCloudProvider(currentSetup?.envId?.value)) &&
                    item.service === currentInput?.service?.value
                )
                .map((r) => r.resourceType)
                .reduce((acc, curr) => {
                  if (!acc.includes(curr)) return [...acc, curr];
                  return acc;
                }, [])
                .sort()
                .map((e) => {
                  return { value: e, label: e };
                })}
              placeholder="Select Resource Type"
              styles={customSelect}
            />
          </div>
          <div className="rule-select-wrapper">
            <h5 className="form__InnerTitle" style={{ marginTop: "25px" }}>
              4. Rule<span style={{ color: "red" }}>*</span>{" "}
            </h5>
            <div>
              <MultiReactSelect
                disabled={
                  (!currentInput.regions.length && !currentInput.isRegionAny) ||
                  isEditMode
                }
                allowSelectAll={false}
                name="rule_select"
                value={currentInput.rule}
                onChange={(e) => {
                  setCurrentInput((currentConfig) => {
                    return {
                      ...currentConfig,
                      customInput: {},
                      rule: e,
                    };
                  });
                }}
                filterOption={filterRuleOptions}
                inputProps={{
                  id: "rule_select",
                }}
                className="rule_select"
                options={rules.map((r) => {
                  return {
                    ...r,
                    value: r.description,
                    label: (
                      <div>
                        <span style={{ fontWeight: 700 }}>
                          {r.ruleData.rulesId}:
                        </span>{" "}
                        {r.description}
                      </div>
                    ),
                  };
                })}
                // onInputChange={handleInputChange}
                placeholder="Select Rule"
                styles={customSelect}
              />
            </div>
          </div>

          {existingPolicyShouldDisableNext ? (
            <InfoBox
              children={
                <div>
                  There is already another policy created for this Rule.{" "}
                  {alreadyExistingPolicy && alreadyExistingPolicy.policy_id ? (
                    <>
                      <Link
                        to={`/remediation-policy/edit/${alreadyExistingPolicy.policy_id}`}
                      >
                        Click here
                      </Link>{" "}
                      to edit it.
                    </>
                  ) : null}
                </div>
              }
              style={{ marginTop: "24px" }}
            />
          ) : null}

          {!existingPolicyShouldDisableNext && selectedRuleInputs.length ? (
            <div>
              <h5 className="form__InnerTitle" style={{ marginTop: "25px" }}>
                5. Input<span style={{ color: "red" }}>*</span>{" "}
              </h5>

              {selectedRuleInputs.map((inputData) => (
                <div style={{ marginBottom: "16px" }}>
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontWeight: 600,
                      fontSize: "12px",
                      color: "#484D56",
                    }}
                  >
                    Parameter: {inputData[inputData.nameKey]}
                  </label>

                  <HtmlTooltip
                    open={inputData.nameKey === focusedCustomInputId}
                    arrow
                    title={inputData[inputData.placeholderKey]}
                    placement="right"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <div className="CreateRemediationPolicy__Searchbar">
                      <input
                        style={{
                          padding: "6px 8px",
                          fontFamily: "Manrope",
                          fontSize: "14px",
                          color: "#A3A6AB",
                          fontWeight: 500,
                        }}
                        value={
                          currentInput.customInput[
                            inputData[inputData.nameKey]
                          ] ?? ""
                        }
                        onChange={(e) => {
                          setShowValidation(true);
                          setCurrentInput((prev) => ({
                            ...prev,
                            customInput: {
                              ...prev.customInput,
                              [inputData[inputData.nameKey]]:
                                e.target.value ?? "",
                            },
                          }));
                        }}
                        onFocus={() =>
                          setFocusedCustomInputId(inputData.nameKey)
                        }
                        onBlur={() => setFocusedCustomInputId(null)}
                        type="text"
                        placeholder={inputData[inputData.placeholderKey]}
                      />
                    </div>
                  </HtmlTooltip>
                </div>
              ))}
              {showValidation ? (
                <Box width={"100%"} display={"flex"}>
                  <Box
                    flexGrow={1}
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "12px",
                      marginTop: "10px",
                      fontWeight: "500",
                      color: "red",
                    }}
                  >
                    {error}
                  </Box>

                  <SubmitButton
                    onClick={handleValidatePolicy}
                    disabled={disableVerify}
                    text={"Verify"}
                    style={{
                      height: "30px",
                      padding: "0 17px",
                      margin: "10px ",
                      fontSize: "15px",
                      fontWeight: "500",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  flexGrow={1}
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    marginTop: "10px",
                    fontWeight: "500",
                    color: "#03BB34",
                  }}
                >
                  Input is validated
                </Box>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <SelectedRegionsModal
        open={showSelectedRegionsModal}
        regions={currentInput.regions}
        isRegionAny={currentInput.isRegionAny}
        onClose={() => setShowSelectedRegionsModal(false)}
      />

      <SelectedResourcesModal
        open={showSelectedResourcesModal}
        resources={currentInput.resources}
        isResourceAny={currentInput.isResourceAny}
        onClose={() => setShowSelectedResourcesModal(false)}
      />
    </div>
  );
};

export default InputForm;
