// import commonTableColumns from "@root/components/table/commonTableColumns";
import { VulnerabilityCounts } from "../VulnerabilityCounts";
// import DateFormatPipe from "@root/components/utilities/DateFormatPipe";

export const workloadColumns  = [
    // commonTableColumns.environment('account_id'),
    {
        field: 'name',
        headerName: 'Workload',
        width: 230,
      },
      {
        field: 'kind',
        headerName: 'Kind',
        width: 200,
      },
    // {
    //   field: 'containers',
    //   headerName: 'Containers',
    //   renderCell: (parmas)=> {
    //     return parmas.row.containers.length > 0 && parmas.row.containers[0]
    //   },
    //   width: 230,
    // },
    // {
    //   field: 'pod',
    //   headerName: 'Pods',
    //   width: 250,
    // },

    {
      field: 'namespace',
      headerName: 'Namespace',
      width: 200,
    },
    {
      field: 'cluster',
      headerName: 'Cluster',
      width: 200,
    },

    // {
    //   field: 'status',
    //   headerName: 'State',
    //   width: 200,
    // },
    {
      field: 'risk_factors',
      headerName: 'Risk Factors',
      renderCell: ()=> 'Host Access',
      width: 200,
    },
  
    // {
    //   field: 'last_scan',
    //   headerName: 'Last Scan',
    //   renderCell: (parmas)=> {
    //     return DateFormatPipe(parmas.row.last_scan)
    //   },
    //   width: 230,
    // },
  
    {
      field: 'vulnerabilities',
      headerName: 'Vulnerabilities',
      width: 250,
      renderCell: params => {
        return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts} />;
      },
    },
  ]