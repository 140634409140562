// import commonTableColumns from '@root/components/table/commonTableColumns';
import { VulnerabilityCounts } from '../VulnerabilityCounts';
import CountTooltip from '@root/components/Tooltip/CountTooltip';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';
// import atlassian_jira_logo_icon from '@assets/svg/atlassian_jira_logo_icon.svg';

import { WorkloadVulnerabilities } from '@root/assets/svg/SVGComponents';

// import fix_available_check from '@root/assets/svg/workload/green_tick_icon_new.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';

import { SeverityWithText } from '@root/components/bootstrapTable/badges/severity';

import { Box } from '@mui/material';

import commonTableColumns from '@root/components/table/commonTableColumns';
export const sbomColumnsImages = [
  //   commonTableColumns.environment('account_id'),

  {
    field: 'name',
    headerName: 'Component',
    width: 230,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
  },
  {
    field: 'path',
    headerName: 'Path',
    renderCell: params => {
      const path = params.row.locations?.map?.(v => v.path);
      return <CountTooltip labels={path} />;
    },
    width: 400,
  },

  //   {
  //     field: 'images',
  //     headerName: 'Images',
  //     renderCell: params => {
  //       return <CountTooltip labels={params?.row?.images} />;
  //     },
  //     width: 250,
  //   },
  //   {
  //     field: 'workloads',
  //     headerName: 'Workloads',
  //     renderCell: params => {
  //       return <CountTooltip labels={params?.row?.workloads} />;
  //     },
  //     width: 250,
  //   },
  //   {
  //     field: 'namespaces',
  //     headerName: 'Namespaces',
  //     width: 200,
  //   },
  //   {
  //     field: 'cluster',
  //     headerName: 'Cluster',
  //     width: 230,
  //   },

  {
    field: 'vulnerabilities',
    headerName: 'Vulnerabilities',
    width: 250,
    renderCell: params => {
      return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts ?? {}} />;
    },
  },
];
export const layersColumnsImages = [
  //   commonTableColumns.environment('account_id'),

  {
    field: 'mediaType',
    headerName: 'Media Type',
    width: 400,
  },
  {
    field: 'size',
    headerName: 'size',
    renderCell: params => params?.row?.size ?? 0,
    width: 200,
  },
];
export const vulnerabilitiesImagesColumns = () => {
  return [
    commonTableColumns.environment('account_id'),
    {
      field: 'cluster',
      headerName: 'Cluster',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={params.row?.cluster}>
            <Text fontWeight={500} className="SingleLine">
              {params.row?.cluster ?? '-'}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      field: 'cve_id',
      headerName: 'CVE ID',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Box sx={{ background: 'rgb(243, 244, 246)', padding: '5px', borderRadius: '.5rem' }}>
              {' '}
              <WorkloadVulnerabilities height="16px" width="16px" />{' '}
            </Box>

            <Tooltip title={params.row?.cve_id}>
              <Text fontWeight={500} style={{ width: '170px' }} className="SingleLine">
                {params.row?.cve_id}
              </Text>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: 'severity',
      headerName: 'CVSS Severity',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.severity}>
            {params.row?.severity.toUpperCase() !== 'INFORMATIONAL' ||
            params.row?.severity.toUpperCase() !== 'UNTRIAGED' ? (
              // <HighIcon textDisabled={true} />
              <SeverityWithText propsValue={params.row?.severity} />
            ) : params.row?.severity.toUpperCase() === 'INFORMATIONAL' ? (
              <img
                src={info}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            ) : (
              <img
                src={question1}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: 'cvss_score',
      headerName: 'CVSS Score',
      classes: 'align-middle',
      minWidth: 200,
      sort: true,
      renderCell: params => {
        const score =
          params.row?.['cvss_score'] === 0 || !params.row?.['cvss_score']
            ? 0
            : parseFloat(params.row?.['cvss_score']).toFixed(1);
        const colorSeverity = params.row?.severity;

        const severityDarkColors = {
          CRITICAL: '#FF0000',
          HIGH: '#EB5757',
          MEDIUM: '#FFA500',
          LOW: '#F2C94C',
          ALL: '#1E90FF',
          DEFAULT: '#5D0E61',
        };

        const severityLightColors = {
          CRITICAL: '#ffdad3',
          HIGH: '#ffdfdc',
          MEDIUM: '#ffeed9',
          LOW: '#fdf5df',
          ALL: '#d7eaff',
          DEFAULT: '#dfcedf',
        };

        const backgroundDarkColor = severityDarkColors[colorSeverity] || severityDarkColors.DEFAULT;
        const backgroundLightColor = severityLightColors[colorSeverity] || severityLightColors.DEFAULT;

        // const version = `v${params.row?.details?.Vector ?? '3.0'}`;
        const version = params.row?.details?.cvssMetricV31?.cvssMetricV31?.[0]?.cvssData?.version
          ? `v${params.row.details.cvssMetricV31.cvssMetricV31[0].cvssData.version}`
          : params.row?.details?.Vector?.match(/CVSS:(\d+\.\d+)/)?.[1]
          ? `v${params.row.details.Vector.match(/CVSS:(\d+\.\d+)/)[1]}`
          : 'v3.0';

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '5px',
              overflow: 'hidden',
            }}>
            <div
              style={{
                backgroundColor: backgroundDarkColor,
                padding: '3px 7px',
                color: '#FFFFFF',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {score}
            </div>
            <div
              style={{
                backgroundColor: backgroundLightColor,
                padding: '3px 7px',
                color: '#5D0E61',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {version}
            </div>
          </div>
        );
      },
    },

    {
      field: 'epss_score',
      headerName: 'EPSS Score',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={'params.row.threat_trend_data.Vendor'}>
            <Text fontWeight={500} className="SingleLine">
              {(params.row?.exploitability_score ?? 0).toFixed(2)}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      field: 'exploitable',
      headerName: 'Exploitable',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return params?.row?.exploitable ? 'Yes' : 'No';
      },
    },

    {
      field: 'components',
      headerName: 'Component',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 450,
      sortable: false,
      renderCell: params => {
        return <CountTooltip labels={params?.row?.components} />;
      },
    },
    {
      field: 'fix_version',
      headerName: 'Fix Version',
      renderCell: params => {
        return params?.row?.fix_version ?? '-';
      },
      width: 250,
    },
    {
      field: 'workloads',
      headerName: 'Workload',
      renderCell: params => {
        return <CountTooltip labels={params?.row?.workloads} />;
      },
      width: 250,
    },
    //   {
    //     field: 'workloads',
    //     headerName: 'Workloads',
    //     renderCell: params => {
    //       return <CountTooltip labels={params?.row?.affected_workloads} />;
    //     },
    //     width: 250,
    //   },
  ];
};
