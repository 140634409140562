import { useTheme } from '@root/context/ThemeContext';
import { capitalizeFirstLetter } from '@root/utilities/string';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
// import Text from '@root/components/v2/ui-elements/Text';
import _ from 'lodash';

const PieCharts = props => {
  const {
    chartData,
    count_msg,
    count_value,
    colors,
    onlyCount = false,
    height = 190,
    middleCountTextSize = '22px',
    width = 190,
    valueInPercentage,
    innerRadius = 61.5,
    outerRadius = 77.5,
  } = props;
  const { isDarkMode } = useTheme();
  function kFormatter(num) {
    const n = String(Math.sign(num) * (Math.abs(num) / 1000));
    if (Math.abs(num) < 999) {
      return num;
    } else if (!n.includes('.')) {
      return n + 'k';
    } else {
      return n.slice(0, 3) + 'k';
    }
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      if (payload[0]?.name === 0) return <></>;
      const labelColor = payload[0].payload.fill;
      return (
        <div className="custom_tooltip" style={{ outline: 0 }}>
          <div className="count_container">
            <p
              className="custom_tooltip_severity m-0"
              style={{ borderLeft: `4px solid ${labelColor} `, paddingLeft: '4px', borderRadius: '1px' }}>
              {capitalizeFirstLetter(payload[0]?.name)} :{' '}
              <span className="custom_tooltip_count"> {payload[0]?.value}</span>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  const isDataEmpty = _.isEmpty(chartData) || chartData.every(item => item.value === 0);

  return (
    <div className="inventory_new">
      <PieChart height={height} width={width}>
        <Pie
          dataKey="value"
          data={isDataEmpty ? [{ name: 'No Data', value: 1 }] : chartData}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#82ca9d"
          labelLine={false}
          isAnimationActive={false}
          stroke={{
            width: 0.1,
            color: '#808080',
          }}
          activeIndex={0}>
          {isDataEmpty ? (
            <Cell fill="#D3D3D3" />
          ) : (
            chartData?.map(d => <Cell key={`cell-${d.name}`} fill={colors[d.name] || '#f00'} fillOpacity={1} />)
          )}
        </Pie>
        {!isDataEmpty && <Tooltip content={<CustomTooltip />} />}
        {/* {count_value && ( */}
        <text
          x="50%"
          y={onlyCount ? '50%' : '45%'}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: isDarkMode ? 'var(--donut-dark-text)' : '#484D56',
            fontFamily: 'Manrope',
            fontSize: middleCountTextSize,
            fontWeight: 800,
          }}>
          {count_value && valueInPercentage ? count_value : kFormatter(count_value)}
        </text>
        {/* // )} */}
        {count_msg && (
          <text
            x="50%"
            y="55%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: isDarkMode ? 'var(--donut-dark-text)' : '#484D56',
              fontFamily: 'Manrope',
              fontSize: '12px',
              fontWeight: 600,
            }}>
            {count_msg}
          </text>
        )}
      </PieChart>
    </div>
  );
};

export default PieCharts;
