import './style.scss';

function PageContent({ className = '', style, children }) {
  return (
    <div className={`PageContent ${className || ''}`} style={style || {}}>
      {children}
    </div>
  );
}

export default PageContent;