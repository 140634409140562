import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@root/context/ThemeContext';

function CloudProviderBarSkeleton({ marginBottom = '15px' }) {
  const { isDarkMode } = useTheme();

  const skeletonStyle = {
    backgroundColor: isDarkMode ? '#2d3748' : '#e2e8f0',
    transition: 'background-color 0.3s ease',
  };

  const containerStyle = {
    backgroundColor: isDarkMode ? '#1a202c' : 'white',
    border: `1px solid ${isDarkMode ? '#2d3748' : '#cbd5e0'}`,
    transition: 'all 0.3s ease',
  };

  return (
    <Box
      className="Borders RoundedCorners"
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems="center"
      gap="10px"
      height="32px"
      marginBottom={marginBottom}
      paddingLeft="10px"
      width={'425px'}
      paddingRight="10px"
      sx={{
        ...containerStyle,
        '& .MuiSkeleton-root': {
          ...skeletonStyle
        }
      }}>
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
      <Skeleton width="80px" />
    </Box>
  );
}

export default CloudProviderBarSkeleton;
