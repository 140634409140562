import CountTooltip from '@root/components/table/cell-types/CountTooltip';

// import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
// import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
// import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
// import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
// import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';
// import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
// import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
// import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
// import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
// import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
// import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
// import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';
import ComputeIcon from '@root/assets/svg/Top Services Categories/Compute.svg';
import ContainersIcon from '@root/assets/svg/Top Services Categories/Containers.svg';
import Analytics from '@root/assets/svg/Top Services Categories/Data_Analytics.svg';
import Database from '@root/assets/svg/Top Services Categories/Database.svg';
import DeveloperIcon from '@root/assets/svg/Top Services Categories/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Top Services Categories/Machine_Learning.svg';
import ManagementToolsIcon from '@root/assets/svg/Top Services Categories/Management_Tools.svg';
import NetworkingIcon from '@root/assets/svg/Top Services Categories/Networking.svg';
import OperationIcon from '@root/assets/svg/Top Services Categories/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Top Services Categories/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Top Services Categories/Serverless.svg';
import StorageIcon from '@root/assets/svg/Top Services Categories/Storage.svg';

const service_category_icons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
};

const AWSServiceCategoryIcons = ({ height = 25, width = 25, name, multiple, showName = true }) => {
  if (multiple) {
    return (
      <>
        <img
          src={service_category_icons[name[0]?.split(' ')[1]]}
          height={height}
          width={width}
          style={{ objectFit: 'contain' }}
          alt={name}
        />

        {showName && (
          <span
            style={{
              margin: '0 5px',
            }}>
            {name[0]}
          </span>
        )}
        {name.length > 1 && (
          <CountTooltip
            // showFirst={false}
            labels={name}
            visibleLabelStyle={{ width: '140px !important' }}
            borderless
            showFirst={false}
          />
        )}
      </>
    );
  } else {
    const imgName = service_category_icons[name] || null;
    return (
      <>
        {imgName ? (
          <img
            src={service_category_icons[name]}
            height={height}
            width={width}
            style={{ objectFit: 'contain' }}
            alt={name}
          />
        ) : null}
      </>
    );
  }
};

export default AWSServiceCategoryIcons;
