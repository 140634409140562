import React, { useState, useEffect, useRef, cloneElement } from 'react';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />, {
  shouldForwardProp: prop => !['color', 'fontcolor', 'padding'].includes(prop),
})(({ theme, color, fontcolor, padding, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color || '#fafafa',
    color: fontcolor || '#121212',
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Manrope',
    padding: padding || '4px 8px',
    width: width || '100%',
    lineHeight: 2,
    letterSpacing:'.1px'
   
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: color || '#fafafa',
  },  
  // Add styles to ensure tooltip appears above the dropdown
  '&[data-popper-placement*="top"]': {
    zIndex: 10000,
  },
  '&[data-popper-placement*="bottom"]': {
    zIndex: 10000,
  },
}));

function Tooltip({
  title,
  children,
  color,
  isIcon = false,
  fromScan = false,
  fromDate = false,
  fontcolor,
  padding,
  width = '100%',
  placement = 'top', // Default placement to top
  ...props
}) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const childRef = useRef();

  useEffect(() => {
    const checkOverflow = () => {
      if (childRef.current) {
        const { scrollWidth, clientWidth } = childRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [children]);

  const childWithRef = React.isValidElement(children) ? cloneElement(children, { ref: childRef }) : children;

  if (!title || !children) {
    return <>{children || null}</>;
  }

  return (
    <LightTooltip
      title={isOverflowing || isIcon || fromScan || fromDate ? title : ''}
      arrow
      color={color}
      fontcolor={fontcolor}
      padding={padding}
      width={width}
      placement={placement}
      PopperProps={{
        style: { zIndex: 10001 }, // Ensure Popper has higher z-index
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              altAxis: true,
              tether: false,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            },
          },
        ],
      }}
      {...props}>
      {childWithRef}
    </LightTooltip>
  );
}

export default Tooltip;
