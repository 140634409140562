import { useTheme } from '@root/context/ThemeContext';

function PageContainer({ children, className = '' }) {
  const { isDarkMode } = useTheme();

  return (
    <div 
      className={className}
      style={{
        background: isDarkMode ? '#1a202c' : '#F7FAFC',
        transition: 'background-color 0.3s ease'
      }}
    >
      {children}
    </div>
  );
}

export default PageContainer;
