import commonTableColumns from '@root/components/table/commonTableColumns';
import { VulnerabilityCounts } from '../VulnerabilityCounts';
import CountTooltip from '@root/components/Tooltip/CountTooltip';

export const sbomColumns = [
  commonTableColumns.environment('account_id','cluster' ),
  {
    field: 'name',
    headerName: 'Image',
    width: 250,
  },
  {
    field: 'workload',
    headerName: 'Workload',
    renderCell: params => {
      return <CountTooltip labels={params?.row?.related_workloads?.map(item => item.name) ?? []} />;
    },
    width: 230,
  },
  {
    field: 'namespace',
    headerName: 'Namespace',
    width: 200,
    renderCell: params => {
      return <CountTooltip labels={params?.row?.namespaces} />;
    },
  },
  // {
  //   field: 'cluster',
  //   headerName: 'Cluster',
  //   width: 230,
  // },

  

  {
    field: 'vulnerabilities',
    headerName: 'Vulnerabilities',
    width: 250,
    renderCell: params => {
      return <VulnerabilityCounts vulnerabilities={params.row.vulnerability_counts} />;
    },
  },
];
