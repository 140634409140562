import { Box, Grid, Skeleton } from '@mui/material';
import NoData from '@root/components/common/NoData';
import ContentBox from '@root/components/v2/content/ContentBox';
import PieCharts from '../charts/PieChart';
// import TopOpenTooltip from '@root/components/v2/ui-elements/Tooltip/TopOpenTooltip';
import '../style.scss';
const HealthScoreBreakdown = props => {
  const {
    cloudProvider,
    health_score_breakdown,
    isFetchingHealthScoreBreakdown,
    title = 'Health Score Breakdown',

    isKubernetes = false,
    infoMsg,
    count_msg = 'Risk',
  } = props;
  const colors = {
    Critical: '#CD0404',
    High: '#EB6C6C',
    Medium: '#FFA825',
    Low: '#E8DF07',
    Info: '#476FFC',
    Warning: '#ED5656',
    Unknown: '#a0aec0',
  };
  return (
    <>
      <Grid item>
        <ContentBox
          title={
            <>
              {title}
              {/* <TopOpenTooltip
                title={
                  <div style={{ padding: '10px' }}>
                    <span> Health score breakdown based on resource severity </span>
                  </div>
                }>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 11.2C8.87188 11.2 11.2 8.87188 11.2 6C11.2 3.12812 8.87188 0.8 6 0.8C3.12812 0.799999 0.800001 3.12812 0.800001 6C0.8 8.87188 3.12812 11.2 6 11.2ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6ZM6 9.62656C5.37909 9.62656 5.2 9.02091 5.2 8.8L5.2 5.44C5.2 5.21909 5.37909 4.82656 6 4.82656C6.62091 4.82656 6.8 5.21909 6.8 5.44L6.8 8.8C6.8 9.02091 6.62091 9.62656 6 9.62656Z"
                    fill="#718096"
                  />
                  <path
                    d="M5.2 3.62656C5.2 3.18473 5.55817 2.82656 6 2.82656C6.44183 2.82656 6.8 3.18473 6.8 3.62656C6.8 4.06839 6.44183 4.42656 6 4.42656C5.55817 4.42656 5.2 4.06839 5.2 3.62656Z"
                    fill="#718096"
                  />
                </svg>
              </TopOpenTooltip> */}
            </>
          }
          infoMsg={infoMsg ?? 'Health score breakdown based on resource severity'}
          titleSize="medium"
          padding="0px"
          height={isKubernetes ? '426px' : '406px'}
          titleContainerStyle={{
            // border: "0px",
            fontSize: '16px !important',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            // borderBottom: '1px solid #CBD5E0',
            padding: '11.04px 15px',
            margin: '0px',
          }}
          containerClassName={'border-box-no-pad'}
          paddingMode="content">
          {isFetchingHealthScoreBreakdown ? (
            <Box display="flex" flexDirection="column" alignItems="center" padding="15px" paddingTop="35px">
              <Box width="100%" display="flex" justifyContent="center" marginBottom={'20px'}>
                <Skeleton variant="circular" width="160px" height="160px" />
              </Box>
              <Box flexGrow={0.4} height="10px" width="100%" />

              <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom="5px">
                <Skeleton width="100px" />
                <Skeleton width="100px" />
              </Box>
              <Grid sx={{ width: '100%' }}>
                {Array.from(new Array(4)).map((i, index) => (
                  <Box key={i} width="100%" display="flex" flexDirection="column" gap={'10px'}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop={index === 0 ? '0px' : '8px'}>
                      <Skeleton width="80px" />

                      <Skeleton width="80px" />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          ) : cloudProvider === 'azure' || health_score_breakdown?.data?.length === 0 ? (
            <NoData height={'350px'} />
          ) : (
            <div style={{ padding: '15px' }}>
              {/* <Grid>
                  <CircleWithText score={threats?.data?.total_threats} />
                </Grid> */}
              <Grid className="inventory_new">
                <Grid sx={{ display: 'flex', justifyContent: 'center', pt: '5px' }}>
                  <PieCharts
                    chartData={health_score_breakdown?.data?.risk_breakup}
                    count_msg={count_msg}
                    count_value={health_score_breakdown?.data?.total_risk}
                    colors={colors}
                  />
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', py: '10px' }}>
                  <p className="card_sub_heading legend-heading  m-0" style={{}}>
                    {' '}
                    Severity
                  </p>
                  <p className="card_sub_heading legend-heading  m-0"> Resources </p>
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                  {health_score_breakdown?.data?.risk_breakup.map(item => {
                    return (
                      <>
                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <p
                              className="m-0"
                              style={{
                                width: '10px',
                                height: '10px',
                                background:
                                  item.name == 'Critical'
                                    ? '#CD0404'
                                    : item.name == 'High'
                                    ? '#EB6C6C'
                                    : item.name == 'Medium'
                                    ? '#FFA825'
                                    : item.name == 'Low'
                                    ? '#E8DF07'
                                    : item.name == 'Info'
                                    ? '#476FFC'
                                    : item.name == 'Unknown'
                                    ? '#a0aec0'
                                    : '#ED5656',

                                borderRadius: '50%',
                              }}></p>
                            {/* <img src={aws_icon} alt="" style={{ width: '22px', height: '14px' }} /> */}
                            <p className="title legend-text  m-0">{item.name} </p>
                          </Grid>

                          <p className="title legend-value m-0" style={{ lineHeight: 'normal' }}>
                            {' '}
                            {item.value}
                          </p>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          )}
        </ContentBox>
      </Grid>
    </>
  );
};

export default HealthScoreBreakdown;
