import { Box, Skeleton } from '@mui/material';
import TableSkeleton from '@root/components/table/skeleton';
// import { height } from '@mui/system';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import { useTheme } from '@root/context/ThemeContext';

function OverviewSkeleton() {
  const { isDarkMode } = useTheme();

  const skeletonStyle = {
    backgroundColor: isDarkMode ? '#2d3748' : '#e2e8f0',
    transition: 'background-color 0.3s ease',
  };

  const contentBoxStyle = {
    backgroundColor: isDarkMode ? '#1a202c' : '#fff',
    transition: 'background-color 0.3s ease',
    border: `1px solid ${isDarkMode ? '#2d3748' : '#cbd5e0'}`,
  };

  const dotStyle = {
    backgroundColor: isDarkMode ? '#4a5568' : '#ddd',
    transition: 'background-color 0.3s ease',
  };

  return (
    // <PageContainer>
    //   <PageHeader title="Overview" />

    <PageContent>
      <Skeleton
        variant="rectangular"
        height="15px"
        width={'100px'}
        sx={{ 
          marginBottom: '15px', 
          borderRadius: '8px',
          ...skeletonStyle
        }}
      />
      <CloudProviderBarSkeleton />

      <ContentBox
        title="CQ Shield Priority"
        paddingMode="content"
        marginBottom="15px"
        style={{ height: '130px', ...contentBoxStyle }}
        titleContainerStyle={{ padding: '0px 10px' }}>
        <Box padding="5px 15px" display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <ColumnsContainer>
            {Array.from(new Array(4)).map((_, i) => (
              <Column key={i}>
                <Box display="flex" flexDirection="row" alignItems={'center'} height="80px">
                  <Skeleton component="div" width="40px" height="40px" style={{ transform: 'none', ...skeletonStyle }} />
                  <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center" paddingLeft="10px">
                    <Skeleton width="40px" sx={skeletonStyle} />
                    <Skeleton sx={skeletonStyle} />
                  </Box>
                </Box>
              </Column>
            ))}
          </ColumnsContainer>
        </Box>
      </ContentBox>

      <ColumnsContainer marginBottom="15px">
        <Column>
          <ContentBox
            title="Identity Risk"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px', ...contentBoxStyle }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((_, index) => (
                <Box key={index} height="50px" marginBottom={index === 4 ? 0 : '15px'}>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Skeleton width="100px" sx={skeletonStyle} />
                    <Skeleton width="70px" sx={skeletonStyle} />
                  </Box>
                  <Skeleton sx={skeletonStyle} />
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            title="Top 5 IAM Risk"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px', ...contentBoxStyle }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((_, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  height="50px"
                  marginBottom={index === 4 ? 0 : '15px'}>
                  <Skeleton width="30px" height="30px" style={{ transform: 'none', ...skeletonStyle }} />
                  <Skeleton style={{ flexGrow: 1, ...skeletonStyle }} />
                  <Skeleton width="30px" height="30px" style={{ transform: 'none', ...skeletonStyle }} />
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
        <Column>
          <ContentBox
            title="IAM Compliance Score"
            paddingMode="content"
            titleContainerStyle={{ padding: '0px 10px' }}
            style={{ height: '410px', ...contentBoxStyle }}>
            <Box padding="30px" paddingTop={'15px'}>
              {Array.from(new Array(5)).map((_, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  height="50px"
                  marginBottom={index === 4 ? 0 : '15px'}>
                  <Skeleton width="30px" height="30px" style={{ transform: 'none', ...skeletonStyle }} />
                  <Box flexGrow={1}>
                    <Skeleton sx={skeletonStyle} />
                    <Skeleton sx={skeletonStyle} />
                  </Box>
                </Box>
              ))}
            </Box>
          </ContentBox>
        </Column>
      </ColumnsContainer>

      <Box display={'flex'} width={'100%'} gap={'15px'}>
        <ContentBox title="IAM Asset Types" paddingMode="content" style={{ flexGrow: 1, ...contentBoxStyle }}>
          <Box display="flex" flexDirection="row" padding="15px" gap="15px">
            {Array.from(new Array(5)).map((_, i) => (
              <Box
                key={i}
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexGrow={1}
                padding="10px">
                <Skeleton width="100px" height="100px" variant="circular" sx={skeletonStyle} />

                <Box display="flex" flexDirection="row" alignItems="center" width="100%" marginTop="15px" gap="10px">
                  {Array.from(new Array(2)).map((_, j) => (
                    <Box key={j} display="flex" flexDirection="row" flexGrow={1}>
                      <Box
                        width="8px"
                        height="8px"
                        borderRadius="50%"
                        marginTop="10px"
                        marginRight="10px"
                        sx={dotStyle}
                      />
                      <Box flexGrow={1}>
                        <Skeleton width="20px" sx={skeletonStyle} />
                        <Skeleton sx={skeletonStyle} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </ContentBox>
        <ContentBox title="Over Privileged Identities" paddingMode="content" width="300px" style={contentBoxStyle}>
          <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} padding="30px">
            <Skeleton width="100px" height="100px" variant="circular" sx={skeletonStyle} />

            <Box display="flex" flexDirection="row" alignItems="center" width="100%" marginTop="15px" gap="10px">
              {Array.from(new Array(3)).map((_, i) => (
                <Box key={i} display="flex" flexDirection="row" flexGrow={1}>
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    marginTop="10px"
                    marginRight="10px"
                    sx={dotStyle}
                  />
                  <Box flexGrow={1}>
                    <Skeleton width="20px" sx={skeletonStyle} />
                    <Skeleton sx={skeletonStyle} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ContentBox>
      </Box>
      <Box display={'flex'} width={'100%'} gap={'15px'} style={{ marginTop: '15px' }}>
        {['Anomaly/Threats', 'Attack Paths', 'Top Risky Identities'].map(title => (
          <ContentBox key={title} title={title} paddingMode="content" width="33.333%" style={contentBoxStyle}>
            <TableSkeleton 
              ROWS_COUNT={4} 
              COLUMNS_COUNT={2} 
              height="300px" 
              width={300} 
              sx={skeletonStyle}
            />
          </ContentBox>
        ))}
      </Box>
    </PageContent>
    // </PageContainer>
  );
}

export default OverviewSkeleton;
