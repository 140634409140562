import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@root/context/ThemeContext';

interface PageHeaderSkeletonProps {
  isRegionFilter?: boolean;
}

function PageHeaderSkeleton({ isRegionFilter }: PageHeaderSkeletonProps) {
  const { isDarkMode } = useTheme();

  return (
    <div>
      <Box marginBottom="10px" display="flex" justifyContent="space-between">
        <Skeleton 
          height="20px" 
          width="150px" 
          style={{ 
            transform: 'none',
            backgroundColor: isDarkMode ? '#2D3748' : '#E2E8F0' 
          }} 
        />
        {isRegionFilter ? (
          <Skeleton 
            height="30px" 
            width="150px" 
            style={{ 
              transform: 'none',
              backgroundColor: isDarkMode ? '#2D3748' : '#E2E8F0' 
            }} 
          />
        ) : null}
      </Box>
    </div>
  );
}

export default PageHeaderSkeleton;
