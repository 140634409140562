// import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
// import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
// import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
// import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';
// import atlassian_jira_logo_icon from '@assets/svg/atlassian_jira_logo_icon.svg';

import { WorkloadVulnerabilities } from '@root/assets/svg/SVGComponents';

// import fix_available_check from '@root/assets/svg/workload/green_tick_icon_new.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';

import { SeverityWithText } from '@root/components/bootstrapTable/badges/severity';

import { Box } from '@mui/material';

import commonTableColumns from '@root/components/table/commonTableColumns';
import CountTooltip from '@root/components/Tooltip/CountTooltip';

// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';

export const k8columns = () => {
  return [
    commonTableColumns.environment('account_id', 'cluster'),
    // {
    //   field: 'cluster',
    //   headerName: 'Cluster',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     return (
    //       <Tooltip title={params.row?.cluster}>
    //         <Text fontWeight={500} className="SingleLine">
    //           {params.row?.cluster ?? '-'}
    //         </Text>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: 'cve_id',
      headerName: 'CVE ID',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Box sx={{ background: 'rgb(243, 244, 246)', padding: '5px', borderRadius: '.5rem' }}>
              {' '}
              <WorkloadVulnerabilities height="16px" width="16px" />{' '}
            </Box>

            <Tooltip title={params.row?.cve_id}>
              <Text fontWeight={500} style={{ width: '170px' }} className="SingleLine">
                {params.row?.cve_id}
              </Text>
            </Tooltip>
          </div>
        );
      },
    },

    // {
    //   field: 'Name',
    //   headerName: 'CVE Name',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 350,
    //   sortable: false,
    //   renderCell: params => {
    //     return (
    //       <Tooltip title={params.row?.threat_trend_data?.Name}>
    //         <Text fontWeight={500} className="SingleLine">
    //           {params.row?.threat_trend_data?.Name ?? '-'}
    //         </Text>
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   field: 'CVS Type',
    //   headerName: 'CVE Type',
    //   classes: 'align-middle',
    //   minWidth: 250,
    //   sortable: false,
    //   renderCell: params => {
    //     console.log(params.row?.details, 'params.row?.details?.type');

    //     return (
    //       <Tooltip title={params.row?.type}>
    //         <Text fontWeight={500} className="SingleLine">
    //           {params.row?.type ?? '-'}
    //         </Text>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: 'severity',
      headerName: 'CVSS Severity',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.severity}>
            {params.row?.severity.toUpperCase() !== 'INFORMATIONAL' ||
            params.row?.severity.toUpperCase() !== 'UNTRIAGED' ? (
              // <HighIcon textDisabled={true} />
              <SeverityWithText propsValue={params.row?.severity} />
            ) : params.row?.severity.toUpperCase() === 'INFORMATIONAL' ? (
              <img
                src={info}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            ) : (
              <img
                src={question1}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: 'cvss_score',
      headerName: 'CVSS Score',
      classes: 'align-middle',
      minWidth: 200,
      sort: true,
      renderCell: params => {
        const score =
          params.row?.['cvss_score'] === 0 || !params.row?.['cvss_score']
            ? 0
            : parseFloat(params.row?.['cvss_score']).toFixed(1);
        const colorSeverity = params.row?.severity;

        const severityDarkColors = {
          CRITICAL: '#FF0000',
          HIGH: '#EB5757',
          MEDIUM: '#FFA500',
          LOW: '#F2C94C',
          ALL: '#1E90FF',
          DEFAULT: '#5D0E61',
        };

        const severityLightColors = {
          CRITICAL: '#ffdad3',
          HIGH: '#ffdfdc',
          MEDIUM: '#ffeed9',
          LOW: '#fdf5df',
          ALL: '#d7eaff',
          DEFAULT: '#dfcedf',
        };

        const backgroundDarkColor = severityDarkColors[colorSeverity] || severityDarkColors.DEFAULT;
        const backgroundLightColor = severityLightColors[colorSeverity] || severityLightColors.DEFAULT;

        // const version = `v${params.row?.details?.Vector ?? '3.0'}`;
        const version = params.row?.details?.cvssMetricV31?.cvssMetricV31?.[0]?.cvssData?.version
          ? `v${params.row.details.cvssMetricV31.cvssMetricV31[0].cvssData.version}`
          : params.row?.details?.Vector?.match(/CVSS:(\d+\.\d+)/)?.[1]
          ? `v${params.row.details.Vector.match(/CVSS:(\d+\.\d+)/)[1]}`
          : 'v3.0';

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '5px',
              overflow: 'hidden',
            }}>
            <div
              style={{
                backgroundColor: backgroundDarkColor,
                padding: '3px 7px',
                color: '#FFFFFF',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {score}
            </div>
            <div
              style={{
                backgroundColor: backgroundLightColor,
                padding: '3px 7px',
                color: '#5D0E61',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {version}
            </div>
          </div>
        );
      },
    },

    // {
    //   field: 'cve_risk',
    //   headerName: 'CVE Risks',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params =>
    //     params.row?.cve_risk === '-' || params.row?.cve_risk?.length === 0 ? (
    //       '-'
    //     ) : (
    //       <div style={{ display: 'flex', gap: '10px' }}>
    //         {params.row?.cve_risk?.exploit && (
    //           <Tooltip title={'Exploit'} isIcon={true}>
    //             <img src={exploit} />
    //           </Tooltip>
    //         )}

    //         {params.row?.cve_risk?.internet_exposed && (
    //           <Tooltip title={'Internet Exposed'} isIcon={true}>
    //             <img src={exposed} />
    //           </Tooltip>
    //         )}
    //       </div>
    //     ),
    // },
    // {
    //   field: 'vendor_name',
    //   headerName: 'Vendor Name',
    //   classes: 'align-middle',
    //   minWidth: 200,
    //   sortable: false,

    //   renderCell: params => {
    //     return (
    //       <Tooltip title={params.row?.threat_trend_data?.Vendor}>
    //         <Text fontWeight={500} className="SingleLine">
    //           {params.row?.threat_trend_data?.Vendor ?? '-'}
    //         </Text>
    //       </Tooltip>
    //     );
    //   },
    // },

    // {
    //   field: 'vendor_severity',
    //   headerName: 'Vendor Severity',
    //   classes: 'align-middle',
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     return params.row?.vendor_severity !== undefined ? (
    //       params.row?.vendor_severity ? (
    //         <SeverityWithText propsValue={params.row?.vendor_severity} />
    //       ) : (
    //         '-'
    //       )
    //     ) : (
    //       '-'
    //     );
    //   },
    // },

    {
      field: 'epss_score',
      headerName: 'EPSS Score',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={'params.row.threat_trend_data.Vendor'}>
            <Text fontWeight={500} className="SingleLine">
              {(params.row?.exploitability_score ?? 0).toFixed(2)}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      field: 'exploitable',
      headerName: 'Exploitable',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return params?.row?.exploitable ? 'Yes' : 'No';
      },
    },
    {
      field: 'affected_artifacts',
      headerName: 'Component',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 250,
      sortable: false,
      renderCell: params => {
        return <CountTooltip labels={params?.row?.affected_artifacts} />;
      },
    },
    {
      field: 'fix_version',
      headerName: 'Fix Version',
      renderCell: params => {
        return params?.row?.fix_version ?? '-';
      },
      width: 250,
    },
    {
      field: 'images',
      headerName: 'Affected Images',
      renderCell: params => {
        return <CountTooltip labels={params?.row?.affected_images} />;
      },
      width: 250,
    },
    {
      field: 'workloads',
      headerName: 'Affected Workloads',
      renderCell: params => {
        return <CountTooltip labels={params?.row?.affected_workloads} />;
      },
      width: 250,
    },
    // {
    //   field: 'sources',
    //   headerName: 'Sources',
    //   classes: 'align-middle',
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     return params.row?.sources === 'CQ Shield Engine' ? (
    //       <Tooltip title={'CQ Shield Engine'} isIcon={true}>
    //         <img src={sources} />
    //       </Tooltip>
    //     ) : (
    //       params.row.sources
    //     );
    //   },
    // },
    // {
    //   field: 'resources',
    //   headerName: 'Resources',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    // },
    // {
    //   field: 'fix',
    //   headerName: 'Fix',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     return params.row.fix === 'YES' ? (
    //       <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
    //         <WorkloadTickIcon height="18px" width="18px" />
    //       </div>
    //     ) : params.row?.fix === '-' ? (
    //       '-'
    //     ) : (
    //       params.row?.fix
    //     );
    //   },
    // },
    // {
    //   field: 'threat_trend_data',
    //   headerName: 'Threat Trend',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     return params.row?.threat_trend?.cisa  || params.row?.threat_trend?.epss  ? (
    //       <>
    //         {params.row?.threat_trend?.cisa !== 0 ? (
    //           <Tooltip title={'Cisa'} isIcon={true}>
    //             <img
    //               src={threat_trend_cisa}
    //               style={{ marginRight: '16px' }}
    //               onClick={() => {
    //                 // setShowDetailsPanel(false);
    //                 setThreatModelData(params.row);
    //                 setOpen(true);

    //                 // event.stopPropagation();
    //               }}
    //             />
    //           </Tooltip>
    //         ) : (
    //           ''
    //         )}
    //         {params.row?.threat_trend?.epss !== 0 ? (
    //           <Tooltip title={'EPSS'} isIcon={true}>
    //             <img
    //               src={threat_trend_epss}
    //               onClick={() => {
    //                 // setShowDetailsPanel(false);
    //                 setThreatModelData(params.row);
    //                 setOpen(true);
    //                 // event.stopPropagation();
    //               }}
    //             />
    //           </Tooltip>
    //         ) : (
    //           ''
    //         )}
    //       </>
    //     ) : (
    //       '-'
    //     );
    //   },
    // },
    // {
    //   field: 'state',
    //   headerName: 'Status',
    //   classes: 'align-middle',
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => (params.row?.state === 'CLOSED' ? 'Inactive' : 'Active'),
    // },
    // {
    //   field: 'jira_status',
    //   headerName: 'Jira Status',
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     console.log(isJiraEnabled, 'fnkdfn');
    //     return (
    //       <MuitoolTip
    //         label={
    //           !isJiraEnabled && jiraErrorMsgs === 'Jira token invalid or expired' ? (
    //             <div style={{ textAlign: 'center' }}>
    //               {jiraErrorMsgs}
    //               <br /> please re-configure from
    //               <Link style={{ marginLeft: '5px' }} to="/notifications/channels">
    //                 Channels
    //               </Link>
    //             </div>
    //           ) : !isJiraEnabled && jiraErrorMsgs !== (null || '') ? (
    //             <div style={{ textAlign: 'center' }}>
    //               JIRA is not enabled for this tenant,
    //               <br /> please enable from
    //               <Link style={{ marginLeft: '5px' }} to="/notifications/channels">
    //                 Channels
    //               </Link>
    //             </div>
    //           ) : (
    //             !isConfigureAllowed && `No permission available. Please contact Organization Admin`
    //           )
    //         }>
    //         <Button
    //           style={{
    //             backgroundColor: 'transparent',
    //             border: 'none',
    //             padding: '0px',
    //             display: 'flex',
    //             gap: '4px',

    //             cursor:
    //               (!isJiraEnabled && jiraErrorMsgs !== '') || !isJiraEnabled || !isConfigureAllowed
    //                 ? 'context-menu'
    //                 : 'pointer',
    //             pointerEvents:
    //               (!isJiraEnabled && jiraErrorMsgs !== '') || !isJiraEnabled || !isConfigureAllowed ? 'none' : 'unset',
    //             opacity:
    //               (!isJiraEnabled && jiraErrorMsgs !== '') || !isJiraEnabled || !isConfigureAllowed ? '0.5' : '1',
    //             // }}
    //             alignItems: 'center',
    //           }}
    //           onClick={e => {
    //             if (!isJiraEnabled) {
    //               e.stopPropagation();
    //               e.preventDefault();
    //               return;
    //             } else {
    //               setSelectedUser(params.row);
    //               setJiraModelData(params.row);
    //               setOpenJiraModel(true);
    //               e.stopPropagation();
    //             }

    //             // setOpen(false);
    //           }}>
    //           <JIRAIcon height="12px" width="12px" />
    //           <span style={{ marginLeft: '5px', color: '#007FFF' }}>Create</span>
    //         </Button>
    //       </MuitoolTip>
    //     );
    //   },
    // },
    // {
    //   field: 'Date Added',
    //   headerName: 'Published',
    //   classes: 'align-middle',
    //   minWidth: 200,
    //   sortable: false,
    //   renderCell: params => DateFormatPipe(params?.row?.threat_trend_data?.['Date Added']),
    // },
    // {
    //   field: 'last_seen',
    //   headerName: 'Last Seen',
    //   classes: 'align-middle',
    //   // headerClassName: 'fs__2'
    //   minWidth: 200,
    //   sortable: false,
    //   // renderCell: params => {
    //   //   const [datePart, timePart] = params.row.last_seen.split('T');
    //   //   return `${datePart}   ${timePart}`;
    //   // },
    //   renderCell: params => DateFormatPipe(params?.row?.last_seen),
    // },
  ];
};
