// import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
// import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
// import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
// import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';
// import atlassian_jira_logo_icon from '@assets/svg/atlassian_jira_logo_icon.svg';

import { WorkloadVulnerabilities } from '@root/assets/svg/SVGComponents';

// import fix_available_check from '@root/assets/svg/workload/green_tick_icon_new.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';

// import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import { SeverityWithText } from '@root/components/bootstrapTable/badges/severity';

import { Box } from '@mui/material';

// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';

export const columns = () => {
  return [
    {
      field: 'cve_id',
      headerName: 'CVE ID',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Box sx={{ background: 'rgb(243, 244, 246)', padding: '5px', borderRadius: '.5rem' }}>
              {' '}
              <WorkloadVulnerabilities height="16px" width="16px" />{' '}
            </Box>

            <Tooltip title={params.row?.cve_id}>
              <Text fontWeight={500} style={{ width: '170px' }} className="SingleLine">
                {params.row?.cve_id}
              </Text>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: 'severity',
      headerName: 'CVSS Severity',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.severity}>
            {params.row?.severity.toUpperCase() !== 'INFORMATIONAL' ||
            params.row?.severity.toUpperCase() !== 'UNTRIAGED' ? (
              // <HighIcon textDisabled={true} />
              <SeverityWithText propsValue={params.row?.severity} />
            ) : params.row?.severity.toUpperCase() === 'INFORMATIONAL' ? (
              <img
                src={info}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            ) : (
              <img
                src={question1}
                style={{
                  height:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row?.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row?.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: 'cvss_score',
      headerName: 'CVSS Score',
      classes: 'align-middle',
      minWidth: 200,
      sort: true,
      renderCell: params => {
        const score = params.row?.['cvss_score'] === 0 || !params.row?.['cvss_score'] ? 0 : parseFloat(params.row?.['cvss_score']).toFixed(1);
        const colorSeverity = params.row?.severity;

        const severityDarkColors = {
          CRITICAL: '#FF0000',
          HIGH: '#EB5757',
          MEDIUM: '#FFA500',
          LOW: '#F2C94C',
          ALL: '#1E90FF',
          DEFAULT: '#5D0E61',
        };

        const severityLightColors = {
          CRITICAL: '#ffdad3',
          HIGH: '#ffdfdc',
          MEDIUM: '#ffeed9',
          LOW: '#fdf5df',
          ALL: '#d7eaff',
          DEFAULT: '#dfcedf',
        };

        const backgroundDarkColor = severityDarkColors[colorSeverity] || severityDarkColors.DEFAULT;
        const backgroundLightColor = severityLightColors[colorSeverity] || severityLightColors.DEFAULT;

        // const version = `v${params.row?.details?.Vector ?? '3.0'}`;
        const version = params.row?.details?.cvssMetricV31?.cvssMetricV31?.[0]?.cvssData?.version
          ? `v${params.row.details.cvssMetricV31.cvssMetricV31[0].cvssData.version}`
          : params.row?.details?.Vector?.match(/CVSS:(\d+\.\d+)/)?.[1]
          ? `v${params.row.details.Vector.match(/CVSS:(\d+\.\d+)/)[1]}`
          : 'v3.0';

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '5px',
              overflow: 'hidden',
            }}>
            <div
              style={{
                backgroundColor: backgroundDarkColor,
                padding: '3px 7px',
                color: '#FFFFFF',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {score}
            </div>
            <div
              style={{
                backgroundColor: backgroundLightColor,
                padding: '3px 7px',
                color: '#5D0E61',
                fontFamily: 'Manrope',
                fontWeight: 500,
                fontSize: '12px',
              }}>
              {version}
            </div>
          </div>
        );
      },
    },
    {
      field: 'exploitability_score',
      headerName: 'EPSS Score',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={'params.row.threat_trend_data.Vendor'}>
            <Text fontWeight={500} className="SingleLine">
              {(params.row?.exploitability_score ?? 0).toFixed(2)}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      field: 'exploitable',
      headerName: 'Exploitable',
      classes: 'align-middle',
      // headerClassName: 'fs__2'
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        return (
          <Tooltip title={'params.row.threat_trend_data.Vendor'}>
            <Text fontWeight={500} className="SingleLine">
              {(params.row?.exploitable ? 'Yes':'No')}
            </Text>
          </Tooltip>
        );
      },
    },
    {
        field: 'path',
        headerName: 'Path',
        classes: 'align-middle',
        minWidth: 200,
        sortable: false,
    
      },
    {
      field: 'state',
      headerName: 'Status',
      classes: 'align-middle',
      minWidth: 200,
      sortable: false,
      renderCell: params => (params.row?.state === 'CLOSED' ? 'Inactive' : 'Active'),
    },

    
  ];
};
