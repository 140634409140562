import { Box, Button, FormControlLabel, FormGroup, IconButton, InputAdornment, Popper, TextField } from '@mui/material';
import CheckBox from '@root/components/v2/ui-elements/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import './filter.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@root/context/ThemeContext';

import noMfaLogo from '@root/assets/svg/Risk/RiskPriority/no-mfa.svg';
import AWSServiceIcons from '@root/components/awsServiceIcons';
import ComplianceIconMapping from '@root/components/complianceIconMapping';
import anomalousLogo from '@root/assets/svg/Risk/RiskPriority/anomalous.svg';
import dataLogo from '@root/assets/svg/Cloud_Events/data-security-logo.svg';
import dataAccessLogo from '@root/assets/svg/Risk/RiskPriority/data-access.svg';
import encryptionLogo from '@root/assets/svg/Cloud_Events/encryption-logo.svg';
import exposedSecretsLogo from '@root/assets/svg/Cloud_Events/exposed-secret.svg';
import highAvailabilityLogo from '@root/assets/svg/Cloud_Events/high-availability.svg';
import identityLogo from '@root/assets/svg/Cloud_Events/identity-logo.svg';
import lateralMovementLogo from '@root/assets/svg/Risk/RiskPriority/lateral-movement.svg';
import loggingMonitoringLogo from '@root/assets/svg/Cloud_Events/logging-and-monitoring.svg';
import networkLogo from '@root/assets/svg/Cloud_Events/network-logo.svg';
import noRiskLogo from '@root/assets/svg/Cloud_Events/no-risk-logo.svg';
import threatLogo from '@root/assets/svg/Risk/RiskPriority/threat.svg';
import userLogo from '@root/assets/svg/Cloud_Events/user-logo.svg';
import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/vulnerabilities-logo.svg';
import administratorLogo from '@root/assets/svg/Risk/RiskPriority/admin.svg';
import credentialExposureLogo from '@root/assets/svg/Risk/RiskPriority/credential-exposure.svg';
import inactiveLogo from '@root/assets/svg/Risk/RiskPriority/inactive.png';
import infrastructureModificationLogo from '@root/assets/svg/Risk/RiskPriority/infrastructure-modification.svg';
import misconfigurationIcon from '@root/assets/svg/Cloud_Events/misconfigurationlogo.svg';
import overPrivilegedLogo from '@root/assets/svg/Risk/RiskPriority/over-privileged.svg';
import privilegeEscalationLogo from '@root/assets/svg/Risk/RiskPriority/privilege-escalation.svg';
import priviledgeLogo from '@root/assets/svg/Risk/RiskPriority/privledge.png';
import reconnaissanceLogo from '@root/assets/svg/Risk/RiskPriority/reconnaissance.svg';
import Malware from '@root/assets/svg/Cloud_Events/malware.svg';
import RuntimeLogo from '@root/assets/svg/Cloud_Events/runtimelogo.svg';
// import EffectiveAdmin from '@assets/svg/identity_risk_icons/effective_admin Hover.svg';
import EffectiveAdmin from '@root/assets/svg/Risk/RiskPriority/effective-admin.png';
import athena from '@root/assets/img/aws_services/aws_athena.svg';
import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';
import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';

import multipleAccessKeysActiveLogo from '@root/assets/svg/Risk/RiskPriority/multiple-access-keys-active.png';
import crossAccountLogo from '@root/assets/svg/Risk/RiskPriority/cross-account.svg';
import environmentAccessLogo from '@root/assets/svg/Risk/RiskPriority/environment-access.svg';
import rootUserLogo from '@root/assets/svg/Risk/RiskPriority/root-user.svg';
import accessKey1NotRotatedLogo from '@root/assets/svg/Risk/RiskPriority/access-key1-not-rotated.svg';
import permissionManagementLogo from '@root/assets/svg/Risk/RiskPriority/permission-management.png';
import accessKey2NotRotatedLogo from '@root/assets/svg/Risk/RiskPriority/access-key2-not-rotated.svg';
import attackPathLogo from '@root/assets/svg/Risk/RiskPriority/attack_path.svg';
import learningLogo from '@root/assets/svg/Risk/RiskPriority/learning.svg';

// import permission_management from '@assets/svg/identity_risk_icons/permission_management Hover.svg';
import _ from 'lodash';
import { SearchIcon } from '@root/assets/svg/SVGComponents';
import Tooltip from '../v2/ui-elements/Tooltip';

const active_risk_mapping = {
  ['Data']: dataLogo,
  ['Credential Exposure']: credentialExposureLogo,
  ['Privilege Escalation']: privilegeEscalationLogo,
  ['Reconnaissance']: reconnaissanceLogo,
  ['Privileged']: priviledgeLogo,
  ['Inactive']: inactiveLogo,
  ['Threat']: threatLogo,
  ['Administrator']: administratorLogo,
  ['Admin']: administratorLogo,
  ['Infrastructure Modification']: infrastructureModificationLogo,
  ['Over Privileged']: overPrivilegedLogo,
  ['Data Security']: dataLogo,
  ['Encryption']: encryptionLogo,
  ['Exposed Secrets']: exposedSecretsLogo,
  ['Network']: networkLogo,
  ['Anomalous']: anomalousLogo,
  ['Logging And Monitoring']: loggingMonitoringLogo,
  ['Logging & Monitoring']: loggingMonitoringLogo,
  ['Lateral Movement']: lateralMovementLogo,
  ['High Availability']: highAvailabilityLogo,
  ['Identity']: identityLogo,
  ['User']: userLogo,
  ['Vulnerabilities']: vulnerabilitiesLogo,
  ['NoRisk']: noRiskLogo,
  ['Misconfiguration']: misconfigurationIcon,
  ['Malware']: Malware,
  ['Runtime']: RuntimeLogo,
  ['No Mfa']: noMfaLogo,
  ['Cross Account']: crossAccountLogo,
  ['Environment Access']: environmentAccessLogo,
  ['Multiple Access Keys Active']: multipleAccessKeysActiveLogo,
  ['Root User']: rootUserLogo,
  ['Access Key 1 Not Rotated']: accessKey1NotRotatedLogo,
  ['Learning']: learningLogo,
  ['Access Key 2 Not Rotated']: accessKey2NotRotatedLogo,
  ['Attack path']: attackPathLogo,

  // Kubernetes Risk Temp
  ['Effective Admin']: EffectiveAdmin,
  ['Data Access']: dataAccessLogo,
  ['High Privileged']: overPrivilegedLogo,
  ['Misconfigurations']: misconfigurationIcon,
  ['Threats anomaly']: threatLogo,
  ['Anomaly']: anomalousLogo,
  ['Permission Management']: permissionManagementLogo,
};

const serviceGridIcons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
  ['Athena']: athena,
};

const FilterComponent = ({
  openFilter,
  option,
  selectedFilters,
  setSelectedFilters,
  defaultValue,
  setIsDefaultValue,
  setInventoryTableBreadcrumb,
}) => {
  const { isDarkMode } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRefs = useRef([]);
  const popperRefs = useRef([]);
  const [openFilterId, setOpenFilterId] = useState(null);
  const [isAnyPopperOpen, setIsAnyPopperOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const filterOptions = useMemo(() => {
    if (option)
      return Object.keys(option)?.map(key => ({
        id: key.toLowerCase().replace(/\s+/g, '_'),
        label: key,
        options: option[key],
      }));
  }, [option]);

  const handleFilterClick = (event, filterId) => {
    setAnchorEl(event.currentTarget);
    setOpenFilterId(filterId);

    setIsAnyPopperOpen(prev => {
      if (!openFilterId) {
        // initially if no filter is open
        return true;
      } else if (prev && openFilterId === filterId) {
        // 2rd case if user clicks on the same filter => then it should be closed
        return false;
      } else if (prev && openFilterId !== filterId) {
        // 3nd case if one property is open and the user switches to another property
        //  => From risk -> cq-shield-priority
        // popper will remain open
        return true;
        // else reverse
      } else return !prev;
    });
    setSearchTerm(''); // Reset search term when opening a new filter
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenFilterId(null);
    setSearchTerm(''); // Reset search term when closing
  };
  const handleClearSearch = event => {
    event.stopPropagation();
    setSearchTerm('');
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (filterId, option, isChecked) => {
    setSelectedFilters(prev => {
      const updatedFilter = { ...prev };
      if (isChecked) {
        updatedFilter[filterId] = [...(updatedFilter[filterId] || []), option];
      } else {
        updatedFilter[filterId] = (updatedFilter[filterId] || []).filter(item => item !== option);
      }
      if (updatedFilter[filterId]?.length === 0) {
        if (Array.isArray(updatedFilter[filterId])) {
          updatedFilter[filterId] = [];
        } else {
          updatedFilter[filterId] = '';
        }
      }
      return updatedFilter;
    });
    // only removing services breadcrumb independently of any prefixed breadcrumb
    if (filterId?.toLowerCase() === 'services') {
      setInventoryTableBreadcrumb(prev => {
        return prev.filter(
          item => !(item.title?.toLowerCase().includes('aws') || item.title?.toLowerCase().includes('gcp')),
        );
      });
    }
  };

  const getFilteredOptions = options => {
    return options.filter(option => String(option).toLowerCase().includes(searchTerm.toLowerCase()));
  };

  useEffect(() => {
    if (!_.isEmpty(defaultValue)) {
      const initialFilters = {};
      Object.entries(defaultValue || {})?.map(([key, value]) => {
        initialFilters[key] = value;
      });
      setSelectedFilters(prev => ({ ...prev, ...initialFilters }));
      if (setIsDefaultValue) setIsDefaultValue(true);
    }
  }, [defaultValue, setIsDefaultValue, setSelectedFilters]);

  const clearFilter = filterId => {
    setSelectedFilters(prev => {
      const updatedFilter = { ...prev };
      if (Array.isArray(updatedFilter[filterId])) {
        updatedFilter[filterId] = [];
      } else {
        updatedFilter[filterId] = '';
      }
      return updatedFilter;
    });
  };

  useEffect(() => {
    const handleClickOutside = event => {
      let isClickOutside = true;

      // Loop through each button and check if the click was outside any button or its popper
      for (let i = 0; i < buttonRefs.current.length; i++) {
        if (buttonRefs.current[i]?.contains(event.target) || popperRefs.current[i]?.contains(event.target)) {
          isClickOutside = false; // The click is inside one of the buttons or poppers
          break;
        }
      }

      if (isClickOutside) {
        setIsAnyPopperOpen(false); // Close all poppers if the click was outside
      }
    };

    // Adding event listener for clicks outside of the poppers
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Empty dependency array to set this up only once
  const resetAllFilters = () => {
    const emptyFilters = {};
    Object.keys(selectedFilters).forEach(key => {
      emptyFilters[key] = Array.isArray(selectedFilters[key]) ? [] : '';
    });
    setSelectedFilters(emptyFilters);
  };
  const hasActiveFilters = Object.values(selectedFilters).some(filter =>
    Array.isArray(filter) ? filter.length > 0 : Boolean(filter),
  );
  if (!openFilter) return <></>;

  return (
    <div className="filter-component">
      <div className="filter-buttons">
        {filterOptions?.map((filter, index) => (
          <React.Fragment key={filter.id}>
            <Button
              variant="outlined"
              ref={el => (buttonRefs.current[index] = el)}
              onClick={e => handleFilterClick(e, filter.id)}
              className="filter-button"
              style={{
                borderColor: selectedFilters[filter.id]?.length > 0 
                  ? isDarkMode ? '#4299e1' : 'rgb(1,64,227)' 
                  : isDarkMode ? '#4a5568' : 'var(--border-color-light)',
              }}>
              {filter.label}
              {selectedFilters[filter.id]?.length > 0 && (
                <span className=""> : ({selectedFilters[filter.id].length})</span>
              )}
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: '20px',
                  transform: isAnyPopperOpen && openFilterId === filter.id ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                  color: 'rgb(34, 34, 34)',
                }}
              />
            </Button>
            <Popper
              open={isAnyPopperOpen && openFilterId === filter.id}
              sx={{
                backgroundColor: isDarkMode ? '#1a202c' : '#ffffff',
                color: isDarkMode ? '#e2e8f0' : '#1a202c',
                border: `1px solid ${isDarkMode ? '#2d3748' : 'var(--border-color-light)'}`,
                boxShadow: isDarkMode 
                  ? '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.2),2px 3px 14px 2px rgba(0,0,0,0.2)'
                  : '0px 5px 5px -3px rgba(0,0,0,0.05),0px 8px 10px 1px rgba(0,0,0,0.05),2px 3px 14px 2px rgba(0,0,0,0.05)',
                borderRadius: '10px',
                overflow: 'hidden',
                zIndex: '9300 !important',
                marginTop: '7px !important',
                // transition: 'all 0.3s ease',
              }}
              anchorEl={anchorEl}
              onClose={handleClose}
              ref={el => (popperRefs.current[index] = el)}
              className="CustomScrollbar"
              placement="bottom-start"
              // anchorOrigin={{
              //   vertical: 'bottom',
              //   horizontal: 'left',
              // }}
            >
              <div style={{ padding: '10px 10px 0 10px' }}>
                <TextField
                  size="small"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.54)' }} />
                      </InputAdornment>
                    ),
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear search"
                          onClick={handleClearSearch}
                          edge="end"
                          size="small"
                          sx={{
                            padding: '2px',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          }}>
                          <ClearIcon sx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontSize: '13px',
                      fontFamily: 'Manrope',
                      color: isDarkMode ? '#e2e8f0' : 'inherit',
                      '& fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '8px 0px',
                    },
                    backgroundColor: isDarkMode ? '#2d3748' : 'rgb(249, 250, 251)',
                    borderRadius: '5px',
                    // transition: 'all 0.3s ease',     
                  }}
                />
              </div>
              <div
                className="filter-popover CustomScrollbar"
                style={{
                  marginTop: '10px',
                  maxHeight: '226px',
                  minWidth: '100px',
                  overflowY: 'auto',
                  maxWidth: '450px',
                }}>
                <FormGroup>
                  {getFilteredOptions(filter?.options || []).map(option => (
                    <FormControlLabel
                      className="CustomScrollbar"
                      sx={{
                        marginBottom: '0px',
                        marginRight: '0px',
                        padding: '0px 20px',
                        display: 'flex !important',
                        flexWrap: 'nowrap',
                        '.MuiTypography-root': {
                          fontFamily: 'Manrope',
                          fontSize: '13px',
                          fontWeight: '500',
                        },
                        '&:hover': {
                          backgroundColor: 'rgb(238,238,238)',
                        },
                        '&:active': {
                          backgroundColor: 'rgb(238,238,238)',
                        },
                      }}
                      key={option}
                      control={
                        <CheckBox
                          checked={
                            selectedFilters[filter.id]?.includes(option) ||
                            (!_.isBoolean(option) && selectedFilters[filter.id]?.includes(option?.toLowerCase())) ||
                            false
                          }
                          onChange={e => handleFilterChange(filter.id, option, e.target.checked)}
                        />
                      }
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {active_risk_mapping[option] && filter.label?.toUpperCase()?.includes('RISK') && (
                            <img
                              src={active_risk_mapping[option]}
                              style={{ marginRight: '7px', width: '18px', height: '18px' }}
                            />
                          )}
                          {filter.label === 'Compliance Family' && (
                            <ComplianceIconMapping
                              name={option}
                              width="20px"
                              height="20px"
                              style={{ marginRight: '5px' }}
                            />
                          )}
                          {serviceGridIcons[option] && (
                            <img
                              src={serviceGridIcons[option]}
                              style={{ marginRight: '5px', width: '20px', height: '20px' }}
                            />
                          )}
                          {(filter.label === 'Services' || filter.label === 'Service') && (
                            <div style={{ marginRight: '10px' }}>
                              <AWSServiceIcons name={option} imgWidth={13} imgHeight={13} />
                            </div>
                          )}
                          <Tooltip title={option}>
                            <span
                              className="SingleLine"
                              style={{ fontSize: '13px', fontFamily: 'Manrope', fontWeight: '500', maxWidth: '350px' }}>
                              {typeof option === 'boolean' ? option.toString() : option}
                            </span>
                          </Tooltip>
                        </div>
                      }
                    />
                  ))}
                </FormGroup>
              </div>
              <Box display={'flex'} justifyContent={'center'} margin={'7px'}>
                <Button
                  onClick={() => {
                    clearFilter(filter.id);
                  }}
                  className="clear-button"
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    fontFamily: 'Manrope',
                    fontWeight: '500',
                    color: 'rgb(24, 95, 226)',
                    padding: '3px 8px',
                    borderRadius: '5px',
                  }}>
                  Clear
                </Button>
              </Box>
            </Popper>
          </React.Fragment>
        ))}
        {hasActiveFilters && (
          <Button
            variant="outlined"
            onClick={resetAllFilters}
            className="reset-all-button"
            sx={{
              marginLeft: '8px',
              textTransform: 'none',
              fontSize: '13px',
              fontFamily: 'Manrope',
              fontWeight: '500',
              color: isDarkMode ? '#4299e1' : 'rgb(24, 95, 236)',
              border: 'none',
              '&:hover': {
                backgroundColor: isDarkMode ? '#4a5568' : 'rgb(230, 239, 255)',
                border: 'none',
              },
              // transition: 'all 0.3s ease',
            }}>
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
